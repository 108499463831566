import { useEffect, useState } from "react";
import { Room } from "../../../declarations";
import CacheManagerInstance from "../../../service/cacheManager";

export function useRooms(componentID: number): Room[] {
    const [rooms, setRooms] = useState<Room[]>([]);
    useEffect(() => {
        CacheManagerInstance.getRooms(componentID).then(setRooms)
    }, [componentID]);
    return rooms;
}
