import {ModalBase, ModalField, useModal} from "../../../core/modal/ModalBase";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import {mdiAccountAlert} from "@mdi/js";
import {useState} from "react";
import {Student} from "../../../declarations";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

const validationRaison = (value: string): string | null => value.length >= 255 ? null : value;
export function JustifyAbsence({student, eventID}: {student: Student, eventID: number}): JSX.Element {

    const [value, setValue] = useState<string>('');
    const modal = useModal()

    const onCreate = (id: string): boolean => {
        if (id !== 'confirm') return true;
        CacheManager.justifyAbsence(eventID, student.id, value).catch(modal.openErrorModal);
        return true;
    }

    return (
        <ModalBase
            title={LANG.event_modal_justify_title}
            buttons={[
                {label: LANG.global_confirm, id: 'confirm', active: true}
            ]}
            onButtonClick={onCreate}
        >
            <div className={ModalStyle.body_fields}>
                <ModalField id={'reason'}
                            type={'text'}
                            label={LANG.event_modal_justify_field_reason_label}
                            placeholder={LANG.event_modal_justify_field_reason_placeholder}
                            onChange={setValue}
                            validation={validationRaison}
                            svg={mdiAccountAlert}/>
            </div>
        </ModalBase>
    )
}