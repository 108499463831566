import Icon from "@mdi/react";
import {ToolTipContainer} from "./Utils";
import Styles from '../styles/component/IconButton.module.scss'
import {extendClassName} from "../../../util/utilsHelper";
import {NavLink} from "react-router-dom";
import {PlacesType} from "react-tooltip";
import {permission} from "../../../util/pernission";
import CacheManager from "../../../service/cacheManager";
import {MouseEvent} from "react";

export enum ButtonStyle  {
    ROUND_UPC = 0,
    ROUND_GRAY,
    CLEAR,
    NONE
}

// enum ButtonSize {
//     MEDIUM = 0,
//     LARGE = 1,
//     SMALL
// }

export enum ButtonHoverTransition {
    NONE,
    BACKGROUND_WHITE,
}

interface IconButtonPros {

    path: string;
    pathHover?: string

    title?: string;
    'title-inline'?: boolean,
    'title-place'?: PlacesType,
    'aria-label'?: string;

    className?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement|HTMLAnchorElement>) => void;
    link?: string;
    disabled?: boolean;

    'd-style'?: ButtonStyle;
    // size?: ButtonSize,
    large?: boolean;
    transition?: ButtonHoverTransition;
    permission?: permission;
}

export function IconButton(props: IconButtonPros): JSX.Element|null {

    if (props.permission && !CacheManager.havePermission(props.permission)) return null;

    let btnStyle: string|undefined;
    if (props["d-style"] !== ButtonStyle.CLEAR) {
        btnStyle =  props.large ? Styles.size_large : Styles.size_medium
        switch (props["d-style"] === undefined ? ButtonStyle.ROUND_UPC : props["d-style"])
        {
            case ButtonStyle.ROUND_GRAY:
                btnStyle = extendClassName(btnStyle, Styles.style_round_gray)
                break;
            case ButtonStyle.ROUND_UPC:
                btnStyle = extendClassName(btnStyle, Styles.style_round_upc)
                break;
            case ButtonStyle.NONE:
                break;
        }
    } else  {
        btnStyle = props.large ? Styles.style_clear_large : Styles.style_clear_small
    }

    if (props.pathHover) btnStyle = extendClassName(btnStyle, Styles.button_trigger_on_hover)
    if(props.transition === undefined || props.transition === ButtonHoverTransition.BACKGROUND_WHITE)
        btnStyle = extendClassName(btnStyle, Styles.transition_bg_white)

    btnStyle = extendClassName(btnStyle, Styles.global)

    const inside = props.link === undefined || props.disabled ? (
        <button onClick={props.onClick} className={btnStyle} disabled={props.disabled}>
            <Icon path={props.path} />
            {props.pathHover && <Icon path={props.pathHover} />}
        </button>
    ) : (
        <NavLink to={props.link} onClick={props.onClick} className={btnStyle}>
            <Icon path={props.path} />
            {props.pathHover && <Icon path={props.pathHover} />}
        </NavLink>
    )

    if (props.title) {
        return (
            <ToolTipContainer data-tooltip-content={props.title} aria-label={props["aria-label"]}
                              className={props.className} data-tooltip-place={props["title-place"]} inline={props["title-inline"]}>
                {inside}
            </ToolTipContainer>
        )
    }

    return (
        <div className={props.className} aria-label={props["aria-label"]}>
            {inside}
        </div>
    )

}