import {ModalBase, ModalField, useModal} from "../../../core/modal/ModalBase";
import {ACYear} from "../../../declarations";
import React, {useCallback, useState} from "react";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import {mdiClockEnd, mdiClockStart, mdiPound} from "@mdi/js";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

export function EditAcYear({baseAcYear, componentID}: { baseAcYear?: ACYear, componentID?: number }): JSX.Element {

    const modal = useModal();

    const [data, setData] = useState<{
        name: string,
        year_start: number,
        year_end: number,
    }>(baseAcYear ? {
        name: baseAcYear.name,
        year_start: baseAcYear.year_start,
        year_end: baseAcYear.year_end,
    } : {
        name: "",
        year_start: new Date().getFullYear(),
        year_end: new Date().getFullYear() + 1,
    });

    if (componentID === undefined && baseAcYear === undefined)  {
        throw new Error("Component ID or baseAcYear must be defined")
    }

    const onCreate = useCallback((id: string) => {
        if (id !== "create") return true;
        if (baseAcYear === undefined) {
                                                            // can't be undefined because of the if above but typescript doesn't know that
            CacheManager.createAcYear(componentID||0, data.name, data.year_start, data.year_end).catch(modal.openErrorModal);
        } else {
            CacheManager.updateAcYear({
                archived: baseAcYear.archived, component_id: baseAcYear.component_id,
                id: baseAcYear.id,
                ...data
            }).catch(modal.openErrorModal);
        }
        return true;
    }, [baseAcYear, componentID, data, modal.openErrorModal])

    return (
        <ModalBase
            title={baseAcYear ?  LANG.ac_year_edit : LANG.ac_year_create}
            buttons={[
                {
                    id: "create",
                    label: baseAcYear ? LANG.global_edit : LANG.global_create,
                    active: data.name.length > 2 && data.name.length <= 40 && data.year_start < data.year_end
                }
            ]}
            onButtonClick={onCreate}
        >
            <div className={ModalStyle.body_fields}>
                <ModalField
                    id='name'
                    type='text'
                    label={LANG.ac_year_modal_field_name}
                    defaultValue={data.name}
                    svg={mdiPound}
                    placeholder={LANG.ac_year_modal_field_name}
                    validation={value => value.length > 40 ? null : value}
                    onChange={(value: string) => setData({...data, name: value})}
                />
                <div className={ModalStyle.body_dual_fields}>
                    <ModalField
                        id='year_start'
                        type='number'
                        label={LANG.ac_year_modal_field_year_start}
                        svg={mdiClockStart}
                        defaultValue={data.year_start.toString()}
                        onChange={(value: string) => setData({...data, year_start: parseInt(value)})}
                    />
                    <ModalField
                        id='year_end'
                        type='number'
                        label={LANG.ac_year_modal_field_year_end}
                        svg={mdiClockEnd}
                        defaultValue={data.year_end.toString()}
                        onChange={(value: string) => setData({...data, year_end: parseInt(value)})}
                    />
                </div>
                {data.year_end <= data.year_start &&
                    <p className={ModalStyle.error}>{LANG.ac_year_modal_error_year}</p>}

            </div>
        </ModalBase>

    )
}