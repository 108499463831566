import { TableHeaderCol, TableProps } from "../../../declarations";
import { useState } from "react";
import Styles from "../styles/component/Tables.module.scss"
import { extendClassName } from "../../../util/utilsHelper";


type itemsType = { [Key: string]: any };

export function Table<T extends itemsType>(props: TableProps<T>): JSX.Element {

    const [sort, setSort] = useState<{ key: string, reverse: boolean } | undefined>();

    let changeSort = (key: string) => {
        setSort({ key: key, reverse: (sort && sort.key === key ? !sort.reverse : false) });
    }

    let applySort = (_data: T[], _sort: { key: string, reverse: boolean } | undefined) => {
        if (_sort === undefined) return _data;
        return _data.sort((a, b) => {
            if (a[_sort.key] < b[_sort.key]) {
                return _sort.reverse ? 1 : -1;
            }
            if (a[_sort.key] > b[_sort.key]) {
                return _sort.reverse ? -1 : 1;
            }
            return 0;
        })
    }

    let applyFilter = (_data: T[], _filter: string | undefined) => {
        if (!_filter) return _data;

        const reduceFiltered: { [key: string]: boolean } = {};
        props.header.forEach(({ key, canBeFiltered }) => {
            if (canBeFiltered || canBeFiltered === undefined) reduceFiltered[key] = true;
        })

        return _data.filter((row) => {
            for (let key in row) {
                if (!reduceFiltered[key]) continue;
                if (row[key].toString().toLowerCase().includes(_filter.toLowerCase())) return true;
            }
            return false;
        }
        )
    }

    const filteredHeader = props.header.filter(value => !value.disabled);

    return (
        <div className={extendClassName(Styles.table_container, props.className)} onScroll={(e: any) => {
            if (e.target.scrollTop === 0) {
                e.target.classList.contains('scrolled') && e.target.classList.remove('scrolled')
            } else {
                !e.target.classList.contains('scrolled') && e.target.classList.add('scrolled')
            }
        }}>
            <table className={Styles.table}>
                {props.showHeader &&
                    <thead>
                        <tr>
                            {
                                filteredHeader.map(({ key, display, camBeSorted }) => {
                                    if (camBeSorted === undefined || camBeSorted)
                                        return <th key={key} onClick={() => changeSort(key)}>{display}</th>
                                    else
                                        return <th key={key}>{display}</th>
                                })
                            }
                        </tr>
                    </thead>
                }
                <tbody>
                    {
                        applySort(applyFilter(props.data, props.filter), sort).map((row, index) => {
                            return (
                                <tr key={index} className={extendClassName(props.rowClassName, props.rowClassNameCallback ? props.rowClassNameCallback(row) : undefined)}>
                                    {
                                        filteredHeader.map(({ key, render }) => {
                                            return <td key={index + '-' + key}>
                                                {render ? render(row, row[key]) || row[key] : row[key]}
                                            </td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {props.children}
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function applySort<T extends itemsType>(_data: T[], _sort: { key: string, reverse: boolean } | undefined): T[] {
    if (_sort === undefined) return _data;
    return _data.sort((a, b) => {
        if (a[_sort.key] < b[_sort.key]) {
            return _sort.reverse ? 1 : -1;
        }
        if (a[_sort.key] > b[_sort.key]) {
            return _sort.reverse ? -1 : 1;
        }
        return 0;
    })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function applyFilter<T extends itemsType>(_data: T[], _filter: string | undefined, _header: TableHeaderCol<T>[]): T[] {
    if (!_filter) return _data;

    const reduceFiltered: { [key: string]: boolean } = {};
    _header.forEach(({ key, canBeFiltered }) => {
        if (canBeFiltered || canBeFiltered === undefined) reduceFiltered[key] = true;
    })

    return _data.filter((row) => {
        for (let key in row) {
            if (!reduceFiltered[key]) continue;
            if (row[key].toString().toLowerCase().includes(_filter.toLowerCase())) return true;
        }
        return false;
    }
    )
}


export function WindowTable<T extends { [Key: string]: any }>(props: TableProps<T>): JSX.Element {

    const [sort, setSort] = useState<{ key: string, reverse: boolean } | undefined>();

    // const data = useMemo<T[]>(() =>
    //     applySort(applyFilter(props.data, props.filter, props.header), sort)
    //     , [sort, props.filter, props.data])

    let changeSort = (key: string) => {
        setSort({ key: key, reverse: (sort && sort.key === key ? !sort.reverse : false) });
    }

    const filteredHeader = props.header.filter(value => !value.disabled);

    // const Item = ({ index, style }: { index: number, style: CSSProperties }) => {
    //     return (
    //         <tr key={index} style={style}>
    //             {
    //                 filteredHeader.map(({ key, render }) =>
    //                     <td key={index + '-' + key}>
    //                         {render ? render(data[index], data[index][key]) || data[index][key] : data[index][key]}
    //                     </td>
    //                 )
    //             }
    //         </tr>
    //     )
    // }


    return (
        <div className={extendClassName(Styles.table_container, props.className)} onScroll={(e: any) => {
            if (e.target.scrollTop === 0) {
                e.target.classList.contains('scrolled') && e.target.classList.remove('scrolled')
            } else {
                !e.target.classList.contains('scrolled') && e.target.classList.add('scrolled')
            }
        }}>
            <table className={Styles.table}>
                {props.showHeader &&
                    <thead>
                        <tr >
                            {
                                filteredHeader.map(({ key, display, camBeSorted }) => {
                                    if (camBeSorted === undefined || camBeSorted)
                                        return <th key={key} onClick={() => changeSort(key)}>{display}</th>
                                    else
                                        return <th key={key}>{display}</th>
                                })
                            }
                        </tr>
                    </thead>
                }
                <tbody>



                </tbody>
            </table>
            {props.children}
        </div>
    )
}
