import {IconButton} from "../common";
import {mdiAccountChildOutline, mdiPencil, mdiPencilOutline} from "@mdi/js";
import {ButtonStyle} from "../common/component/IconButton";

export function Test() {
    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center",
            gap: "1rem"
        }}>

            <IconButton
                title={'cc'}
                path={mdiPencil}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiPencilOutline}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                large
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiAccountChildOutline}
                large
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                d-style={ButtonStyle.ROUND_GRAY}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiAccountChildOutline}
                d-style={ButtonStyle.ROUND_GRAY}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                d-style={ButtonStyle.ROUND_GRAY}
                large
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiAccountChildOutline}
                d-style={ButtonStyle.ROUND_GRAY}
                large
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                d-style={ButtonStyle.CLEAR}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiAccountChildOutline}
                d-style={ButtonStyle.CLEAR}
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                d-style={ButtonStyle.CLEAR}
                large
            />

            <IconButton
                title={'cc'}
                path={mdiPencil}
                pathHover={mdiAccountChildOutline}
                d-style={ButtonStyle.CLEAR}
                large
                link={'/'}
            />

        </div>
    )
}