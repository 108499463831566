import {UComponent} from "../../../declarations";
import {useCallback, useEffect, useState} from "react";
import CacheManager from "../../../service/cacheManager";
import {ChangeEvent, useEventListener} from "../../common";

export function useComponent(id: number): [
    component: Promise<UComponent | undefined>,
    refresh: () => void
] {

    const [component, setComponent] = useState<Promise<UComponent | undefined>>(Promise.resolve(undefined))

    const refresh = useCallback(() => {
        setComponent(CacheManager.getComponent(id))
    }, [id])

    const eventListener = useCallback((event: ChangeEvent<UComponent>) => {
        if (event.action === 'change' && event.data.id === id) {
            setComponent(Promise.resolve(event.data))
        }
    }, [id])

    useEventListener('componentChange', eventListener)

    useEffect(refresh, [refresh])

    return [
        component,
        refresh
    ]

}