import { CsvColumn, CSVGroup, CSVStudent, UploaderCSVDataManagerProps, uploadingStatus } from "../../declaration";
import { useEffect, useState } from "react";
import Papa from "papaparse";
import { ALL_REQUIRED_HEADER_TAGS, CsvHeaderTag } from "../../enum";
import { getRandomColorFromPalette } from "../../../../util/utilsHelper";
import { GlobalSpinner } from "../../../common";
import { UploaderCSVDataDisplay } from "./UploaderCSVDataDisplay";
import { useModal } from "../../../../core/modal/ModalBase";
import { ConfirmationModal } from "../../../../core/modal/ConfirmationModal";
import { UploaderUploadDisplay } from "./UploaderUploadDisplay";
import CacheManager from "../../../../service/cacheManager";
import { REQUESTS } from "../../../../service/apiHelper";
import { PostGroup } from "../../../../declarations";
import { LANG } from "../../../../lang";
import { RequestError } from "../../../../util/RequestError";

export function UploaderCSVDataManager({
    file,
    onError,
    existGroups,
    acYearID,
    reset
}: UploaderCSVDataManagerProps): JSX.Element {

    const modal = useModal();

    const [header, setHeader] = useState<CsvColumn[] | undefined>(undefined)

    const [groups, setGroups] = useState<{ [key: string]: CSVGroup } | undefined>(undefined)
    const [students, setStudents] = useState<CSVStudent[] | undefined>(undefined)
    const [allStudentTagRequired, setAllStudentTagRequired] = useState<boolean>(false)

    const [data, setData] = useState<any[][] | undefined>(undefined)

    const [uploadingState, setUploadingState] = useState<uploadingStatus | undefined>(undefined)


    // generate header and parse data
    useEffect(() => {
        Papa.parse(file, {
            complete: function(results: Papa.ParseResult<any>) {
                if (results.errors.length > 0) {
                    console.error(results.errors)
                    onError(LANG.admin_csv_error_io_file)
                    return
                }
                let data = results.data.filter((e: any) => e.length > 0)

                if (data.length <= 1) {
                    onError(LANG.admin_csv_error_empty_file)
                    return;
                }

                let _header: any[] = data[0]
                setHeader(_header.map((e: any, i: number) => {
                    return {
                        name: typeof e === 'string' ? e : 'Colonne ' + i,
                        index: i,
                        tag: CsvHeaderTag.UNDEFINED
                    }
                }))
                setData(data.filter((e: any, i: number) => i !== 0 && e.length === _header.length))
            },
        });
    }, [file, onError]);

    // get students and groups from data when header and data are set
    useEffect(() => {
        if (data === undefined || header === undefined) {
            setStudents(undefined);
            setGroups(undefined);
            return;
        }
        const start = performance.now()
        // get all column index for each unique tag
        const colKey: { [key: number]: number } = ALL_REQUIRED_HEADER_TAGS.map(tag => {
            return {
                tag: tag,
                index: header.findIndex(value => value.tag === tag)
            }
        }).reduce((previousValue, currentValue) => {
            return { ...previousValue, [currentValue.tag]: currentValue.index }
        }, {})

        // get all column index for each group tag
        const groupColKey: number[] = header.reduce((previousValue, currentValue) =>
            currentValue.tag === CsvHeaderTag.GROUP ? [...previousValue, currentValue.index] : previousValue,
            [] as number[])

        // extra id for more code readability
        const idCol = colKey[CsvHeaderTag.ID]

        // if no group col and no id col defined
        if (groupColKey.length === 0 || idCol < 0) {
            setStudents(undefined);
            setGroups(undefined);
            return;
        }

        setGroups(oldGroups => {
            const newGroups: { [key: string]: CSVGroup } = {}
            const newStudents: CSVStudent[] = []

            // for each group col
            for (const i of groupColKey) {
                const seen = new Set();
                // for each line
                data.forEach(line => {
                    // get the sum of the group col and id col
                    const sum = line[i] + line[idCol]
                    if (isNaN(parseInt(line[idCol]))) return; // continue
                    // if the sum is not already seen
                    if (!seen.has(sum)) {
                        const studentID = parseInt(line[idCol])
                        const studentIndex = newStudents.findIndex(s => s.id === studentID)
                        // add the student to the new students
                        if (studentIndex === -1) {
                            newStudents.push({
                                id: studentID,
                                card_id: line[colKey[CsvHeaderTag.CARD_ID]],
                                firstname: line[colKey[CsvHeaderTag.FIRST_NAME]],
                                lastname: line[colKey[CsvHeaderTag.LAST_NAME]],
                                groups: [line[i]]
                            })
                        } else {
                            newStudents[studentIndex].groups.push(line[i])
                        }

                        // add to seen
                        seen.add(sum)
                        // if the group is not already defined
                        if (!newGroups.hasOwnProperty(line[i])) {
                            const eg = existGroups.find(g => g.name === line[i])
                            // add the group to the new groups
                            newGroups[line[i]] = {
                                ...oldGroups?.[line[i]] || {
                                    name: line[i],
                                    color: eg?.color || getRandomColorFromPalette(),
                                    ac_year_id: -1,
                                    component_id: -1,
                                    id: -1,
                                    // disable the group if it not already exist
                                    disabled: eg?.id === undefined,
                                    // auto link to the group with the same name if exist
                                    isLinkTo: eg?.id
                                },
                                students_count: 1
                            }
                        } else {
                            // @ts-ignore check with hasOwnProperty before
                            newGroups[line[i]].students_count++;
                        }
                    }
                })
            }

            // show time to get the data
            console.log('time to get data', performance.now() - start)
            setStudents(newStudents);
            setAllStudentTagRequired(!Object.values(colKey).includes(-1))
            return newGroups;
        })

    }, [data, existGroups, header]);

    const _groupChange = (group: string, newGroup: CSVGroup) => {
        // should not happen but just in case and for typescript to be happy
        if (groups === undefined || (groups[group] === undefined && group !== 'global')) return;

        setGroups({
            ...groups,
            [group]: { ...groups[group], ...newGroup }
        })
    }

    const enableAllGroup = (enable: boolean = true) => {
        if (groups === undefined) return;
        setGroups(
            Object.keys(groups).reduce<{ [key: string]: CSVGroup }>((result, key) => {
                const g = groups[key]
                g.disabled = !enable
                result[key] = g;
                return result;
            }, {})
        )
    }

    const startUpload = async () => {
        if (students === undefined || groups === undefined) return;

        setUploadingState({ status: 'uploading', message: LANG.admin_csv_send_in_progress })


        const studentsToSend = students.map(s => {
            return {
                id: s.id,
                card_id: s.card_id === '' ? null : s.card_id,
                firstname: s.firstname,
                lastname: s.lastname
            }
        })

        try {
            await CacheManager.fetchRequest(REQUESTS.POST_STUDENTS, { id: acYearID.toString() }, { students: studentsToSend })
        } catch (e: any) {
            setUploadingState({
                status: 'error',
                message: LANG.admin_csv_error_send_students_acyear,
                error: (e instanceof RequestError) ? e.message : LANG.error_unknown
            })
            return
        }
        const groupsToSend: PostGroup[] = Object.values(groups).filter(g => !g.disabled && g.isLinkTo === undefined).map(g => {
            return {
                name: g.name,
                color: g.color,
            }
        })

        const groupToID: {
            [key: string]: number
        } = Object.entries(groups).filter(([_, g]) => !g.disabled && g.isLinkTo !== undefined).reduce((prev, [key, g]) => {
            return {
                ...prev,
                [key]: g.isLinkTo,
            }
        }, {})

        for (const i in groupsToSend) {
            try {
                setUploadingState({
                    status: 'uploading',
                    message: LANG.admin_csv_send_group(parseInt(i) + 1, groupsToSend.length),
                })
                const res = await CacheManager._easyGet(REQUESTS.POST_GROUP, { id: acYearID.toString() }, {
                    name: groupsToSend[i].name,
                    color: groupsToSend[i].color,
                })
                // todo: look this is strange
                groupToID[groupsToSend[i].name] = res.id
            } catch (error: any) {
                setUploadingState({
                    status: 'error',
                    message: LANG.admin_csv_error_send_group(parseInt(i) + 1, groupsToSend.length),
                    error: error
                })
                console.error(error)
                return
            }
        }
        let count = 0;
        let groupCount = Object.keys(groupToID).length;
        for (const [key, groupID] of Object.entries(groupToID)) {
            // just in case
            if (groups[key] === undefined) continue;
            try {
                setUploadingState({
                    status: 'uploading',
                    message: LANG.admin_csv_send_students(key, count + 1, groupCount),
                })
                // if is group global send all students else send only students with the group
                if (key === 'global') {
                    await CacheManager.addStudentsToGroup(groupID, students.map(s => s.id))
                } else {
                    await CacheManager.addStudentsToGroup(groupID, students.filter(s => s.groups.includes(key)).map(s => s.id))
                }

            } catch (error: any) {
                setUploadingState({
                    status: 'error',
                    message: LANG.admin_csv_error_send_students(key, count + 1, groupCount),
                    error: error
                })
                console.error(error)
            }
            count++;
        }

        setUploadingState({ status: 'done', message: 'FIN' })
    }

    // wait for all data to be load
    if (header === undefined || data === undefined) {
        return (
            <div>
                <GlobalSpinner />
            </div>
        )
    }

    if (uploadingState !== undefined) {
        return (
            <UploaderUploadDisplay status={uploadingState} />
        )
    }

    return (
        <UploaderCSVDataDisplay
            existGroups={existGroups}
            groups={groups}
            students={students}
            headers={header}
            allStudentTagRequired={allStudentTagRequired}
            updateHeader={setHeader}
            updateGroup={_groupChange}
            enableAllGroup={enableAllGroup}
            askForUpload={() => {
                const studentsCount = students?.length || 0;
                const groupsCount = Object.values(groups || {}).filter(g => !g.disabled && g.isLinkTo === undefined).length;
                if (studentsCount === 0) return;
                modal.openModal(
                    <ConfirmationModal title={LANG.admin_csv_send}
                        message={`Ajouter <span class="intext-upc">${studentsCount}</span> étudiants et <span class="intext-upc">${groupsCount}</span> groups ?`}
                        useHtmlMessage
                        onConfirm={startUpload} />
                )
            }}
            reset={reset}
        />
    )
}
