import { useCallback, useEffect, useState } from "react";
import { UEvent } from "../../../declarations";
import CacheManager from "../../../service/cacheManager";
import { ChangeEvent, useEventListener } from "../../common";

export function useUEvent(eventID: number): {
    event?: UEvent,
    isError: boolean
} {
    const [event, setEvents] = useState<UEvent | undefined>(undefined)
    const [isError, setIsError] = useState<boolean>(false);

    const _setEvents = (e?: UEvent) => {
        setIsError(e === undefined);
        setEvents(e);
    }

    const refresh = useCallback(() => {
        if (isNaN(eventID)) {
            _setEvents(undefined);
            return;
        }
        CacheManager.getEvent(eventID.toString()).then(_setEvents).catch(e => {
            console.error(e);
            _setEvents(undefined);
        });
    }, [eventID]);

    const eventListener = useCallback((event: ChangeEvent<UEvent>) => {
        if (!isNaN(eventID) && event.action === 'change' && event.data.id === eventID) {
            setEvents(event.data)
        }
    }, [eventID]);

    useEventListener('eventChange', eventListener);

    useEffect(refresh, [refresh]);

    return {
        event,
        isError
    };

}
