import {useState} from "react";
import {AwaitSuspensePromise} from "../../common";
import {useGroups} from "../../group";
import {useParams} from "react-router-dom";
import {useAcYears} from "../hook/useAcYears";
import {UploaderCSVSelectFile} from "../component/uploader/UploaderCSVSelectFile";
import {UploaderCSVDataManager} from "../component/uploader/UploaderCSVDataManager";
import {LANG} from "../../../lang";


export function UploaderCSV(): JSX.Element {
    // params
    const {componentID} = useParams() as { componentID: string };

    // state
    const [file, setFile] = useState<File | undefined>(undefined);
    const [acYear, setAcYear] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    // data
    const [acYears] = useAcYears(parseInt(componentID))
    const [existGroups] = useGroups(undefined, false, undefined, acYear, true)

    const _reset = () => {
        setFile(undefined);
        setAcYear(undefined);
        setError(undefined);
    }

    if (file === undefined || acYear === undefined) {
        return (
            <AwaitSuspensePromise promise={acYears}>
                {acs => (
                    <UploaderCSVSelectFile acYears={acs} onAcYearSelected={acYear1 => setAcYear(acYear1?.id)}
                                           onFileSelected={setFile} file={file}/>
                )}
            </AwaitSuspensePromise>
        )
    }

    if (error !== undefined) {
        return (
            <div>
                {error}
                <button onClick={_reset}>{LANG.admin_csv_reset}</button>
            </div>
        )
    }

    return (
        <AwaitSuspensePromise promise={existGroups}>
            {eg => (
                <UploaderCSVDataManager
                    existGroups={eg}
                    file={file}
                    acYearID={acYear}
                    onError={setError}
                    reset={_reset}
                />
            )}
        </AwaitSuspensePromise>
    )
}