import { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom"
import { LANG } from "../../../lang"
import CacheManagerInstance from "../../../service/cacheManager";
import Styles from "../style/Login.module.scss"

export function LoginLayout() {
    const navigate = useNavigate();

    useEffect(() => {
        if (CacheManagerInstance.getSelfUnsafe() === undefined) {
            CacheManagerInstance.tryInitFromLocalStorage();
        }
        if (CacheManagerInstance.hasToken()) {
            navigate("/f-auth/select-acyear");
        }
    }, [navigate])

    return (
        <main className={Styles.main} id="login-main">
            <div className={Styles.container}>
                <Outlet />
            </div>
        </main>
    )
}

export function LoginSelectType() {
    return (
        <div className={Styles.select_type}>
            <a className={Styles.type_upc} href="/auth/v1/cas/login">{LANG.auth_login_upc}</a>
            <span className={Styles.type_or}> ou </span>
            <NavLink className={Styles.type_local} to="/f-auth/local">{LANG.auth_login_local}</NavLink>
        </div>
    )
}
