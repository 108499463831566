import { ModalBase, ModalField, ModalFieldSelect, useModal } from "../../../core/modal/ModalBase";
import React, { useMemo, useState } from "react";
import { mdiCalendarMonthOutline, mdiMapMarkerOutline, mdiPencilOutline, mdiPound } from "@mdi/js";
import { extendClassName, formatNumber } from "../../../util/utilsHelper";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import { Group, UEvent } from "../../../declarations";
import CacheManager from "../../../service/cacheManager";
import { DEFAULT_EVENT_COLOR } from "../../../util/const";
import { LANG } from "../../../lang";
import { useNavigate } from "react-router-dom";
import { useRooms } from "../hook/useRoom";

const possibleHours = Array.from({ length: 24 * 4 }, (_, i) => i).map((hour) => {
    return {
        id: hour.toString(),
        label: `${formatNumber(Math.floor(hour / 4), 2)}:${formatNumber(hour % 4 * 15, 2)}`
    }
});

const validationName = (value: string): string | null => value.length > 60 ? null : value;

interface EventModalCoreData {
    name: string,
    room: string,
    color: string,
    start_hour: string,
    end_hour: string
    date: string,
    end_date: string,
    isDaily: boolean
}

interface EventModalProps {
    defaultData: EventModalCoreData,
    onCreate: (state: EventModalCoreData, event: UEvent) => boolean;
    btnText: string,
    modalName: string
}

function EventModalCore({ defaultData, onCreate, btnText, modalName }: EventModalProps): JSX.Element {

    const [state, setState] = useState<EventModalCoreData>(defaultData);
    const rooms = useRooms(CacheManager.unsafeGetAcYear()?.component_id || 0);

    const onInputChange = <K extends keyof EventModalCoreData>(value: EventModalCoreData[K], id: K): void => {
        setState((prevState) => {
            return { ...prevState, [id]: value }
        });
    }

    const setIsDaily = (v: boolean): void => {
        onInputChange(v, 'isDaily');
    }

    const _onCreate = (id: string): boolean => {
        if (id !== "create") return true;
        if (!isValid) return false;


        const day = new Date(state.date)
        const end_day = new Date(state.end_date)

        const event: UEvent = {
            id: -1,
            name: state.name,
            room: state.room,
            color: state.color,
            start_time: new Date(day.getFullYear(), day.getMonth(), day.getDate(), Math.floor(parseInt(state.start_hour) / 4), (parseInt(state.start_hour) % 4) * 15).getTime() / 1000,
            end_time: state.isDaily ?
                new Date(day.getFullYear(), day.getMonth(), day.getDate(), Math.floor(parseInt(state.end_hour) / 4), (parseInt(state.end_hour) % 4) * 15).getTime() / 1000 :
                new Date(end_day.getFullYear(), end_day.getMonth(), end_day.getDate(), Math.floor(parseInt(state.end_hour) / 4), (parseInt(state.end_hour) % 4) * 15).getTime() / 1000,
            ac_year_id: CacheManager.getAcYear().id,
            component_id: CacheManager.getAcYear().component_id,
        }

        return onCreate(state, event);
    }

    const isValid =
        (state.isDaily || new Date(state.date).getTime() === new Date(state.end_date).getTime() ? // if daily or same day check if start hour is before end hour
            parseInt(state.start_hour) < parseInt(state.end_hour) :
            new Date(state.date).getTime() < new Date(state.end_date).getTime()
        ) &&                                                            // if not daily check if start date is before end date
        state.name.length > 0 &&                                        // check if name is not empty
        state.room.length > 0 &&                                        // check if room is not empty
        new Date(state.date).getTime() > 0 &&                           // check if date is valid
        (state.isDaily || new Date(state.end_date).getTime() > 0);      // if not daily check if end date is valid

    return (
        <ModalBase
            title={modalName}
            buttons={[
                { id: "create", label: btnText, active: isValid }
            ]}
            onButtonClick={_onCreate}
            className='modal-create-event'
        >
            <div className={ModalStyle.modal_switch_category}>
                <button
                    className={extendClassName(ModalStyle.switch_category_btn, state.isDaily ? ModalStyle.active : undefined)}
                    onClick={() => setIsDaily(true)}
                    children={LANG.event_type_daily}
                />
                <button
                    className={extendClassName(ModalStyle.switch_category_btn, !state.isDaily ? ModalStyle.active : undefined)}
                    onClick={() => setIsDaily(false)}
                    children={LANG.event_type_weekly}
                />
            </div>
            <div className={ModalStyle.body_fields}>
                <ModalField
                    id="name"
                    label={LANG.event_modal_field_name}
                    type="text"
                    defaultValue={state.name}
                    svg={mdiPound}
                    placeholder={LANG.event_modal_field_name}
                    validation={validationName}
                    onChange={onInputChange}
                />
                <ModalField
                    id="room"
                    label={LANG.event_modal_field_room}
                    type="text"
                    defaultValue={state.room}
                    svg={mdiMapMarkerOutline}
                    placeholder={LANG.event_modal_field_room}
                    validation={validationName}
                    onChange={onInputChange}
                    dastaListId="event-create-room-list"
                    dataList={rooms.map(d => d.name)}
                />

                <ModalField
                    id='color'
                    type='color'
                    label={LANG.global_color}
                    defaultValue={state.color}
                    svg={mdiPencilOutline}
                    onChange={onInputChange}
                />
                {state.isDaily &&

                    <ModalField
                        id="date"
                        label={LANG.global_date}
                        type="date"
                        svg={mdiCalendarMonthOutline}
                        defaultValue={state.date}
                        onChange={onInputChange}
                    />
                }

                {!state.isDaily &&
                    <>
                        <div className={ModalStyle.body_dual_fields}>
                            <ModalField
                                id="date"
                                label={LANG.event_modal_field_start_date}
                                type="date"
                                svg={mdiCalendarMonthOutline}
                                defaultValue={state.date}
                                onChange={onInputChange}
                            />
                            <ModalField
                                id="end_date"
                                label={LANG.event_modal_field_end_date}
                                type="date"
                                svg={mdiCalendarMonthOutline}
                                defaultValue={state.end_date}
                                onChange={onInputChange}
                            />
                        </div>
                        {new Date(state.date).getTime() > new Date(state.end_date).getTime() &&
                            <p className={ModalStyle.error}>{LANG.event_modal_field_date_error}</p>
                        }
                    </>
                }
                <div className={ModalStyle.body_dual_fields}>
                    <ModalFieldSelect
                        id="start_hour"
                        label={LANG.event_modal_field_start_hour}
                        values={possibleHours}
                        defaultValue={state.start_hour}
                        onChange={onInputChange}
                    />

                    <ModalFieldSelect
                        id="end_hour"
                        label={LANG.event_modal_field_end_hour}
                        values={possibleHours}
                        defaultValue={state.end_hour}
                        onChange={onInputChange}
                    />
                </div>
                {((state.isDaily || new Date(state.date).getTime() === new Date(state.end_date).getTime()) && parseInt(state.start_hour) >= parseInt(state.end_hour)) &&
                    <p className={ModalStyle.error}>{LANG.event_modal_field_hour_error}</p>}
            </div>
        </ModalBase>
    )
}

export function CreateEventModal({ quickGroup }: { quickGroup?: Group }): JSX.Element {
    const navigate = useNavigate();
    const defaultState = useMemo<EventModalCoreData>(() => {
        const currentHour = new Date().getHours() * 4 + Math.floor(new Date().getMinutes() / 15);
        return {
            name: (quickGroup?.name) || "",
            room: "",
            color: (quickGroup?.color) || DEFAULT_EVENT_COLOR,
            start_hour: currentHour.toString(),
            end_hour: (currentHour + 4).toString(),
            date: new Date().toISOString().split('T')[0],
            end_date: new Date().toISOString().split('T')[0],
            isDaily: true,
        }
    }, [quickGroup])
    const onCreate = (state: EventModalCoreData, event: UEvent): boolean => {
        CacheManager.createEvent(event).then(event => {
            if (quickGroup) {
                CacheManager.AddGroupToEvent(event.id, quickGroup.id).then(() => {
                    navigate(`/events/${event.id}`);
                })
            }
        }).catch((err) => {
            // todo: handle error
        });

        return true;
    }
    return <EventModalCore
        defaultData={defaultState}
        onCreate={onCreate}
        btnText={LANG.global_create}
        modalName={LANG.event_create}
    />

}

export function EditEventModal({ baseEvent }: { baseEvent: UEvent }): JSX.Element {
    const defaultState = useMemo<EventModalCoreData>(() => {
        const eventDate = new Date(baseEvent!.start_time * 1000)
        const eventEndDate = new Date(baseEvent!.end_time * 1000)
        return {
            name: baseEvent!.name,
            room: baseEvent!.room,
            color: baseEvent!.color,
            start_hour: (eventDate.getHours() * 4 + Math.floor(eventDate.getMinutes() / 15)).toString(),
            end_hour: (eventEndDate.getHours() * 4 + Math.floor(eventEndDate.getMinutes() / 15)).toString(),
            date: eventDate.toISOString().split('T')[0],
            end_date: eventEndDate.toISOString().split('T')[0],
            isDaily: baseEvent.end_time - baseEvent.end_time < 86400,
        }
    }, [baseEvent])
    const onCreate = (_: EventModalCoreData, event: UEvent): boolean => {
        // if nothing changed return do not trigger api call
        if (
            event.name === baseEvent.name &&
            event.room === baseEvent.room &&
            event.color === baseEvent.color &&
            event.start_time === baseEvent.start_time &&
            event.end_time === baseEvent.end_time
        ) return true;

        event.id = baseEvent.id;
        event.ac_year_id = baseEvent.ac_year_id;
        event.component_id = baseEvent.component_id;
        CacheManager.updateEvent(event).then().catch((err) => {
            // todo: handle error
            console.error(err);
        });
        return true;
    }

    return <EventModalCore
        defaultData={defaultState}
        onCreate={onCreate}
        btnText={LANG.global_edit}
        modalName={LANG.event_edit}
    />

}

export function DuplicateEventModal({ baseEvent }: { baseEvent: UEvent }): JSX.Element {

    const modal = useModal()

    const defaultState = useMemo<EventModalCoreData>(() => {
        const start = new Date(baseEvent!.start_time * 1000);
        const end = new Date(baseEvent!.end_time * 1000);
        return {
            name: baseEvent!.name,
            room: baseEvent!.room,
            color: baseEvent!.color,
            start_hour: (start.getHours() * 4 + Math.ceil(start.getMinutes() / 15)).toString(),
            end_hour: (end.getHours() * 4 + Math.ceil(end.getMinutes() / 15)).toString(),
            date: start.toISOString().split('T')[0],
            end_date: end.toISOString().split('T')[0],
            isDaily: baseEvent.end_time - baseEvent.end_time < 86400,
        }
    }, [baseEvent])
    const onCreate = (_: EventModalCoreData, event: UEvent): boolean => {
        event.ac_year_id = baseEvent.ac_year_id;
        event.component_id = baseEvent.component_id;
        CacheManager.duplicateEvent(baseEvent.id, event).then().catch((err) => {
            modal.openErrorModal(err)
        });
        return true;
    }

    return <EventModalCore
        defaultData={defaultState}
        onCreate={onCreate}
        btnText={LANG.global_duplicate}
        modalName={LANG.event_duplicate}
    />
}
