import { mdiCalendarMonthOutline } from "@mdi/js";
import { Fragment, useCallback, useEffect, useState } from "react";
import { UEvent } from "../../../declarations";
import { LANG } from "../../../lang";
import { REQUESTS } from "../../../service/apiHelper";
import CacheManagerInstance from "../../../service/cacheManager";
import { isSameDayNumber } from "../../../util/dateHelper";
import { extendClassName } from "../../../util/utilsHelper";
import { ChangeEvent, NoContent, useEventListener } from "../../common";
import Styles from "../styles/EventComponents.module.scss";
import { Event } from "./Event";


export interface EventCalendarSettings {
    hideValidated?: boolean
    hideArchived?: boolean
    startTime: number
    endTime: number
}

export interface EventCalendarBodyProps {
    className?: string
    settings: EventCalendarSettings
}

function removeDuplicateAndSortEvent(events: UEvent[]): UEvent[] {
    return events.filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index).sort((a, b) => a.start_time - b.start_time);
}

export function EventCalendarBody(props: EventCalendarBodyProps): JSX.Element {

    const [events, setEvents] = useState<UEvent[]>([]);

    const loadEvents = useCallback((start_time: number, end_time: number) => {
        CacheManagerInstance.fetchRequest(REQUESTS.GET_AC_YEAR_EVENTS,
            {
                id: CacheManagerInstance.getAcYear().id.toString(),
                start_time: start_time.toString(),
                end_time: end_time.toString()
            }).then((data) => {

                if (data.data) {
                    // add new events to the list and remove duplicates and sort by start_time
                    setEvents(removeDuplicateAndSortEvent(data.data));
                }
            })
    }, []);

    const eventChange = (event: ChangeEvent<UEvent>) => {
        // check if event is in the current week if it's not, don't add it
        if (!(event.data.end_time > props.settings.startTime && event.data.start_time < props.settings.endTime)) {
            return;
        }

        // add new events to the list and remove duplicates and sort by start_time
        // new event is at the beginning of the list for keep last event if update is provided to an event
        // the old version of event will be removed by removeDuplicateAndSortEvent
        setEvents(removeDuplicateAndSortEvent([event.data, ...events]));
    };

    useEffect(() => {
        if (props.settings.startTime === 0 || props.settings.endTime === 0) return;
        loadEvents(props.settings.startTime, props.settings.endTime)
    }, [loadEvents, props.settings.endTime, props.settings.startTime])

    useEventListener('eventChange', eventChange);

    const classes = [Styles.calendar__body, props.className]

    const flagsFilter = 0 | (props.settings.hideValidated ? 0b1 : 0) | (props.settings.hideArchived ? 0b10 : 0)

    const filteredEvent = flagsFilter === 0 ? events : events.filter(e => ((e.flags || 0) & flagsFilter) === 0)

    if (filteredEvent.length === 0) {
        return <div className={extendClassName(...classes, Styles.calendar__body_empty)}>
            <NoContent
                iconPath={mdiCalendarMonthOutline}
                text={LANG.event_calendar_empty}
            />
        </div>
    }

    const eventPeriod = filteredEvent.filter((event) => event.end_time - event.start_time > 86400).sort((a, b) => a.start_time - b.start_time);
    const eventDay = filteredEvent.filter((event) => event.end_time - event.start_time <= 86400).sort((a, b) => a.start_time - b.start_time);


    return (
        <div className={extendClassName(...classes)}>
            {eventPeriod.length > 0 &&
                <>
                    <div className={Styles.calendar__day_separator}>
                        <span>{LANG.event_calendar_separator_weekly}</span>
                    </div>
                    {
                        eventPeriod.map((event, index) => <Event event={event} groups={[]} key={index} />)
                    }
                </>
            }
            {eventDay.map((event, index) => {
                return (
                    <Fragment key={index}>
                        {(index === 0 || !isSameDayNumber(filteredEvent[index - 1].start_time, event.start_time)) &&
                            <div className={Styles.calendar__day_separator}>
                                <span>{new Date(event.start_time * 1000).toLocaleDateString("fr-FR", {
                                    weekday: "long",
                                    day: "numeric",
                                    month: "long",
                                })}
                                </span>
                            </div>
                        }
                        <Event event={event} groups={[]} />
                    </Fragment>

                )
            })
            }

        </div>
    )

}
