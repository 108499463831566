import logo from '../../../res/UniversiteParisCite_monogramme_couleur_RVB.png'
import {APP_NAME, TOOL_TIP_ID} from "../../../util/const";
import {NavLink} from "react-router-dom";
import Styles from '../styles/component/Header.module.scss'
import CacheManager from "../../../service/cacheManager";
import Icon from "@mdi/react";
import {
    mdiAccountGroup,
    mdiAccountGroupOutline,
    mdiAccountSchool,
    mdiAccountSchoolOutline,
    mdiCalendarMonth,
    mdiCalendarMonthOutline
} from "@mdi/js";
import {extendClassName} from "../../../util/utilsHelper";
import React, {memo} from "react";
import {IfAllow} from "./IfAllow";
import {Permission} from "../../../util/pernission";
import {childrenProp} from "../../../declarations";
import {UserBrandProfile} from "./UserBrandProfile";


function HeaderNavItem(props: { to: string, tooltip: string, icon: string, iconSelect: string }) {
    return (
        <div data-tooltip-id={TOOL_TIP_ID} data-tooltip-content={props.tooltip}>
            <NavLink to={props.to} className={Styles.nav__item}>
                <Icon path={props.icon} className={Styles.nav__item__icon}/>
                <Icon path={props.iconSelect} className={extendClassName(Styles.nav__item__icon, Styles.icon__active)}/>
            </NavLink>
        </div>
    )
}

function HeaderBase({children}: childrenProp): JSX.Element {
    const acYear = CacheManager.unsafeGetAcYear();
    return (
        <header className={Styles.Header}>
            <div className={Styles.header__start}>
                <NavLink to="/" className={Styles.header__brand}>
                    <img src={logo} alt="logo" className={Styles.brand__logo}/>
                    <div className={Styles.brand__logo_text}>
                        <h1>{APP_NAME}</h1>
                        <h2>{CacheManager.unsafeGetAcYear()?.id === -1 ? 'Admin' : `${acYear?.component?.name} - ${acYear?.name}`}</h2>
                    </div>
                </NavLink>
            </div>
            <nav className={Styles.header__nav}>
                {children}
            </nav>
            <div className={Styles.header__end}>
                <UserBrandProfile />
            </div>
        </header>
    )
}

function Header_(): JSX.Element {

    return (
        <HeaderBase>
            <HeaderNavItem to="/events" tooltip='Evènements' icon={mdiCalendarMonthOutline}
                           iconSelect={mdiCalendarMonth}/>
            <IfAllow permission={Permission.VIEW_GROUP}>
                <HeaderNavItem to="/groups" tooltip='Groupes' icon={mdiAccountGroupOutline}
                               iconSelect={mdiAccountGroup}/>
            </IfAllow>
            <IfAllow permission={Permission.VIEW_STUDENTS}>
                <HeaderNavItem to="/students" tooltip='Etudiants' icon={mdiAccountSchoolOutline}
                               iconSelect={mdiAccountSchool}/>
            </IfAllow>
            {/*<HeaderNavItem to="/debug" tooltip='Debug' icon={mdiBugOutline} iconSelect={mdiBug}/>*/}
        </HeaderBase>
    )
}

function HeaderAdmin_(): JSX.Element {
    return (
        <HeaderBase>
<></>
        </HeaderBase>
    )
}

export const Header = memo(Header_);
export const HeaderAdmin = memo(HeaderAdmin_);