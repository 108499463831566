import { Link, useNavigate } from "react-router-dom";
import Styles from "../style/Login.module.scss";
import Icon from "@mdi/react";
import { mdiAccountOutline } from "@mdi/js";
import { FormEvent, useRef, useState } from "react";
import CacheManager from "../../../service/cacheManager";
import { LANG } from "../../../lang";

export function RegisterCas(): JSX.Element {
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false)
    const registerRef = useRef<HTMLFormElement>(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const navigate = useNavigate();

    if (!queryParameters.has('log_token')) {
        navigate('/');
    }


    const id = queryParameters.get('id');

    const register = (e: FormEvent) => {
        e.preventDefault();
        if (registerRef.current === null) {
            return;
        }
        let fd: FormData = new FormData(registerRef.current);
        if (!fd.has("email") || typeof fd.get("email") !== "string") {
            setError(LANG.auth_error_missing_field)
            return;
        }
        // @ts-ignore : check if not null before but typescript is stupid <3
        CacheManager.registerCAS(queryParameters.get('log_token') || '', fd.get("email")).then(() => {
            setSuccess(true)
        }).catch((e) => {
            setError(e.message)
            if (registerRef.current)
                registerRef.current.reset()
        });
    }

    let logout = () => {
        CacheManager.logout();
    }

    let body;
    if (success) {
        body = (
            <div className={Styles.login}>
                <div>{LANG.auth_cas_success}</div>
                <Link to={"/f-auth"} className={Styles.btn}>{LANG.auth_login}</Link>
            </div>
        )
    } else {
        body = (
            <form ref={registerRef} className={Styles.login} onSubmit={register}>
                <div className={Styles.cas_register_profile}>
                    <div className={Styles.login_profile}>
                        <Icon path={mdiAccountOutline} />
                    </div>
                    <div className={Styles.cas_register_id}>
                        <span> {id} </span>
                        <button className={Styles.notme} onClick={logout}>{LANG.auth_not_me}</button>
                    </div>
                </div>
                <h3>{LANG.auth_cas_register_title}</h3>
                {error && <div className={Styles.login__error}>
                    {error}
                </div>}
                <input type="email" name='email' placeholder="Email" required />
                <button type='submit' >{LANG.auth_register}</button>
            </form>
        )
    }

    return (
        <main className={Styles.main}>
            <div className={Styles.container}>
                {body}
            </div>
        </main>
    )
}
