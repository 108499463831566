import {EventGroupChangeEventData, Group} from "../../../declarations";
import {useCallback, useEffect, useState} from "react";
import CacheManager from "../../../service/cacheManager";
import {ChangeEvent, useEventListener} from "../../common";

/*
    useGroups is a hook that returns a promise of groups and a refresh function.
    if eventID provide return all group of the event
    if studentID provide return all group of the student
    eventID and studentID can't be cumulate
 */
export function useGroups(eventID?: number, listenEvent?: boolean, studentID?: number, componentID?: number, ignoreError?: boolean): [
    groups: Promise<Group[]>,
    refresh: () => void
] {
    if (([eventID, studentID, componentID].reduce((p, c) => c === undefined ? p : (p||0)+1, 0)||0) > 1) {
        if (!ignoreError)
            throw new Error("eventID and studentID and componentID can't be cumulate")
    }

    const [groups, setGroups] = useState<Promise<Group[]>>(Promise.resolve([]))

    const refresh = useCallback( () => {
        if (eventID !== undefined)
            setGroups(CacheManager.getEventGroups(eventID.toString()))
        else if (studentID !== undefined)
            setGroups(CacheManager.getStudentGroups(studentID))
        else if (componentID !== undefined || CacheManager.getAcYear().id > 0)
            setGroups(CacheManager.getGroups(componentID))
        else {
            setGroups(Promise.resolve([]))
        }

    }, [componentID, eventID, studentID])

    useEffect(refresh, [refresh])
    // todo fix callback for componentID
    const eventCallback = useCallback((event: ChangeEvent<Group>) => {
        if (!listenEvent) return
        if (event.action === 'create') {
            if (eventID !== undefined || studentID !== undefined) return
            setGroups(groups.then((groups) => {
                groups.push(event.data)
                return groups
            }))
        } else if (event.action === 'change') {
            setGroups(groups.then((groups) => {
                const index = groups.findIndex((group) => group.id === event.data.id)
                // should not happen but just in case
                if (index === -1) {
                    // if eventID is defined, we don't want to add the group because it's not part of the event
                    if (eventID !== undefined || studentID !== undefined) return groups
                    groups.push(event.data)
                } else {
                    let oldMemberCount = groups[index].students_count
                    groups[index] = event.data
                    groups[index].students_count = oldMemberCount
                }
                return groups
            }))
        } else {
            setGroups(groups.then((groups) => {
                const index = groups.findIndex((group) => group.id === event.data.id)
                if (index >= 0)
                    groups.splice(index, 1)
                return groups
            }))
        }
    }, [listenEvent, eventID, studentID, groups])

    const groupEventChangeCallback = useCallback((event: ChangeEvent<EventGroupChangeEventData>) => {
        if (event.data.eventID !== eventID) return
        if (event.action === 'create') {
            setGroups(groups.then((groups) => {
                const index = groups.findIndex((group) => group.id === event.data.group.id)
                if (index === -1) {
                    groups.push(event.data.group)
                }
                return groups
            }))
        } else if (event.action === 'delete') {
            setGroups(groups.then((groups) => {
                const index = groups.findIndex((group) => group.id === event.data.group.id)
                groups.splice(index, 1)
                return groups
            }))
        }
    }, [groups, eventID])

    useEventListener('groupChange', eventCallback)
    useEventListener('eventGroupChange', groupEventChangeCallback)


    return [groups, refresh]
}