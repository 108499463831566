import { Presence } from "../student";
import { Event } from "../../planning";
import Icon from "@mdi/react";
import { mdiAccountAlert, mdiAccountCancel, mdiAccountCheck, mdiAccountClock } from "@mdi/js";
import Styles from "../style/component/StudentPresences.module.scss"
import { extendClassName } from "../../../util/utilsHelper";
import { classNameProp } from "../../../declarations";
import { ToolTipContainer } from "../../common";
import { LANG } from "../../../lang";
import { SCAN_TYPE } from "../../../util/const";

const getIcon = (presence: Presence) => {

    if (presence.time) {
        if (presence.type === SCAN_TYPE.JUSTIFIED) {
            return (
                <ToolTipContainer data-tooltip-content={LANG.student_justify_reason(presence.reason)}>
                    <Icon path={mdiAccountAlert} className={extendClassName(Styles.icon, Styles.justified)} />
                </ToolTipContainer>
            )
        } else if (presence.type === SCAN_TYPE.ABSENT) {
            return <Icon path={mdiAccountCancel} className={extendClassName(Styles.icon, Styles.wrong)} />
        } else {
            return <Icon path={mdiAccountCheck} className={extendClassName(Styles.icon, Styles.ok)} />
        }
    } else {
        return <Icon path={mdiAccountClock} className={extendClassName(Styles.icon, Styles.neutre)} />
    }

}

export function StudentPresences({ presences, className }: { presences: Presence[] } & classNameProp): JSX.Element {

    return (
        <section className={className}>
            <div className={Styles.scrollable}>
                {
                    presences.sort((a, b) => b.start_time - a.start_time).map((presence) => (
                        <div key={presence.id} className={Styles.event_container}>
                            {getIcon(presence)}
                            <Event className={Styles.event} event={presence} groups={[]} dateOptions={{
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit"
                            }} />
                        </div>
                    ))
                }
            </div>
        </section>
    )
}
