import Styles from '../styles/SelectGroup.module.scss'
import ModalStyles from '../../../core/modal/Modal.module.scss'
import { GroupCore, SmartGroupLists } from "../component";
import { extendClassName } from "../../../util/utilsHelper";
import { Group } from "../../../declarations";
import { useModal } from "../../../core/modal/ModalBase";
import { LANG } from "../../../lang";
import { SearchBar } from '../../common';
import { useState } from 'react';



function SelectGroup({ callback }: { callback: (group: Group) => void }): JSX.Element {
    const [filter, setFilter] = useState<string>("");
    const modal = useModal()
    const onSelect = (group: Group) => {
        modal.closeModal()
        callback(group)
    }

    function BTNGroup({ group }: { group: Group }): JSX.Element {
        return (
            <button onClick={() => onSelect(group)}>
                <GroupCore group={group}></GroupCore>
            </button>
        )
    }

    return (
        <div className={extendClassName(Styles.container, ModalStyles.popout_small)}>
            <div className={Styles.head}>
                <h1>{LANG.group_select}</h1>
                <SearchBar className={Styles.group_search_bar} onChange={setFilter} ignoreCase={true} placeholder={LANG.group_search} />
            </div>
            <div className={Styles.body}>
                <SmartGroupLists GroupComponent={BTNGroup} className={Styles.group_list} filter={filter} />
            </div>
        </div>
    )
}

export {
    SelectGroup
}
