export function getStartDayTime(currentTime?: number): number {
    const date = currentTime === undefined ? new Date() : new Date(currentTime * 1000);
    date.setHours(0, 0, 0, 0);
    return Math.ceil(date.getTime() / 1000);
}

export function getEndDayTime(currentTime?: number): number {
    const date = currentTime === undefined ? new Date() : new Date(currentTime * 1000);
    date.setHours(23, 59, 59, 999);
    return Math.ceil(date.getTime() / 1000);
}

export function addWeek(date: Date, week: number): Date {
    date.setDate(date.getDate() + (week * 7));
    return date;
}

export function addWeekNumber(date: number, week: number): number {
    return Math.ceil(addWeek(new Date(date * 1000), week).getTime() / 1000);
}

export function addDay(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
}

export function addDayNumber(date: number, days: number): number {
    return Math.ceil(addDay(new Date(date * 1000), days).getTime() / 1000);
}

export function getStartWeekTime(currentTime?: number): number {
    const date = currentTime === undefined ? new Date() : new Date(currentTime * 1000);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - date.getDay());
    return Math.ceil(date.getTime() / 1000);
}

export function getEndWeekTime(currentTime?: number): number {
    const date = currentTime === undefined ? new Date() : new Date(currentTime * 1000);
    date.setHours(23, 59, 59, 0);
    date.setDate(date.getDate() + (6 - date.getDay()));
    return Math.ceil(date.getTime() / 1000);
}

export function isSameDayNumber(date1: number, date2: number): boolean {
    return isSameDay(new Date(date1 * 1000), new Date(date2 * 1000));
}

export function isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
