import {APIAnswer} from "../declarations";

class RequestError extends Error {

    public readonly statusCode: number;
    public readonly json: APIAnswer<null>|null;

  constructor(message: string, statusCode: number, json: APIAnswer<null>|null) {
    super(message);
    this.statusCode = statusCode;
    this.json = json;
  }
}

export {RequestError};