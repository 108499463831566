
export function PageNotFound() : JSX.Element {
    return (
        <div>
            <p>404 Page not found</p>
        </div>
    );
}

export function SomethingWentWrong() : JSX.Element {
    return (
        <div>
            <p>Something went wrong</p>
        </div>
    );
}