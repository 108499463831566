import {UPGroupProps} from "../../declaration";
import Styles from '../../styles/uploader/UPGroup.module.scss'
import {DEFAULT_EVENT_COLOR} from "../../../../util/const";
import Icon from "@mdi/react";
import {
    mdiAccount,
    mdiEyeOffOutline,
    mdiEyeOutline, mdiFilter, mdiFilterOutline,
    mdiLinkVariantPlus,
    mdiLinkVariantRemove,
    mdiPencil
} from "@mdi/js";
import {IconButton, ToolTipContainer} from "../../../common";
import {extendClassName} from "../../../../util/utilsHelper";
import {useModal} from "../../../../core/modal/ModalBase";
import {ConfirmationModal} from "../../../../core/modal/ConfirmationModal";
import CreateEditGroupModal from "../../../group/modal/CreateEditGroupModal";
import {LinkGroup} from "../../modal/uploader/LinkGroup";
import {LANG} from "../../../../lang";

export function UPGroup(props: UPGroupProps): JSX.Element {
    const modal = useModal();

    const enableDisableToggle = () => {
        props.onChange({...props.group, disabled: !props.group.disabled})
    }

    const unlink = () => {
        modal.openModal(<ConfirmationModal title={LANG.admin_csv_unlink} message={LANG.admin_csv_unlink_confirm} onConfirm={() => {
            props.onChange({...props.group, isLinkTo: undefined})
        }} />)
    }

    const link = () => {
        modal.openModal(<LinkGroup targetGroup={props.group} groups={props.existGroupForLink} onLink={(groupID) => {
            const g = props.existGroupForLink.find(g => g.id === groupID)
            if (g === undefined) return;
            props.onChange({...props.group, isLinkTo: groupID, color: g.color, name: g.name})
        }} />)
    }

    const edit = () => {
        modal.openModal(<CreateEditGroupModal baseGroup={props.group} onConfirm={props.onChange} />)
    }

    const btn = props.group.disabled ? (
        <>
            <IconButton path={mdiEyeOutline}  title={LANG.admin_csv_activate} aria-label='add' onClick={enableDisableToggle}/>
        </>
    ) : props.group.isLinkTo ? (
        <>
            <IconButton path={mdiEyeOffOutline}  title={LANG.admin_csv_deactivate} aria-label='remove' onClick={enableDisableToggle}/>
            <IconButton path={mdiLinkVariantRemove}  title={LANG.admin_csv_unlink} aria-label='unlink' onClick={unlink} />
            <IconButton path={mdiPencil}  title={LANG.global_edit} aria-label='edit' disabled />
        </>
    ) : (
        <>
            <IconButton path={mdiEyeOffOutline}  title={LANG.admin_csv_deactivate} aria-label='remove' onClick={enableDisableToggle}/>
            <IconButton path={mdiLinkVariantPlus}  title={LANG.admin_csv_unlink} aria-label='limk' onClick={link}/>
            <IconButton path={mdiPencil}  title={LANG.global_edit} aria-label='edit' onClick={edit} />
        </>
    )



    return (
        <div className={extendClassName(Styles.group__link, props.group.disabled ? Styles.group__disable : undefined)}>
            <div className={Styles.group} role='group' aria-label='group'>
                <div className={Styles.group__color} aria-label='group color'
                     style={{backgroundColor: props.group.color || DEFAULT_EVENT_COLOR}}>
                </div>
                <span className={Styles.group__name} aria-label='group name'>{props.group.name}</span>

                <div className={Styles.action_container}>
                    <IconButton path={props.isFilter ? mdiFilterOutline : mdiFilter}  title='Filtrer' aria-label='filter' onClick={props.onFilterSelect}  />
                    {btn}
                </div>

                <ToolTipContainer className={Styles.group__students_count} data-tooltip-content={LANG.group_students_count}
                                  aria-label='group students count'>
                    <span className={Styles.students_count__number}>{props.group.students_count || 0}</span>
                    <Icon className={Styles.students_count__ico} path={mdiAccount}/>
                </ToolTipContainer>
            </div>
        </div>
    )
}