import {useCallback, useEffect, useState} from "react";
import CacheManager from "../../../service/cacheManager";
import {Presence} from "../student";
import {Student} from "../../../declarations";

export function useStudentPresences(studentID: number, getStudent: boolean): {
    presences: Promise<Presence[]>,
    refresh: () => void,
    student: Promise<Student | undefined>
} {
    const [presences, setPresences] = useState<Promise<Presence[]>>( Promise.resolve([]) )
    const [student, setStudent] = useState<Promise<Student | undefined> >(Promise.resolve(undefined))

    const refresh = useCallback(() => {
        setPresences(CacheManager.getPresences(studentID));
        if (getStudent) {
            setStudent(CacheManager.getStudent(studentID));
        }
    }, [studentID, getStudent])

    useEffect(refresh, [refresh])

    return {presences, refresh, student}

}