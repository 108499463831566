import { BlockPicker, ColorResult } from 'react-color';
import React, { MouseEvent, useState } from "react";
import { COLOR_PALETTE, DEFAULT_EVENT_COLOR } from "../../util/const";
import { useModal } from "./ModalBase";

function ColorPicker({ onChange, defaultValue }: {
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    defaultValue?: string
}) {

    const modal = useModal()
    const [color, setColor] = useState<string>(defaultValue || DEFAULT_EVENT_COLOR)

    const _onChange = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        setColor(color.hex)
        event.currentTarget.value = color.hex
        onChange(event)
    }

    const _showPicker = (e: MouseEvent<HTMLDivElement>) => {
        modal.openModal(<BlockPicker
            color={color}
            onChange={_onChange}
            colors={COLOR_PALETTE}
        />, {
            position: 'relative',
            layer: 100,
            relativeTo: e.currentTarget,
            relativeDirection: 'bottom',
        })
    }

    // const [color, setColor] = useState<Color>(defaultValue||DEFAULT_EVENT_COLOR)

    return (
        <div className="color-picker" onClick={_showPicker}
            style={{ backgroundColor: color }}>
        </div>
    )
}

export {
    ColorPicker
}
