import { permission } from "../../../util/pernission";
import { Permissible } from "../../../declarations";
import CacheManager from "../../../service/cacheManager";

export function IfAllow(
    {
        children,
        permission,
        target,
        overwrite
    }: {
        children: JSX.Element,
        permission: permission,
        target?: Permissible
        overwrite?: boolean
    }
): JSX.Element {
    if (overwrite !== undefined) return overwrite ? children : <></>;

    const localPerm: permission | undefined = target ? target.permission : CacheManager.unsafeGetAcYear()?.component?.permission;
    if (localPerm && (localPerm & permission) === permission) {
        return children;
    }
    return <></>;
}
