import Styles from "../styles/GroupPage.module.scss";
import { Suspense, useEffect, useState } from "react";
import { ChangeEvent, GlobalSpinner, IconButton, useEventListener } from "../../common";
import { Group, StudentScanTypeCount } from "../../../declarations";
import CacheManager from "../../../service/cacheManager";
import { Await, useNavigate, useParams } from "react-router-dom";
import { DEFAULT_EVENT_COLOR } from "../../../util/const";
import { mdiPencil, mdiPencilOutline } from "@mdi/js";
import { useModal } from "../../../core/modal/ModalBase";
import CreateEditGroupModal from "../modal/CreateEditGroupModal";
import { GroupStudents } from "../component";
import { ButtonStyle } from "../../common/component/IconButton";
import { Permission } from "../../../util/pernission";
import { LANG } from "../../../lang";

function GroupDetails(): JSX.Element {

    const { id } = useParams()
    const modal = useModal();
    const navigate = useNavigate()

    const [group, setGroup] = useState<Promise<Group>>(Promise.resolve({} as Group));
    const [students, setStudents] = useState<Promise<StudentScanTypeCount[]>>(Promise.resolve([]));

    useEffect(() => {
        if (id !== undefined) {
            setGroup(CacheManager.getGroup(id));
            setStudents(CacheManager.getGroupStudentsWithScan(id));
        }
    }, [id])

    useEventListener('groupChange', (event: ChangeEvent<Group>) => {
        if (event.data.id === parseInt(id ?? '0')) {
            if (event.action === 'delete') {
                navigate('/group');
            } else {
                setGroup(Promise.resolve(event.data));
            }
        }
    })
    const idNumber = parseInt(id || "");
    if (isNaN(idNumber)) {
        return <div>ERROR</div>
    }


    return (
        <div className={Styles.group_view}>
            <Suspense fallback={<GlobalSpinner className={Styles.view_pt} />}>
                <Await resolve={group}>
                    {(resolveGroup) => (
                        <div className={Styles.view_pt + " panel_content_head"}
                            style={{ borderColor: resolveGroup.color || DEFAULT_EVENT_COLOR }}>
                            <div className='head__title'>
                                <h2>{resolveGroup.name}</h2>
                            </div>
                            <IconButton
                                path={mdiPencilOutline}
                                pathHover={mdiPencil}
                                title={LANG.group_edit}
                                onClick={() => {
                                    modal.openModal(<CreateEditGroupModal baseGroup={resolveGroup} />)
                                }}
                                title-place={"right"}
                                d-style={ButtonStyle.CLEAR}
                                permission={Permission.MANAGE_GROUP}
                                large
                            />
                        </div>
                    )}
                </Await>
            </Suspense>
            <div className={Styles.view_pd}>
                <Suspense fallback={<GlobalSpinner />}>
                    <Await resolve={students}>
                        {(resolveStudents) => (
                            <GroupStudents students={resolveStudents} groupID={idNumber} />
                        )}
                    </Await>
                </Suspense>
            </div>
        </div>
    )
}

export {
    GroupDetails
}
