import { useState } from "react";
import Styles from "../styles/EventComponents.module.scss";
import { EventCalendarBody, EventCalendarSettings } from "./EventCalendarBody";
import { EventCalendarHead } from "./EventCalendarHead";


function EventCalendar(props: { className?: string }): JSX.Element {

    const [settings, setSettings] = useState<EventCalendarSettings>({ endTime: 0, startTime: 0 })

    return (
        <div className={Styles.calendar}>
            <EventCalendarHead onSettingsChange={setSettings} defaultHideArchived={true} />
            <EventCalendarBody settings={settings} className={props.className} />
        </div>
    )
}

export { EventCalendar };
