import Styles from "../style/SelectStudents.module.scss"
import ModalStyles from "../../../core/modal/Modal.module.scss"
import {extendClassName} from "../../../util/utilsHelper";
import {SearchBar} from "../../common";
import {useCallback, useRef, useState} from "react";
import {Student} from "../../../declarations";
import Icon from "@mdi/react";
import {mdiClose} from "@mdi/js";
import {useModal} from "../../../core/modal/ModalBase";
import {StudentList} from "../component/StudentList";
import {SelectableStudent} from "../student";
import {LANG} from "../../../lang";


function StudentSelected({student, onRemove} : {student: Student, onRemove: (id: number) => void}) {
    return (
        <div className={Styles.select_student}>
            <div className={Styles.students__info}>
                <span>{student.firstname}</span>
                <span>{student.lastname}</span>
            </div>
            <button className={Styles.remove} onClick={() => onRemove(student.id)}>
                <Icon path={mdiClose} />
            </button>
        </div>
    )
}

function SelectStudents({callBack} : {callBack: (students: Student[]) => void}): JSX.Element {
    const [filter, setFilter] = useState<string>("")
    // const [hasNextPage, setHasNextPage] = useState<boolean>(true)

    // const [isNextPageLoading, setIsNextPageLoading] = useMemo<boolean>(false)
    // const [items, setItems] = useState<SelectableStudent[]>([])
    // const cacheItems = useMemo<SelectableStudent[]>(() => [], [])
    // const filterLoad = useMemo<{[key: string]: boolean}>(() => ({}), [])

    const [students, setStudents] = useState<SelectableStudent[]>([])

    const search = useRef<HTMLInputElement>(null)

    const modal = useModal()

    const onSelectedChange = useCallback((students: SelectableStudent[]) => {
        setStudents(students)
    }, [])

    const sendData = useCallback(() => {
        callBack(students)
        modal.closeModal()
    }, [students, modal, callBack])

    return (
        <div className={extendClassName(Styles.select_box, ModalStyles.popout_medium)}>
            <div className={Styles.box__head}>
                <h1>{LANG.students_select_modal_title}</h1>
                <div className={Styles.selected_students}>
                    {students.map((st) => (
                        <StudentSelected key={'s-' + st.id} student={st} onRemove={() => {}} />
                    ))}
                </div>
                <SearchBar className={Styles.search_bar} ref={search} onChange={setFilter} ignoreCase={true} placeholder={LANG.students_select_modal_search}/>
            </div>
            <div className={Styles.box__body}>
                <StudentList chackable={true} search={search} onSelectedChange={onSelectedChange} filter={filter} setFilter={setFilter} />.
            </div>
            <div className={Styles.box__foot}>
                <button className={Styles.validate} onClick={sendData}>{LANG.students_select_modal_btn_add}</button>
            </div>
        </div>
    )
}

export {
    SelectStudents
};