import { mdiAccountOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LANG } from "../../../lang";
import CacheManagerInstance from "../../../service/cacheManager";
import Styles from "../style/Login.module.scss"

export function LoginPassword() {
    const [error, setError] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()

    let log = (e: FormEvent) => {
        e.preventDefault();

        if (password === "" || email === "") {
            setError(LANG.auth_error_missing_field)
            return;
        }

        // @ts-ignore : check if not null before but typescript is stupid <3
        CacheManagerInstance.login(email, password).then(() => {
            navigate("/f-auth/select-acyear")
        }).catch((e) => {
            setError(e.message)
        });

    }

    return (
        <form className={Styles.login} onSubmit={log}>
            <div className={Styles.login_profile}>
                <Icon path={mdiAccountOutline} />
            </div>

            {error && <div className={Styles.login__error}>
                {error}
            </div>}
            <input
                type="email"
                name='email'
                placeholder="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                name='password'
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />

            <button type='submit'>{LANG.auth_login}</button>
            <a href="/auth/v1/cas/login">{LANG.auth_login_upc}</a>
        </form>

    )
}
