import React from "react";

export const LANG = {
    global_create: "Créer",
    global_edit: "Modifer",
    global_duplicate: "Dupliquer",
    global_color: "Couleur",
    global_date: "Date",
    global_confirm: "Confirmer",
    global_id: "ID",
    global_actions: "Actions",
    global_refresh: "Actualiser",
    global_cancel: "Annuler",
    global_close: "Fermer",
    global_loading: "Chargement…",
    global_all: "Tous",
    global_search: "Rechercher…",
    global_add: "Ajouter",
    global_archive: "Archiver",
    global_delete: "Suprimer",
    global_error: "Erreur",

    profile_switch_ac_year: "Changer d'année universitaire",
    profile_logout: "Déconnexion",

    unauthorized_page: "Vous n'êtes pas autorisé à voir cet élément.",

    auth_register: "S'inscrire",
    auth_login: "Connexion",
    auth_login_upc: "Se connecter avec UPC",
    auth_login_local: "Se connecter localement",
    auth_not_me: "Ce n'est pas moi",
    auth_continue: "Continuer",
    auth_error_missing_field: "Merci de remplir tous les champs",
    auth_no_access: [
        "Vous n'avez accès à aucune composante !",
        "Veuillez contacter un administrateur."
    ],
    auth_select_label_option: "Sélectionner une année universitaire",
    auth_cas_success: "Votre compte a bien été créé merci ! Penser a demandé de vous ajouter les accès pour votre compte puis connectez-vous !",
    auth_cas_register_title: "Finaliser la création du compte",

    events: "Événement",
    event_create: 'Créer un événement',
    event_edit: "Modifier un événement",
    event_duplicate: "Dupliquer un événement",
    event_type_daily: "Journalier",
    event_type_weekly: "Periodique",
    event_modal_field_name: "Nom de l'événement",
    event_modal_field_session: "Nom de la scéance",
    event_modal_field_room: "Nom de la salle",
    event_modal_field_start_date: "Date de début",
    event_modal_field_end_date: "Date de fin",
    event_modal_field_date_error: "La date de fin doit être supérieur à la date de fin.",
    event_modal_field_start_hour: "Heur de début",
    event_modal_field_end_hour: "Heur de fin",
    event_modal_field_hour_error: "L'heure de fin doit être supérieure à l'heure de début.",
    event_modal_justify_title: "Justifier une absence ?",
    event_modal_justify_field_reason_label: "Raison",
    event_modal_justify_field_reason_placeholder: "Raison de l'absence",
    event_calendar_empty: "Aucun événement",
    event_not_selected: "Aucun événement sélectionné",
    event_scan_tooltip: "Scanner des cartes",
    event_scan_desktop_tooltip: "Scanner sur l'application de bureau",
    event_scan_result_waiting: "En attente d'une carte…",
    event_scan_result_error: (e?: string) => `Erreur : ${e}`,
    event_scan_result_success: (f?: string, l?: string) => `Ok: ${f} ${l?.toUpperCase()}`,
    event_scan_result_error_unknown: "Erreur inconnue",
    event_scan_result_unknown_card: "Carte inconnue",
    event_remove_group_confirmation: "Retirer le groupe de l'événement ?",
    event_add_group: "Ajouter un groupe",
    event_calender_week_of: (f?: string, t?: string) => `${f} au ${t}.`,
    event_calendar_previous_week: "Semaine précédente",
    event_calendar_next_week: "Semaine suivante",
    event_calendar_separator_weekly: "Événement périodique",
    event_students_tooltip_csv_download: "Télécharger en CSV",
    event_students_colum_firstname: "Prénom",
    event_students_colum_lastname: "Nom",
    event_students_colum_scan: "Scanner le",
    event_students_actions_view: "Voir le profil",
    event_students_actions_delete_presense: "Retirer la presence (Neutre)",
    event_students_actions_delete_presense_title: "Retirer la presence (Neutre)",
    event_students_actions_delete_presense_body: (f?: string, l?: string) => `Voulez-vous retirer la presence de ${f} ${l?.toUpperCase()} (Revenir á l'état Neutre)`,
    event_students_actions_set_absent: "Marquer absent",
    event_students_actions_set_present: "Marquer présent",
    event_students_actions_justify: "Justifier l'absence",
    event_validate_button: "Valider les prèsence",
    event_unvalidate_button: "Dèbloquer l'évènement",
    event_validate_modal_title: "Valider l'évènement",
    event_validate_modal_body: "Voulez-vous valider l'évènement ? vous ne pourez plus modifier les présences.",

    students: "Étudiants",
    student_present: "Présent",
    student_absent: "Absent",
    student_neutal: "----",
    student_justify_absent: "Absence justifiée",
    student_justify_reason: (r?: string) => `Justification: ${r}`,
    student_total: "Total",
    student_delete: "Supprimer l'étudiant(e) ?",
    student_delete_confirm: (f?: string, l?: string, id?: number | string) => <>
        Voulez-vous vraiment supprimer l'étudiant(e)
        &nbsp;<span className={'intext-upc'}>{f}</span>
        &nbsp;<span className={'intext-upc'}>{l}</span> du groupe
        &nbsp;<span className={'intext-upc'}>{id}</span> ?
    </>,
    student_view: "Voir l'étudiant(e)",
    student_not_selected: "Aucun étudiant sélectionné",
    students_select_modal_title: "Sélectionner des étudiants",
    students_select_modal_btn_add: "Ajouter les étudiants",
    students_select_modal_search: "Rechercher un étudiant",
    student_card_modal_title: "Modifier le numéro de carte étudiante",
    student_card_modal_field_card: "Numéro de carte",
    student_card_modal_field_little_endian: "Little Endian ID",
    student_card_modal_field_little_endian_description: "Format de l'ID si vous utilisez un lecteur RFID laisser activer, si vous rentré le numéro à la main qui est sur la carte désactiver l'option.",

    groups: "Groupes",
    group_search: "Rechercher un groupe… (ex: \"ue1 ed7\")",
    group_create: "Créer un groupe",
    group_delete: "Supprimer un groupe",
    group_create_modal_field_name: "Nom du groupe",
    group_edit: "Modifier un groupe",
    group_select: "Sélectionner un groupe",
    group_not_selected: "Aucun groupe sélectionné",
    group_students_count: "Nombre d'étudiants",
    group_add_students: "Ajouter des étudiants",
    group_remove_student: "Retirer l'etudiants du groupe",
    group_remove_student_confirm: "Voulez-vous vraiment retirer l'étudiant du groupe ?",
    group_students_scan_count: "Présence",

    users: "Utilisateurs",
    users_add: "Ajouter un utilisateur",
    users_modal_field_email: "Email de l'utilisateur",
    users_modal_field_email_placeholder: "prénom.nom@u-paris.fr",
    users_permission_modal_title: (u?: string) => `Modifier les accés de @${u}`,
    users_permission_remove_confirmation_title: (u?: string) => `Retirer les accès de @${u}`,
    users_permission_remove_confirmation: (u?: string) => `Voulez-vous vraiment retirer tous les accès à @${u} ?`,

    components: "Composantes",
    component_edit: "Modifier la composante",
    component_create: "Créer une composante",
    component_modal_field_name: "Nom de la composante",

    ac_years: "Années universitaires",
    ac_year_edit: "Modifier l'année universitaire",
    ac_year_create: "Créer une année universitaire",
    ac_year_modal_field_name: "Nom de l'année universitaire",
    ac_year_modal_field_year_start: "Année de début",
    ac_year_modal_field_year_end: "Année de fin",
    ac_year_modal_error_year: "L'année de fin doit être supérieur à l'année de début",
    ac_year_archive_modal_title: "Archiver l'année universitaire",
    ac_year_archive_modal_warnings: [
        "Êtes-vous sûr de vouloir archiver cette année scolaire ?",
        "Vous ne pourrez plus la modifier.",
        "Cette action est irréversible"
    ],
    ac_year_archive_modal_confirm: "Pour confirmer merci de rentrer le nom de l'année universitaire :",
    ac_year_archived: "archivée",

    admin_upload: "Ajouter des données",
    admin_csv_reset: "Réinitialiser",

    admin_csv_select_ac_year: "Choisir une année universitaire…",
    admin_csv_select_file: "Choisir un fichier CSV…",
    admin_csv_link: "Lier",
    admin_csv_unlink: "Délier",
    admin_csv_unlink_confirm: "Voulez-vous vraiment délier le groupe ?",
    admin_csv_deactivate: "Déscativer",
    admin_csv_activate: "Activer",
    admin_csv_activate_all: "Tout Activer",
    admin_csv_link_modal_title: "Lier un groupe",
    admin_csv_link_modal_no_group: "Aucun groupe disponible",
    admin_csv_column: "Colonne",
    admin_csv_tag: "Tag",
    admin_csv_missing_tags: (t?: string) => `Tags manquant : ${t}`,
    admin_csv_send: "Envoyer les donées",
    admin_csv_send_in_progress: "Envoi des données…",
    admin_csv_send_success: "Toutes les données ont été importées avec succès !",
    admin_csv_send_group: (c: number, t: number) => `Envoi des groupes ${c}/${t}`,
    admin_csv_send_students: (k: string, c: number, t: number) => `Envoi des étudiants dans le groupe ${k} (${c}/${t})`,
    admin_csv_add_global_group: "Ajouter un groupe global",
    admin_csv_remove_filter: "Retirer les filtres",
    admin_csv_warning_cancel: "Voulez-vous vraiment annuler l'import des données ? Rien ne sera sauvegardé !",
    admin_csv_error_io_file: "Erreur lors de la lecture du fichier",
    admin_csv_error_empty_file: "Le fichier est vide",
    admin_csv_error_send_students_acyear: `Erreur lors de l'envoies des donnés étudiantes`,
    admin_csv_error_send_group: (c: number, t: number) => `Erreur lors de l'envoi des groupes ${c}/${t}`,
    admin_csv_error_send_students: (k: string, c: number, t: number) => `Erreur lors de l'envoi des étudiants dans le groupe ${k} (${c}/${t})`,
    error_unknown: "Une erreur est survenue !",
    tag_validate: "Validé",
    tag_archived: "Archivé"

}
