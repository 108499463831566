import Styles from "../styles/component/Header.module.scss";
import default_user from "../../../res/default_user.png";
import React, {MouseEvent} from "react";
import {mdiChevronDown} from "@mdi/js";
import Icon from "@mdi/react";
import {useModal} from "../../../core/modal/ModalBase";
import {ProfilePopup} from "../modal/ProfilePopup";
import {convertRemToPixels} from "../../../util/utilsHelper";

export function UserBrandProfile(): JSX.Element {

    const modal = useModal()
    return (
        <button className={Styles.header__user} onClick={(e: MouseEvent<HTMLButtonElement>) => {
            modal.openModal(<ProfilePopup />, {
                position: 'relative',
                relativeDirection: "bottom-left",
                relativeTo: e.currentTarget,
                offset: [convertRemToPixels(3), convertRemToPixels(.25)]
            })
        }}>
            <img src={default_user} alt="user profile" className={Styles.user__logo}/>
            <Icon path={mdiChevronDown} className={Styles.user__arrow}/>
        </button>
    )
}