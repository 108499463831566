import { useEffect, useState } from "react";
import { EventCalendarSettings } from "./EventCalendarBody";
import Styles from "../styles/EventComponents.module.scss";
import { mdiArchive, mdiCheck, mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { LANG } from "../../../lang";
import { addDayNumber, addWeekNumber, getEndDayTime, getEndWeekTime, getStartDayTime, getStartWeekTime } from "../../../util/dateHelper";
import Icon from "@mdi/react";
import { TOOL_TIP_ID } from "../../../util/const";
import { CheckBoxIcon, CompactSelect, InputGroup } from "../../common/component/InputUtils";

export interface EventCalendarHeadProps {
    onSettingsChange: (settings: EventCalendarSettings) => void;
    defaultHideArchived?: boolean,
    defaultHideValidate?: boolean
}

export function EventCalendarHead(props: EventCalendarHeadProps): JSX.Element {

    const [settings, setSettings] = useState<EventCalendarSettings>({
        hideValidated: props.defaultHideValidate,
        hideArchived: props.defaultHideArchived,
        startTime: getStartDayTime(),
        endTime: getEndDayTime(),
    })
    const [isWeekly, setIsWeekly] = useState<boolean>(false);

    const onDayChange = (direction: number) => {
        let start = settings.startTime;
        let end = settings.endTime;
        if (isWeekly) {
            start = addWeekNumber(start, direction);
            end = getEndWeekTime(start)
        } else {
            start = addDayNumber(start, direction)
            end = getEndDayTime(start)
        }
        setSettings({ ...settings, startTime: start, endTime: end })
    }

    const onTypeChange = (value: string) => {
        if (value === '1') {
            if (isWeekly) return;
            setIsWeekly(true)
            setSettings({
                ...settings,
                startTime: getStartWeekTime(settings.startTime),
                endTime: getEndWeekTime(settings.startTime)
            })
        } else {
            if (!isWeekly) return;
            setIsWeekly(false);
            setSettings({
                ...settings,
                startTime: getStartDayTime(settings.startTime),
                endTime: getEndDayTime(settings.startTime)
            })
        }
    }

    useEffect(() => props.onSettingsChange(settings), [props, props.onSettingsChange, settings])

    const daysLabel = isWeekly ? LANG.event_calender_week_of(
        new Date(settings.startTime * 1000).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
        }),
        new Date(settings.endTime * 1000).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
        })
    ) : new Date(settings.startTime * 1000).toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
    });

    return (
        <div>
            <div className={Styles.calendar_settings}>
                <InputGroup>
                    <CompactSelect onChange={onTypeChange} options={{ '0': "Jour", '1': "Semaine" }} />
                    <CheckBoxIcon icon={mdiCheck} defaultValue={!props.defaultHideValidate}
                        onChange={(v) => setSettings({ ...settings, hideValidated: !v })} />
                    <CheckBoxIcon icon={mdiArchive} defaultValue={!props.defaultHideArchived}
                        onChange={(v) => setSettings({ ...settings, hideArchived: !v })} />
                </InputGroup>
            </div>
            <div className={Styles.calender__week}>
                <div data-tooltip-id={TOOL_TIP_ID}
                    data-tooltip-content={LANG.event_calendar_previous_week}
                    data-tooltip-place='right'
                    role='tooltip'>
                    <button className={Styles.week__before}
                        onClick={() => onDayChange(-1)}>
                        <Icon path={mdiChevronLeft} />
                    </button>
                </div>
                <span
                    className={Styles.week__name}>{daysLabel}</span>
                <div data-tooltip-id={TOOL_TIP_ID}
                    data-tooltip-content={LANG.event_calendar_next_week}
                    data-tooltip-place='left'
                    role='tooltip'>
                    <button className={Styles.week__after}
                        onClick={() => onDayChange(1)}>
                        <Icon path={mdiChevronRight} />
                    </button>
                </div>

            </div>
        </div>
    )
}
