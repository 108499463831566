import {UploaderStudentDisplayProps} from "../../declaration";
import Styles from '../../styles/uploader/UploaderStudentDisplay.module.scss';
import {LANG} from "../../../../lang";

export function UploaderStudentDisplay({students, groupFilter}: UploaderStudentDisplayProps): JSX.Element {
    return (
        <table className={Styles.table}>
            <thead className={Styles.head}>
            <tr>
                <th>{LANG.global_id}</th>
                <th>{LANG.event_students_colum_firstname}</th>
                <th>{LANG.event_students_colum_lastname}</th>
                <th>{LANG.student_card_modal_field_card}</th>
            </tr>
            </thead>
            <tbody className={Styles.body}>
            {
                (groupFilter === undefined ? students : students.filter(s => s.groups.includes(groupFilter))).map(s => (
                        <tr key={s.id}>
                            <td>{s.id}</td>
                            <td>{s.firstname}</td>
                            <td>{s.lastname}</td>
                            <td>{s.card_id}</td>
                        </tr>
                    )
                )
            }
            </tbody>
        </table>
    )
}