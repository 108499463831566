import {NoContent} from "../../common";
import {mdiAccountSchool} from "@mdi/js";
import CreateEditGroupModal from "../../group/modal/CreateEditGroupModal";
import React from "react";
import {LANG} from "../../../lang";

const NoStudent = (
    <NoContent iconPath={mdiAccountSchool}
               text={LANG.student_not_selected}
               modal={<CreateEditGroupModal />}
    />
)

export {
    NoStudent
}