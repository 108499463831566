import Styles from "../styles/EventComponents.module.scss"
import { NavLink } from "react-router-dom";
import React from "react";
import { DEFAULT_EVENT_COLOR } from "../../../util/const";
import { classNameProp, Group, UEvent } from "../../../declarations";
import { extendClassName } from "../../../util/utilsHelper";
import { Tag } from "../../common/component/Tag";
import { mdiArchive, mdiCheck } from "@mdi/js";
import { LANG } from "../../../lang";


// Event component
// display an event with its groups and time
// props:
// - event: the event to display
// - groups: the groups of the event
// - dateOptions?: the options to display the date, default : {hour: "2-digit", minute: "2-digit"}
function EventComponent(props: {
    event: UEvent,
    groups: Group[],
    dateOptions?: Intl.DateTimeFormatOptions
} & classNameProp): JSX.Element {

    const fDateOptions: Intl.DateTimeFormatOptions = props.dateOptions || props.event.end_time - props.event.start_time > 24 * 60 * 60 ? {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    } : {
        hour: "2-digit",
        minute: "2-digit"
    }

    // format start and end time
    let start = new Date(props.event.start_time * 1000).toLocaleTimeString("fr-FR", fDateOptions);
    let end = new Date(props.event.end_time * 1000).toLocaleTimeString("fr-FR", fDateOptions);

    return (
        <div className={extendClassName(Styles.event, props.className)}>
            <NavLink className={Styles.event__link} to={"/events/" + props.event.id} end>
                <div className={Styles.event__head}
                    style={{ backgroundColor: props.event.color || DEFAULT_EVENT_COLOR }}></div>
                <div className={Styles.event__body}>
                    <span className={Styles.child_title}>{props.event.name}</span>
                    <span className={Styles.child_room}>{props.event.room}</span>
                    <div className={Styles.child_time}>
                        <span>{start}</span>
                        <span> - </span>
                        <span>{end}</span>
                    </div>
                    <div className={Styles.child_tags}>
                        {((props.event.flags || 0) & 0b1) > 0 && <Tag name={LANG.tag_validate} color="#97c188" mdiIcon={mdiCheck} />}
                        {((props.event.flags || 0) & 0b10) > 0 && <Tag name={LANG.tag_archived} color="#c1752c" mdiIcon={mdiArchive} />}
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

// EventCalendar component

export const Event = React.memo(EventComponent);
