import {ModalBase, ModalFieldSelect} from "../../../../core/modal/ModalBase";
import {LinkGroupPros} from "../../declaration";
import {mdiAccountGroupOutline} from "@mdi/js";
import {useState} from "react";
import ModalStyles from "../../../../core/modal/Modal.module.scss";
import {LANG} from "../../../../lang";

export function LinkGroup(props : LinkGroupPros): JSX.Element {
    const defIndex = props.groups.findIndex(v => v.name.toLowerCase() === props.targetGroup.name.toLowerCase());
    const [value, setValue] = useState<string>(props.groups.length === 0 ? '' : props.groups[defIndex < 0 ? 0 : defIndex].id.toString());

    const link = (button: string) => {
        if (button !== 'link') return false;
        if (value === '') {
            props.onLink(undefined);
        }
        props.onLink(parseInt(value));
        return true;
    }


    const body = props.groups.length === 0 ? <div className={ModalStyles.error}>{LANG.admin_csv_link_modal_no_group}</div> : (
        <ModalFieldSelect id={'link'} label={'Group'} svg={mdiAccountGroupOutline} values={
            props.groups.map(value => {
                    return {id: value.id.toString(), label: value.name}
                }
            )
        } onChange={(id) => setValue(id)} defaultValue={value} />
    )

    return (
        <ModalBase title={LANG.admin_csv_link_modal_title}
                   buttons={[
                       {id: "link", label: LANG.admin_csv_link, active: props.groups.length > 0}
                   ]}
                   onButtonClick={link}
        >
            <div>
                {body}
            </div>
        </ModalBase>

    )

}