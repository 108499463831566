export enum CsvHeaderTag {
    UNDEFINED,
    ID,
    FIRST_NAME,
    LAST_NAME,
    GROUP,
    CARD_ID,
}

export const ALL_CSV_HEADER_TAGS = [
    CsvHeaderTag.UNDEFINED,
    CsvHeaderTag.ID,
    CsvHeaderTag.FIRST_NAME,
    CsvHeaderTag.LAST_NAME,
    CsvHeaderTag.GROUP,
    CsvHeaderTag.CARD_ID,
];

export const ALL_REQUIRED_HEADER_TAGS = [
    CsvHeaderTag.ID,
    CsvHeaderTag.FIRST_NAME,
    CsvHeaderTag.LAST_NAME,
    CsvHeaderTag.CARD_ID,
];

export const TAG_STYLES = {
    [CsvHeaderTag.UNDEFINED]: {
        name: 'Aucun',
        color: '#D4B9F8'
    },
    [CsvHeaderTag.ID]: {
        name: 'ID',
        color: '#41D383'
    },
    [CsvHeaderTag.FIRST_NAME]: {
        name: 'Prénom',
        color: '#BE8ABF'
    },
    [CsvHeaderTag.LAST_NAME]: {
        name: 'Nom',
        color: '#7286D3'
    },
    [CsvHeaderTag.GROUP]: {
        name: 'Groupe',
        color: '#FF7575'
    },
    [CsvHeaderTag.CARD_ID]: {
        name: 'Carte ID',
        color: '#FFB575'
    }
}