import {useModal} from "../../../core/modal/ModalBase";
import Styles from "../styles/component/NoContent.module.scss"
import Icon from "@mdi/react";
import React from "react";
import {IfAllow} from "./IfAllow";

function NoContent(props: {
    iconPath: string,
    text: string,
    btnText?: string,
    permission?: number,
    modal?: JSX.Element
}): JSX.Element {
    const modal = useModal();

    let gb = <></>

    if (props.modal) {
        const btn = <button className={Styles.new_btn} onClick={() => modal.openModal(props.modal||<></>)}>{props.btnText}</button>;

        gb =
            props.permission ? (
                <IfAllow permission={props.permission}>
                    {btn}
                </IfAllow>
            ) : btn;
    }

    return (
        <div className={Styles.no_content}>
            <div className={Styles.container}>
                <Icon path={props.iconPath} className={Styles.svg}/>
                <span className={Styles.text}>{props.text}</span>
                {props.btnText && gb}
            </div>
        </div>
    )
}

export {
    NoContent
}