import {mdiPlus, mdiReload} from "@mdi/js";
import {GlobalSpinner, IconButton} from "../../common";
import {Suspense} from "react";
import {useUsersPermissions} from "../hook/useUsersPermissions";
import {Await, useParams} from "react-router-dom";
import {UserPermissions} from "../../../declarations";
import {UserPermissionsLine} from "../component/UserPermissionsLine";
import {useModal} from "../../../core/modal/ModalBase";
import AddUser from "../modal/AddUser";
import {ButtonStyle} from "../../common/component/IconButton";
import {LANG} from "../../../lang";
import Styles from '../styles/Admin.module.scss'

export function EditUsers() {
    const {componentID} = useParams() as { componentID: string }
    const [permissions, refresh] = useUsersPermissions(parseInt(componentID))
    const modal = useModal();

    return (
        <>
            <div className={Styles.component_users_btn}>
                <IconButton
                    onClick={refresh}
                    path={mdiReload}
                    d-style={ButtonStyle.ROUND_UPC}
                    title={LANG.global_refresh}
                    large
                    title-inline
                />
                <IconButton
                    onClick={() => modal.openModal(<AddUser componentID={parseInt(componentID)} />)}
                    path={mdiPlus}
                    d-style={ButtonStyle.ROUND_UPC}
                    title={LANG.global_add}
                    large
                    title-inline
                />
            </div>
            <Suspense fallback={<GlobalSpinner/>}>
                <Await resolve={permissions}>{(permissions: UserPermissions[]) => {
                        return (
                            <div className={Styles.component_users_data}>
                                {permissions.map(permission => <UserPermissionsLine user={permission} key={permission.id}/>)}
                            </div>
                        )
                    }
                }
                </Await>
            </Suspense>
        </>
    )
}