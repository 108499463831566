import { CsvColumn, UploaderCSVDataDisplayProps } from "../../declaration";
import { useModal } from "../../../../core/modal/ModalBase";
import { ALL_REQUIRED_HEADER_TAGS, CsvHeaderTag, TAG_STYLES } from "../../enum";
import { IconButton, NoContent, Panel } from "../../../common";
import {
    mdiAccountAlertOutline,
    mdiAccountGroupOutline,
    mdiAccountSchool,
    mdiEyeOutline,
    mdiFileCancel,
    mdiFilterRemove,
    mdiPlus,
    mdiTableColumnPlusAfter,
    mdiUpload
} from "@mdi/js";
import Styles from "../../styles/uploader/UploaderCSV.module.scss";
import { UPColumns } from "./UPColumns";
import CreateEditGroupModal from "../../../group/modal/CreateEditGroupModal";
import { Group } from "../../../../declarations";
import { UPGroup } from "./UPGroup";
import { useCallback, useState } from "react";
import { UploaderStudentDisplay } from "./UploaderStudentDisplay";
import { ConfirmationModal } from "../../../../core/modal/ConfirmationModal";
import { LANG } from "../../../../lang";


export function UploaderCSVDataDisplay({
    existGroups, students, groups,
    headers, allStudentTagRequired,
    updateHeader, updateGroup,
    enableAllGroup,
    askForUpload, reset
}: UploaderCSVDataDisplayProps): JSX.Element {

    const modal = useModal();
    const [groupFilter, setGroupFilter] = useState<string | undefined>(undefined)


    const onTagAdd = (index: number, tag: CsvHeaderTag) => {
        // should not happen but just in case and for typescript to be happy
        if (headers === undefined) return;

        // check if the tag is already used by another column (except UNDEFINED and GROUP)
        const shouldBeAlone = tag !== CsvHeaderTag.UNDEFINED && tag !== CsvHeaderTag.GROUP;
        // add tag to the column // remove tag from other column if needed
        updateHeader(headers.map((e: CsvColumn) => e.index === index ? {
            ...e,
            tag: tag
        } : shouldBeAlone && e.tag === tag ? { ...e, tag: CsvHeaderTag.UNDEFINED } : e))
        // remove filter
        setGroupFilter(undefined)
    }

    const resetConfirm = useCallback(() => {
        modal.openModal(<ConfirmationModal title={LANG.global_cancel} message={LANG.admin_csv_warning_cancel} onConfirm={reset} />)
    }, [reset, modal])

    const missingTags = ALL_REQUIRED_HEADER_TAGS.filter((t: CsvHeaderTag) => headers.findIndex(e => e.tag === t) === -1)

    const noContent = <NoContent iconPath={mdiAccountAlertOutline}
        text={LANG.admin_csv_missing_tags(missingTags.map((t: CsvHeaderTag) => TAG_STYLES[t].name).join(', '))} />

    return (
        <div className={Styles.settings_container}>

            <Panel title={LANG.admin_csv_column} icon={mdiTableColumnPlusAfter} className={Styles.columns} bodyScrollable borderTop>
                <UPColumns col={headers} onTagAdd={onTagAdd} />
            </Panel>



            <Panel title={LANG.groups} icon={mdiAccountGroupOutline} className={Styles.groups}
                button={(
                    <>
                        <IconButton path={mdiEyeOutline} title={LANG.admin_csv_activate_all} aria-label='add-all' onClick={() => enableAllGroup()} />
                        <IconButton path={mdiPlus} title={LANG.admin_csv_add_global_group} onClick={() => {
                            modal.openModal(<CreateEditGroupModal onConfirm={group => {
                                updateGroup('global', {
                                    name: group.name,
                                    color: group.color,
                                    ac_year_id: -1,
                                    component_id: -1,
                                    id: -1,
                                    students_count: students?.length || 0
                                })
                            }} />)
                        }} large />
                    </>
                )}
                borderTop bodyScrollable>
                {groups === undefined ? noContent : (
                    // sort groups by students count
                    Object.entries(groups)
                        .sort(([_, v1]: [string, Group], [__, v2]: [string, Group]) => (v2.students_count || 0) - (v1.students_count || 0))
                        .map(([key, value]: [string, Group]) => (
                            <UPGroup group={value}
                                existGroupForLink={existGroups}
                                onChange={group => updateGroup(key, group)}
                                key={key}
                                isFilter={groupFilter === key}
                                onFilterSelect={() => setGroupFilter(key === groupFilter ? undefined : key)}
                            />
                        ))
                )}

            </Panel>

            <Panel title={LANG.students} icon={mdiAccountSchool}
                className={Styles.students}
                button={(<div className={Styles.students_btn}>
                    <IconButton path={mdiFilterRemove} title={LANG.admin_csv_remove_filter} onClick={() => setGroupFilter(undefined)} large />
                    <IconButton path={mdiUpload} onClick={askForUpload} title={LANG.admin_csv_send} disabled={students?.length === undefined || students?.length === 0} large />
                    <IconButton path={mdiFileCancel} title={LANG.global_cancel} onClick={resetConfirm} large />
                </div>)}
                bodyScrollable borderTop>
                {(!allStudentTagRequired || students === undefined) && noContent}
                {allStudentTagRequired && students !== undefined && (
                    <UploaderStudentDisplay students={students} groupFilter={groupFilter} />
                )}
            </Panel>

            {/*for red shadow*/}
            <div className={Styles.view_left}></div>
        </div>
    )
}
