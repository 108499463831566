import {useCallback, useEffect, useState} from "react";
import CacheManager from "../../../service/cacheManager";
import {UComponent} from "../../../declarations";
import {ChangeEvent, useEventListener} from "../../common";

export function useComponents(permission?: number): [
    components: Promise<UComponent[]>,
    refresh: () => void
] {

    if (!CacheManager.hasToken()) {
        throw new Error("Token is not set")
    }

    const [components, setComponents] = useState<Promise<UComponent[]>>(Promise.resolve([]))

    const refresh = useCallback(() => {
        setComponents(CacheManager.getComponents().then((components) => {
            if (permission !== undefined) {
                return components.filter((component) => ((component?.permission || 0) & permission) > 0)
            }
            return components
        }))
    }, [permission])

    useEffect(refresh, [refresh])

    const eventListener = useCallback((event: ChangeEvent<UComponent>) => {
        if (event.action === "change") {
            components.then((components) => {
                const index = components.findIndex((component) => component.id === event.data.id)
                if (index !== -1) {
                    components[index] = event.data
                    setComponents(Promise.resolve(components))
                }
            });
        } else if (event.action === "create") {
            if (permission !== undefined && !CacheManager.getSelfUnsafe()?.is_super_admin) {
                if (((event.data.permission || 0) & permission) === 0) {
                    return
                }
            }
            components.then((components) => {
                components.push(event.data)
                setComponents(Promise.resolve(components))
            })
        } else if (event.action === "delete") {
            components.then((components) => {
                const index = components.findIndex((component) => component.id === event.data.id)
                if (index !== -1) {
                    components.splice(index, 1)
                    setComponents(Promise.resolve(components))
                }
            })
        }
    }, [components, permission])

    useEventListener('componentChange', eventListener)

    return [
        components,
        refresh
    ]

}