import {ACYear} from "../../../declarations";
import {useCallback, useEffect, useState} from "react";
import {ChangeEvent, useEventListener} from "../../common";
import CacheManager from "../../../service/cacheManager";

export function useAcYears(componentID: number, redirectIfNotAdmin: boolean = false): [
    Promise<ACYear[]>,
    () => void,
] {

    const [acYears, setAcYears] = useState<Promise<ACYear[]>>(Promise.resolve([]))

    const refresh = useCallback(() => {
        setAcYears(CacheManager.getAcYears(componentID))
    }, [componentID])

    const eventListener = useCallback((e: ChangeEvent<ACYear>) => {
        if (e.data.component_id !== componentID) return;
        if (e.action === 'create') {
            acYears.then(value => setAcYears(Promise.resolve([...value, e.data])))
        } else if (e.action === 'change') {
            acYears.then(value => setAcYears(Promise.resolve(value.map(ac => ac.id === e.data.id ? e.data : ac))))
        } else if (e.action === 'delete') {
            acYears.then(value => setAcYears(Promise.resolve(value.filter(ac => ac.id !== e.data.id))))
        }
    }, [acYears, componentID])

    useEventListener('acYearChange', eventListener);

    useEffect(refresh, [refresh])

    return [
        acYears,
        refresh
    ]
}