export type permission = number;

export const Permission = {
    ACCESS: 1 << 0,
    CREATE_EVENT: 1 << 1,
    VIEW_GROUP: 1 << 2,
    VIEW_GROUP_STUDENTS: 1 << 3,
    CREATE_GROUP: 1 << 4,
    MANAGE_GROUP: 1 << 5,
    VIEW_STUDENTS: 1 << 6,
    VIEW_EVENTS: 1 << 7,
    VIEW_ARCHIVES: 1 << 8,
    CREATE_SCANS: 1 << 9,
    VIEW_SCANS: 1 << 10,
    DELETE_SCANS: 1 << 11,
    EDIT_STUDENTS: 1 << 12,
    MODERATOR: 1 << 15,
    ADMIN: 1 << 16,
}



export const PermissionName: { [key: permission]: string } = {
    [Permission.ACCESS]: "Access",
    [Permission.CREATE_EVENT]: "Create Event",
    [Permission.VIEW_GROUP]: "View Group",
    [Permission.VIEW_GROUP_STUDENTS]: "View Group Students",
    [Permission.CREATE_GROUP]: "Create Group",
    [Permission.MANAGE_GROUP]: "Manage Group",
    [Permission.VIEW_STUDENTS]: "View Students",
    [Permission.VIEW_EVENTS]: "View Events",
    [Permission.VIEW_ARCHIVES]: "View archives",
    [Permission.CREATE_SCANS]: "Create Scans",
    [Permission.VIEW_SCANS]: "View Scans",
    [Permission.DELETE_SCANS]: "Delete Scans",
    [Permission.EDIT_STUDENTS]: "Edit Students",
    [Permission.MODERATOR]: "Scolarité (moderator)",
    [Permission.ADMIN]: "Admin",
}

// export const EventPermission = {
//     VIEW: 1 << 0,
//     EDIT: 1 << 1,
//     DELETE: 1 << 2,
//     SCAN_ADD: 1 << 3,
//     SCAN_REMOVE: 1 << 4,
// }
