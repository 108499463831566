import { mdiArchive, mdiArchiveOutline, mdiContentDuplicate, mdiCubeScan, mdiDataMatrixScan, mdiPencil, mdiPencilOutline } from "@mdi/js"
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal"
import { useModal } from "../../../core/modal/ModalBase"
import { UEvent } from "../../../declarations"
import { LANG } from "../../../lang"
import CacheManagerInstance from "../../../service/cacheManager"
import { Permission } from "../../../util/pernission"
import { IconButton } from "../../common"
import { ButtonStyle } from "../../common/component/IconButton"
import { IfAllow } from "../../common/component/IfAllow"
import { Tag } from "../../common/component/Tag"
import { DuplicateEventModal, EditEventModal } from "../modal/CreateEditEventModal"
import { Buffer } from 'buffer';

export interface EventDetailsActionsProps {
    event: UEvent
}

export function EventDetailsActions(props: EventDetailsActionsProps): JSX.Element {
    const modal = useModal()
    // if acrhived
    if (((props.event.flags || 0) & 0b10) > 0) {
        return <Tag name={LANG.tag_archived} color="#c1752c" mdiIcon={mdiArchive} />
    }
    return (
        <>
            <IfAllow permission={Permission.CREATE_EVENT} >
                <>
                    <IconButton
                        path={mdiPencilOutline}
                        pathHover={mdiPencil}
                        title={LANG.event_edit}
                        onClick={() => {
                            modal.openModal(<EditEventModal baseEvent={props.event} />)
                        }}
                        title-place={'top'}
                        d-style={ButtonStyle.CLEAR}
                        large
                    />
                    <IconButton
                        path={mdiContentDuplicate}
                        pathHover={mdiContentDuplicate}
                        title={LANG.event_duplicate}
                        onClick={() => {
                            modal.openModal(<DuplicateEventModal baseEvent={props.event} />)
                        }}
                        title-place={'top'}
                        d-style={ButtonStyle.CLEAR}
                        large
                    />
                </>

            </IfAllow>
            <IfAllow permission={Permission.CREATE_SCANS} overwrite={((props.event.flags || 0) & 0b11) > 0 ? false : undefined}>
                <>
                    <IconButton
                        path={mdiDataMatrixScan}
                        pathHover={mdiDataMatrixScan}
                        title={LANG.event_scan_tooltip}
                        link={"scan"}
                        title-place={'top'}
                        d-style={ButtonStyle.CLEAR}
                        large
                    />
                    <IconButton
                        path={mdiCubeScan}
                        pathHover={mdiCubeScan}
                        title={LANG.event_scan_desktop_tooltip}
                        onClick={() => {
                            const auth_token = Buffer.from(CacheManagerInstance.getToken()).toString("base64")
                            const url = document.location.protocol + '//' + document.location.host + "/api/v1";
                            const link = document.createElement('a');
                            link.setAttribute("href", `emargement://${url}?tok=${auth_token}&eid=${props.event.id}`)
                            document.body.appendChild(link)
                            link.click()
                            link.remove()
                            console.log(link.href)
                            // modal.openModal(<ModalBase title="" > <a href={`emargement://${url}?tok=${auth_token}&eid=${props.event.id}`}> open </a>  </ModalBase>)

                        }}
                        title-place={"top"}
                        d-style={ButtonStyle.CLEAR}
                        large
                    />
                </>
            </IfAllow>
            <IfAllow permission={Permission.MODERATOR} overwrite={props.event.id === CacheManagerInstance.getSelfUnsafe()?.id ? true : undefined}>
                <IconButton
                    path={mdiArchiveOutline}
                    pathHover={mdiArchive}
                    title={LANG.global_archive}
                    onClick={() => {
                        modal.openModal(
                            <ConfirmationModal
                                title="todo"
                                message="todo"
                                onConfirm={() => {
                                    CacheManagerInstance.archiveEvent(props.event)
                                }}
                            />
                        )
                    }}
                    title-place={"top"}
                    d-style={ButtonStyle.CLEAR}
                    large
                />
            </IfAllow>
        </>
    )
}
