import Icon from "@mdi/react";
import { ChangeEvent, ReactNode, useState } from "react";
import { extendClassName } from "../../../util/utilsHelper";
import Styles from "../styles/InputUtils.module.scss"

export interface InputGroupPros {
    children: ReactNode
    className?: string
}

export interface CheckBoxIconProps {
    icon: string
    onChange?: (v: boolean) => void
    className?: string
    defaultValue?: boolean
    disable?: boolean
    name?: string
}

export interface CompactSelectProps {
    options: { [key: string]: string }
    onChange?: (v: string) => void
    name?: string
    className?: string
    value?: string
}

export function InputGroup(props: InputGroupPros) {
    return <div className={Styles.group}> {props.children} </div>
}


export function CompactSelect(props: CompactSelectProps) {
    const [value, setValue] = useState<string>()

    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    return (
        <label className={extendClassName(Styles.select_box, props.className)}>
            <select value={props.value || value} onChange={onChange} name={props.name}>
                {Object.entries(props.options).map(([key, value], index) => <option value={key} key={index}> {value} </option>)}
            </select>
        </label>
    )
}

export function CheckBoxIcon(props: CheckBoxIconProps) {
    const [value, setValue] = useState<boolean>(props.defaultValue || false)

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked);
        if (props.onChange) {
            props.onChange(e.target.checked)
        }
    }

    return (
        <label className={extendClassName(Styles.check_box, props.className)}>
            <input type="checkbox" name={props.name} checked={value} onChange={onChange} />
            <div className={Styles.icon_box}>
                <Icon path={props.icon} />
            </div>
        </label>
    )

}
