import { CsvHeaderTag, TAG_STYLES } from "../../enum";
import { MouseEventHandler } from "react";
import { Tag } from "../../../common/component/Tag";

export function AdminCsvTag({ tag, clickable, onClick }: {
    tag: CsvHeaderTag,
    clickable?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined
}): JSX.Element {
    return <Tag name={TAG_STYLES[tag].name} color={TAG_STYLES[tag].color} onClick={clickable ? onClick : undefined} />
}
