import {ModalBase, ModalField, useModal} from "../../../core/modal/ModalBase";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import {mdiEmailOutline} from "@mdi/js";
import {useState} from "react";
import {EMAIL_REGEX} from "../../../util/const";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

export default function AddUser({componentID}: {componentID: number}) {

    const [email, setEmail] = useState<string>('')
    const [error, setError] = useState<string>('')

    const modal = useModal();

    const send = () => {
        CacheManager.addUserAccessFromEmail(componentID, email).then(() => {
            modal.closeModal();
        }).catch((e) => {
            setError(e.message)
        })
        return false;
    }

    return (
        <ModalBase
            title={LANG.users_add}
            buttons={
            [
                {
                    label: LANG.global_add,
                    active: EMAIL_REGEX.test(email),
                    id: 'add',
                }
            ]
            }
            onButtonClick={send}
        >
            <div className={ModalStyle.body_fields}>
                <ModalField
                    id={'email'}
                    type={'email'}
                    label={LANG.users_modal_field_email}
                    placeholder={LANG.users_modal_field_email_placeholder}
                    svg={mdiEmailOutline}
                    defaultValue={email}
                    onChange={setEmail}
                    error={error}
                />
            </div>
        </ModalBase>
    )
}