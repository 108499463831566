import {ModalBase} from "./ModalBase";
import {LANG} from "../../lang";

export function ConfirmationModal({
                                      title,
                                      message,
                                      useHtmlMessage,
                                      onConfirm
                                  }: {
                                      title: string,
                                      message: string | JSX.Element,
                                      useHtmlMessage?: boolean,
                                      onConfirm: () => void | Promise<void>
                                  }
): JSX.Element {

    const onButtonClick = (id: string) => {
        if (id === 'confirm') {
            onConfirm();
        }
        return true;
    }

    return (
        <ModalBase
            title={title}
            buttons={[
                {label: LANG.global_confirm, id: 'confirm', active: true}
            ]}
            onButtonClick={onButtonClick}
        >
            <div>
                {useHtmlMessage ? <div dangerouslySetInnerHTML={{__html: message as string}}/> : <p>{message}</p>}
            </div>
        </ModalBase>
    )
}