import { Scan, Student, StudentWithScanTime } from "../../../declarations";
import { useCallback, useEffect, useState } from "react";
import CacheManager from "../../../service/cacheManager";
import { ChangeEvent, useEventListener } from "../../common";
import { Permission } from "../../../util/pernission";

export function useStudentsFromEvent(eventId: number): {
    students: Promise<StudentWithScanTime[]>,
    refresh: () => void,
    allow_scans: boolean
} {
    const [students, setStudents] = useState<Promise<StudentWithScanTime[]>>(Promise.resolve([]))

    const allow_scans = CacheManager.havePermission(Permission.VIEW_SCANS)

    const refresh = useCallback(() => {
        if (allow_scans) {
            setStudents(Promise.all([
                CacheManager.getEventStudents(eventId),
                CacheManager.getEventScans(eventId)
            ]).then(([students, scans]) => {
                return students.map((v: Student) => ({
                    ...v,
                    scan: scans.find((s: Scan) => s.student_id === v.id) || undefined
                }))
            }));
        } else {
            setStudents(CacheManager.getEventStudents(eventId).then(students => {
                return students.map((v: Student) => ({
                    ...v,
                    scan: undefined,
                }))
            }))
        }


    }, [allow_scans, eventId])

    const eventCallback = useCallback((event: ChangeEvent<Scan>) => {
        setStudents(async (prevState: Promise<StudentWithScanTime[]>) => {
            const students = await prevState;
            const index = students.findIndex((student) => student.id === event.data.student_id);
            if (index >= 0) {
                if (event.data.time)
                    students[index].scan = event.data;
                else students[index].scan = undefined;
            }
            return students;
        })
    }, [])

    useEventListener('studentPresenceChange', eventCallback)

    useEffect(refresh, [refresh])

    return { students, refresh, allow_scans }

}
