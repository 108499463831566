import {ModalBase, ModalCheckBox, ModalField, useModal} from "../../../core/modal/ModalBase";
import {useState} from "react";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import {mdiCreditCardSettingsOutline} from "@mdi/js";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

const isValidCardID = (id: string) => /^[0-9a-fA-F]{0,20}$/.test(id) ? id.toUpperCase() : null;
export function EditCardID({studentId}: {studentId: number}) : JSX.Element {

    const [cardId, setCardId] = useState<string>('')
    // LE = Little Endian
    // if true , carId is in Little Endian format
    const [isLE, setIsLe] = useState<boolean>(true)
    const modal = useModal()
    const _onCreate = (id: string): boolean => {
        if (id !== "ok" || cardId.length <= 0 || cardId.length > 20) return false;

        CacheManager.updateStudentCardID(studentId, cardId, isLE).catch(modal.openErrorModal)

        return true;
    }

    return <ModalBase
            title={LANG.student_card_modal_title}
            buttons={[
                {id: "ok", label: LANG.global_edit, active: cardId.length > 0 && cardId.length <= 20}
            ]}
            onButtonClick={_onCreate}
        >
        <div className={ModalStyle.body_fields}>
            <ModalField
                id='card_id'
                label={LANG.student_card_modal_field_card}
                placeholder={LANG.student_card_modal_field_card}
                defaultValue={cardId}
                onChange={setCardId}
                type='text'
                validation={isValidCardID}
                svg={mdiCreditCardSettingsOutline}
                />
            <ModalCheckBox
                id='le'
                label={LANG.student_card_modal_field_little_endian}
                defaultValue={isLE}
                onChange={setIsLe}
                description={LANG.student_card_modal_field_little_endian_description}
            />
        </div>
    </ModalBase>
}