import CacheManager from "../../../service/cacheManager";
import Styles from "../styles/modal/ProfilePopup.module.scss"
import { mdiLogout } from "@mdi/js";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import { LANG } from "../../../lang";

export function ProfilePopup() {

    const acYear = CacheManager.unsafeGetAcYear();
    const navigate = useNavigate()
    const logout = () => {
        CacheManager.logout()
        navigate('/f-auth')
    }

    const changeAcYear = () => {
        navigate('/f-auth')
    }

    return (
        <div className={Styles.popup}>
            <ul>
                <li className={Styles.separator}></li>
                <li className={Styles.current_user}>
                    <span>
                        {CacheManager.getSelfUnsafe()?.email}
                    </span>
                    <span>
                        @{CacheManager.getSelfUnsafe()?.username}
                    </span>

                </li>
                <li className={Styles.separator}></li>
                <li className={Styles.acYear}>
                    {acYear?.component?.name} - {acYear?.name}

                </li>
                <li>
                    <button onClick={changeAcYear} className={Styles.logout_btn}>
                        <Icon path={mdiLogout} className={Styles.logout__icon} />
                        <span>{LANG.profile_switch_ac_year}</span>
                    </button>
                </li>
                <li className={Styles.separator}></li>
                <li className={Styles.logout}>
                    <button onClick={logout} className={Styles.logout_btn}>
                        <Icon path={mdiLogout} className={Styles.logout__icon} />
                        <span>{LANG.profile_logout}</span>
                    </button>
                </li>
            </ul>
        </div>
    )
}
