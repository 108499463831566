import { ModalBase, ModalField, useModal } from "../../../core/modal/ModalBase";
import { ACYear } from "../../../declarations";
import { useCallback, useState } from "react";
import Styles from '../styles/ArchiveAcYear.module.scss'
import CacheManager from "../../../service/cacheManager";
import { LANG } from "../../../lang";

export function ArchiveAcYear({ acYear }: { acYear: ACYear }) {

    const [value, setValue] = useState<string>('')
    const [error, setError] = useState<string | undefined>()
    const modal = useModal()

    const confirm = useCallback((id: string) => {
        if (id !== 'ok') return true;

        CacheManager.archiveAcYear(acYear.id)
            .then(() => modal.closeAllModals)
            .catch((e) => setError(e.message));

        return false;
    }, [acYear.id, modal.closeAllModals])

    return (
        <ModalBase
            title={LANG.ac_year_archive_modal_title}
            buttons={
                [
                    {
                        id: 'ok',
                        label: LANG.global_archive,
                        active: value === acYear.name
                    }
                ]
            }
            onButtonClick={confirm}
        >
            <div className={Styles.danger_info}>
                {LANG.ac_year_archive_modal_warnings.map((v, i) => <p key={i}>{v}</p>)}
            </div>
            <div>
                <p>{LANG.ac_year_archive_modal_confirm}</p>
                <p className={Styles.ac_year_name}>
                    {acYear.name}
                </p>
                <ModalField id='valide' type={'text'} label={''}
                    defaultValue={value} svg={''} placeholder={''}
                    onChange={setValue} error={error}
                />

            </div>
        </ModalBase>
    )
}
