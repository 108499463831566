import { ModalBase, ModalField, useModal } from "../../../core/modal/ModalBase";
import { useCallback, useState } from "react";
import { UComponent } from "../../../declarations";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import { mdiPound } from "@mdi/js";
import CacheManager from "../../../service/cacheManager";
import { LANG } from "../../../lang";

const validationName = (value: string): string | null => value.length > 40 ? null : value;

export function EditComponent({ baseComponent }: { baseComponent?: UComponent }): JSX.Element {

    const [name, setName] = useState<string>(baseComponent?.name ?? "");

    const modal = useModal();

    const onCreate = useCallback((id: string) => {
        if (id !== "create") return true;
        if (baseComponent === undefined) {
            CacheManager.createComponent(name).catch(modal.openErrorModal);
        } else {
            CacheManager.updateComponent({
                id: baseComponent.id,
                name: name,
            }).catch(modal.openErrorModal)
        }

        return true;
    }, [baseComponent, modal.openErrorModal, name])

    return (
        <ModalBase title={baseComponent ? LANG.component_edit : LANG.component_create}
            buttons={[
                { id: "create", label: baseComponent ? LANG.global_edit : LANG.global_create, active: name.length > 2 && name.length <= 40 }
            ]}
            onButtonClick={onCreate}
        >
            <div className={ModalStyle.body_fields}>
                <ModalField
                    id='name'
                    type='text'
                    label={LANG.component_modal_field_name}
                    defaultValue={name}
                    svg={mdiPound}
                    placeholder={LANG.component_modal_field_name}
                    validation={validationName}
                    onChange={(value: string) => setName(value)}
                />
            </div>
        </ModalBase>
    )
}
