import { UploaderUploadDisplayProps } from "../../declaration";
import { GlobalSpinner } from "../../../common";
import Styles from '../../styles/uploader/UploaderUploadDisplay.module.scss'
import Icon from "@mdi/react";
import { mdiCheckBold, mdiCloseThick } from "@mdi/js";
import { extendClassName } from "../../../../util/utilsHelper";
import { LANG } from "../../../../lang";

export function UploaderUploadDisplay({ status }: UploaderUploadDisplayProps): JSX.Element {

    if (status.status === 'uploading') {
        return (
            <div className={Styles.container}>
                <GlobalSpinner className={Styles.loader} />
                <span className={Styles.text}>{status.message}</span>
            </div>
        )
    }
    if (status.status === 'done') {
        return (
            <div className={Styles.container}>
                <figure className={extendClassName(Styles.figure, Styles.success)}>
                    <Icon path={mdiCheckBold} />
                </figure>
                <span className={Styles.text}>{LANG.admin_csv_send_success}</span>
            </div>
        )
    }

    return (
        <div className={Styles.container}>
            <figure className={extendClassName(Styles.figure, Styles.error)}>
                <Icon path={mdiCloseThick} />
            </figure>
            <span className={Styles.text}>{status.message || LANG.error_unknown}</span>
            <span className={Styles.text}>{status.error || LANG.error_unknown}</span>
        </div>
    )
}
