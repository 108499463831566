import {
    childrenProp, ModalCheckBoxProps,
    ModalContextPrivateType,
    ModalContextType,
    ModalFieldProps,
    ModalProps,
    ModalSelectProps,
    ModalSettings,
    Position,
    PositionFactory,
    PositionPopout
} from "../../declarations";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Styles from "./Modal.module.scss"
import { extendClassName, genericMemo, posInRect } from "../../util/utilsHelper";
import Icon from "@mdi/react";
import { useLocation } from "react-router-dom";
import { ColorPicker } from "./ColorPicker";
import { ColorResult } from "react-color";
import { LANG } from "../../lang";
import { ToolTipContainer } from "../../feature/common";


const ModalContext = React.createContext<ModalContextPrivateType | undefined>(undefined)
const MODAL_SETTINGS_DEFAULT: ModalSettings = {
    position: "center",
    layer: 0,
}

const POSITION_GAP = 0;

const IPositionFactory: PositionFactory = {
    bottom(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 - p.width / 2,
            y: r.bottom + POSITION_GAP
        };
    }, left(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left - p.width - POSITION_GAP,
            y: r.top + r.height / 2 - p.height / 2
        }
    }, right(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.right + POSITION_GAP,
            y: r.top + r.height / 2 - p.height / 2
        };
    }, top(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 - p.width / 2,
            y: r.top - p.height - POSITION_GAP
        }
    }, "bottom-left"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 - p.width,
            y: r.bottom + POSITION_GAP
        };
    }, "bottom-right"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 + p.width,
            y: r.bottom + POSITION_GAP
        };
    }, "left-bottom"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left - p.width - POSITION_GAP,
            y: r.top + r.height / 2 + p.height
        };
    }, "left-top"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left - p.width - POSITION_GAP,
            y: r.top + r.height / 2 - p.height
        };
    }, "right-bottom"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.right + POSITION_GAP,
            y: r.top + r.height / 2 + p.height
        };
    }, "right-top"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.right + POSITION_GAP,
            y: r.top + r.height / 2 - p.height
        };
    }, "top-left"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 - p.width,
            y: r.top - p.height - POSITION_GAP
        };
    }, "top-right"(r: DOMRect, p: DOMRect): Position {
        return {
            x: r.left + r.width / 2 + p.width,
            y: r.top - p.height - POSITION_GAP
        };
    }
}

export const alwaysOK = () => true;



const _ModalField = <K extends string>(props: ModalFieldProps<K>): JSX.Element => {

    let lastValue: string = props.defaultValue || "";
    const onChanges = (event: React.ChangeEvent<HTMLInputElement>, color?: ColorResult,) => {
        if (props.validation) {
            let value = props.validation(event.target.value)
            if (value === null) {
                event.target.value = lastValue;
                return;
            }
            event.target.value = value;
        }
        if (props.onChange) {
            props.onChange(event.target.value, props.id)
        }
        lastValue = event.target.value;
    }

    const input = props.type === 'color' ? <ColorPicker onChange={onChanges} defaultValue={props.defaultValue} /> :
        <input className={Styles.input} type={props.type} placeholder={props.placeholder}
            defaultValue={props.defaultValue} onChange={onChanges} list={props.dastaListId} />
    const dataList = props.dataList ? <datalist id={props.dastaListId}>
        {props.dataList.map(d => <option key={props.dastaListId + d} value={d} />)}
    </datalist> : undefined;


    return (
        <div className={extendClassName(Styles.field, props.className)}>
            <label className={Styles.label}>{props.label}</label>
            <div className={Styles.input_container} data-type={props.type}>
                {props.svg && <Icon className={Styles.svg} path={props.svg} />}
                {input}
                {dataList}
            </div>
            {props.error && <p className={Styles.error}>{props.error}</p>}
        </div>
    )
};
export const ModalField = genericMemo(_ModalField)

function _ModalCheckBox<K extends string>(props: ModalCheckBoxProps<K>): JSX.Element {

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange)
            props.onChange(event.target.checked, props.id)
    }

    return (
        <div className={extendClassName(Styles.field_checkbox, props.className)}>
            <div className={Styles.input_container}>
                <div className={Styles.label_container}>
                    {props.svg && <Icon path={props.svg} className={Styles.svg} />}
                    <label className={Styles.label}>{props.label}</label>
                </div>
                <div className={Styles.checkbox_wrapper}>
                    <input onChange={onChange} checked={props.defaultValue} type={"checkbox"} />
                </div>
            </div>
            {props.description &&
                <p className={Styles.description}>
                    {props.description}
                </p>
            }
            {props.error && <p className={Styles.error}><span>{props.error}</span></p>}
        </div>
    )
}

export const ModalCheckBox = genericMemo(_ModalCheckBox)

export function ModalFieldSelect<K extends string>(props: ModalSelectProps<K>): JSX.Element {

    return (
        <div className={extendClassName(Styles.field, props.className)}>
            <label className={Styles.label}>{props.label}</label>
            <div className={Styles.input_container} data-type='select'>
                {props.svg && <Icon className={Styles.svg} path={props.svg} />}
                <select className={Styles.select} defaultValue={props.defaultValue}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        if (props.onChange) {
                            props.onChange(event.target.value, props.id)
                        }
                    }}>
                    {props.values.map(({ id, label }, index) => {
                        return <option key={index} value={id}>{label}</option>
                    })
                    }
                </select>
            </div>
            {props.error && <p className={Styles.error}>{props.error}</p>}
        </div>
    )
}

// ModalBase is a wrapper, it provides default style for modal to be centered
export function ModalBase(props: ModalProps): JSX.Element {
    const modal = useModal()
    return (
        <div className={extendClassName(Styles.modal, props.className)}>
            <div className={Styles.header}>
                <h2 className={Styles.title}>{props.title}</h2>
                <ToolTipContainer data-tooltip-content={LANG.global_close}>
                    <div role='button' className={Styles.close_button} onClick={modal.closeModal}></div>
                </ToolTipContainer>
            </div>
            <div className={Styles.content}>
                {props.children}
            </div>
            <div className={Styles.footer}>
                <div className={Styles.buttons}>
                    <button className={Styles.cancel_btn} onClick={modal.closeModal}>{LANG.global_cancel}</button>
                    {props.buttons && props.buttons.map((button, index) => {
                        return <button className={Styles.optional_btn} key={index} onClick={() => {
                            if (!props.onButtonClick || props.onButtonClick(button.id)) {
                                modal.closeAllModals()
                            }
                        }} disabled={!button.active}>{button.label}</button>
                    })}
                </div>
            </div>
        </div>
    )
}

// ModalContainer is the component that will display the modal
// it's provided by the ModalProvider
// this component should not be used directly in the code only by the ModalProvider
function ModalContainer(): JSX.Element {
    // this component is used to display the modal
    const [modal, setModal] = useState<{ element: JSX.Element, settings: ModalSettings, openTime: number }[]>([])
    const popoutRef = useRef<HTMLElement | null>(null)
    // const [position, setPosition] = useState<{ x: number, y: number }>({x: 0, y: 0})

    // get the context and set the new ModalContext controller
    const context = React.useContext(ModalContext)
    if (context === undefined) {
        throw new Error('ModalContainer must be used within a ModalProvider')
    }

    const openModal = useCallback((v: JSX.Element, s?: ModalSettings) => {
        const newModal = { element: v, settings: s || MODAL_SETTINGS_DEFAULT, openTime: Date.now() }
        const modalLayer = newModal.settings.layer ?? 0;
        if (modalLayer < 0) {
            throw new Error("Modal layer must be positive")
        }

        // keep only the modal with a layer lower than the new one
        const oldModal = modal.filter((m) => {
            return (m.settings.layer ?? 0) <= modalLayer
        })
        // sort should be useless but just in case
        setModal([...oldModal, newModal].sort((a, b) => (a.settings.layer ?? 0) - (b.settings.layer ?? 0)))
    }, [modal, setModal])

    const closeModal = useCallback(() => {
        setModal(modal.slice(0, modal.length - 1))
        return modal.length > 0
    }, [modal, setModal])

    useEffect(() => {

        const value: ModalContextType = {
            openModal: (v: JSX.Element, s?: ModalSettings) => {
                openModal(v, s)
            },
            openErrorModal: (v: any, s?: ModalSettings) => {
                openModal(<ModalBase title={'Error'}> {v.error || v.message || v} </ModalBase>, s)
            },
            closeModal: () => {
                return closeModal()
            },
            closeAllModals: () => {
                setModal([])
            },
            isOpen: () => {
                return modal.length > 0
            }
        }

        context.setModalContext(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setModal, openModal, context.setModalContext])


    useEffect(() => {
        // if no modal is open, do nothing
        if (modal.length === 0) return
        // function check if need to close when user click
        const closeIfClickOutside = (e: MouseEvent) => {

            if (e.target && e.target instanceof HTMLElement) {
                // if click in modal fix container or if modal is relative to something and click outside of it
                if (e.target.className.includes(Styles.modal_fix_container) ||
                    (modal[modal.length - 1]?.settings.relativeTo && popoutRef.current && Date.now() - modal[modal.length - 1].openTime > 100 && !posInRect(e.x, e.y, popoutRef.current.getBoundingClientRect()))
                ) {
                    closeModal()
                }
            }
        }
        // register the function to close the modal when clicking outside
        document.body.addEventListener('mousedown', closeIfClickOutside)
        // unregister the function when the modal is closed when state change
        return () => {
            document.body.removeEventListener('mousedown', closeIfClickOutside)
        }
    }, [closeModal, modal])


    // close when changing location
    const location = useLocation();
    useEffect(() => {
        // execute on location change
        setModal([])
    }, [location]);


    const drawControlRef = useCallback((ref: HTMLElement | null, m: { element: JSX.Element, settings: ModalSettings }) => {
        if (!ref || !modal || !m.settings.relativeTo) return;
        popoutRef.current = ref;
        const relativeDirection = m.settings.relativeDirection || 'auto';

        const relativeBoundingRect = m.settings.relativeTo.getBoundingClientRect();
        const refBoundingRect = ref.getBoundingClientRect();

        const documentWidth = document.documentElement.clientWidth;
        const documentHeight = document.documentElement.clientHeight;

        const isInBound = (pos: { x: number, y: number }) => {
            return pos.x >= 0 && pos.x + refBoundingRect.width <= documentWidth && pos.y >= 0 && pos.y + refBoundingRect.height <= documentHeight;
        }

        const setPosition = (pos: { x: number, y: number }) => {
            ref.style.left = pos.x + (m.settings.offset?.[0] || 0) + 'px';
            ref.style.top = pos.y + (m.settings.offset?.[1] || 0) + 'px';
        }
        // if not auto check if the position is in bound
        // else get first valid position
        // if no valid position force to bottom
        // && isInBound(IPositionFactory[relativeDirection](relativeBoundingRect, refBoundingRect))
        if (relativeDirection !== 'auto') {
            setPosition(IPositionFactory[relativeDirection](relativeBoundingRect, refBoundingRect));
            return;
        } else {
            let pos: PositionPopout
            for (pos in IPositionFactory) {
                const rpos = IPositionFactory[pos](relativeBoundingRect, refBoundingRect);
                if (isInBound(rpos)) {
                    setPosition(rpos);
                    return;
                }
            }
            setPosition(IPositionFactory.bottom(relativeBoundingRect, refBoundingRect));
            return;
        }
    }, [modal])


    const content: JSX.Element | null =
        <>
            {
                modal.map((m, i) => {
                    return m.settings.position === 'relative' ? (
                        <article key={i} role='dialog' ref={(ref: HTMLElement | null) => drawControlRef(ref, m)} className={Styles.modal_relative_container}>
                            {m.element}
                        </article>
                    ) : (
                        <div key={i} className={extendClassName(Styles.modal_fix_container, 'flex-' + m.settings.position)}
                            tabIndex={-1} role='dialog'>
                            {m.element}
                        </div>
                    )
                })
            }

        </>
    return (
        <div className={Styles.layout_container}>
            {content}
        </div>
    )
}

// useModal is a hook, it should be used in a component
// it returns a ModalContextType object which contains
// openModal, closeModal and isOpen
// should be called in a ModalContainer child component
export function useModal(): ModalContextType {
    const context = React.useContext(ModalContext)
    if (context === undefined || context.modalContext === undefined) {
        throw new Error('useModal must be used within a ModalProvider')
    }
    return context.modalContext
}

// ModalProvider is a wrapper, it should be used in the root component
// if you want to use modal everywhere
export function ModalProvider(props: childrenProp): JSX.Element {

    const [modalContext, setModalContext] = useState<ModalContextType | undefined>(undefined)
    const value = React.useMemo<ModalContextPrivateType>(() => ({
        modalContext,
        setModalContext
    }), [modalContext, setModalContext])

    return (
        <ModalContext.Provider value={value}>
            {/*render child only when Modal container have update context to prevent duplicate render*/}
            {modalContext && props.children}
            <ModalContainer />
        </ModalContext.Provider>
    )

}
