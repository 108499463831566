import {classNameProp} from "../../../../declarations";
import {CsvColumn} from "../../declaration";
import {CsvHeaderTag} from "../../enum";
import {SmartColumnsTag} from "./SmartColumnsTag";
import Styles from '../../styles/uploader/UploaderStudentDisplay.module.scss';
import {extendClassName} from "../../../../util/utilsHelper";
import {LANG} from "../../../../lang";

export function UPColumns(
    {className, col, onTagAdd}: classNameProp & {
        col: CsvColumn[],
        onTagAdd: (index: number, tag: CsvHeaderTag) => void
    }
): JSX.Element {
    return (
        <table className={extendClassName(className, Styles.table)}>
            <thead className={Styles.head}>
            <tr>
                <th>{LANG.admin_csv_column}</th>
                <th>{LANG.admin_csv_tag}</th>
            </tr>
            </thead>
            <tbody className={Styles.body}>
            {col.map((e: CsvColumn) => (
                <tr key={e.index}>
                    <td>{e.name}</td>
                    <td>
                        <SmartColumnsTag tag={e.tag} onChange={(tag: CsvHeaderTag) => onTagAdd(e.index, tag)}/>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    )
}