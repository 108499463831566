import { CsvHeaderTag } from "../../enum";
import { useModal } from "../../../../core/modal/ModalBase";
import { SelectTag } from "../../modal/uploader/SelectTag";
import React from "react";
import { AdminCsvTag } from "./Tag";


export function SmartColumnsTag({ tag, onChange }: {
    tag: CsvHeaderTag,
    onChange: (tag: CsvHeaderTag) => void
}): JSX.Element {

    const modal = useModal();

    return (
        <AdminCsvTag tag={tag} clickable onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            modal.openModal((<SelectTag onSelect={onChange} />), {
                position: 'relative',
                relativeTo: e.target as HTMLElement,
                relativeDirection: 'bottom',
                layer: 1
            })
        }} />
    )

}
