import {useComponents} from "../hook/useComponents";
import {Permission} from "../../../util/pernission";
import {Suspense} from "react";
import {GlobalSpinner} from "../../common";
import {Await, NavLink} from "react-router-dom";
import {UComponent} from "../../../declarations";
import Icon from "@mdi/react";
import {mdiPound} from "@mdi/js";
import Styles from "../styles/ComponentsList.module.scss"

export function ComponentsLis() {

    const [components] = useComponents(Permission.ADMIN)

    return (
        <>
            <Suspense fallback={<GlobalSpinner/>}>
                <Await resolve={components}>
                    {(components: UComponent[]) => (
                        <ul>
                            {components.map((component) => (
                                <li key={component.id}>
                                    <NavLink to={`/admin/${component.id}`} className={Styles.component}>
                                        <div className={Styles.component__info}>
                                            <div className={Styles.component__id}>
                                                <Icon path={mdiPound} className={Styles.component__id__ico} />
                                                <span>{component.id}</span>
                                            </div>
                                            <span>{component.name}</span>
                                        </div>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </Await>
            </Suspense>
        </>
    )
}