import { UserPermissions } from "../../../declarations";
import Styles from "../styles/UserPermissionsLine.module.scss";
import Icon from "@mdi/react";
import { mdiPencil, mdiPencilOutline, mdiPound, mdiTrashCan, mdiTrashCanOutline } from "@mdi/js";
import { IconButton } from "../../common";
import { useModal } from "../../../core/modal/ModalBase";
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal";
import CacheManager from "../../../service/cacheManager";
import { EditUserPermissions } from "../modal/EditUserPermissions";
import { Permission, PermissionName } from "../../../util/pernission";
import { LANG } from "../../../lang";
import { ButtonStyle } from "../../common/component/IconButton";

export function UserPermissionsLine({ user }: { user: UserPermissions }): JSX.Element {

    const modal = useModal();

    const deleteAccesses = async () => {
        await CacheManager.removeUserAccess(user.component_id, user.id)
    };

    const isSelf = user.id === CacheManager.getSelf().id;

    return (
        <div className={Styles.user_perm_line}>
            <span className={Styles.id}><Icon path={mdiPound} className={Styles.pound} />{user.id}</span>
            <span>@{user.username}</span>
            <span className={Styles.permissions_names}>
                {
                    (user.permission & Permission.ADMIN) !== 0 ? PermissionName[Permission.ADMIN] : Object.values(Permission).filter((value) => (user.permission & value) !== 0).map((value) => PermissionName[value]).join(', ')
                }
            </span>
            <div role='group' aria-label='actions' className={Styles.actions}>
                <IconButton
                    onClick={() => modal.openModal(<EditUserPermissions user={user} />)}
                    path={mdiPencilOutline}
                    pathHover={mdiPencil}
                    d-style={ButtonStyle.CLEAR}
                    className={Styles.action}
                    title={LANG.global_edit}
                    large
                />

                {!isSelf &&
                    <IconButton
                        onClick={() => {
                            modal.openModal(
                                <ConfirmationModal title={LANG.users_permission_remove_confirmation_title(user.username)}
                                    message={LANG.users_permission_remove_confirmation(user.username)}
                                    onConfirm={deleteAccesses}
                                />
                            )
                        }}
                        path={mdiTrashCanOutline}
                        pathHover={mdiTrashCan}
                        d-style={ButtonStyle.CLEAR}
                        className={Styles.action}
                        title={LANG.global_delete}
                        large
                    />
                }
            </div>

        </div>
    )

}
