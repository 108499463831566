import {Await, NavLink, Outlet, useParams} from "react-router-dom";

import Styles from '../styles/Admin.module.scss'
import React, {Suspense} from "react";
import {GlobalSpinner, IconButton} from "../../common";
import {mdiPencil, mdiPencilOutline} from "@mdi/js";
import {UComponent} from "../../../declarations";
import {useComponent} from "../hook/useComponent";
import {useModal} from "../../../core/modal/ModalBase";
import {EditComponent} from "../modal/EditComponent";
import {extendClassName} from "../../../util/utilsHelper";
import {LANG} from "../../../lang";
import {ButtonStyle} from "../../common/component/IconButton";

export function ComponentsEdit(): JSX.Element {

    const modal = useModal();

    const {componentID} = useParams() as { componentID: string };

    const [component] = useComponent(parseInt(componentID))

    let defaultPath =  /^\/admin\/[0-9]+$/.exec(window.location.pathname) !== null

    return (
        <div className={Styles.component_view}>
            <div className={Styles.head}>
                <Suspense fallback={<GlobalSpinner/>}>
                    <Await resolve={component}>
                        {(c: UComponent|undefined) => (
                            <div className={Styles.view_pt + " panel_content_head"}>
                                <div className='head__title'>
                                    <h2>{c?.name}</h2>
                                </div>
                                <IconButton
                                    onClick={() => {modal.openModal(<EditComponent baseComponent={c} />)}}
                                    path={mdiPencilOutline}
                                    pathHover={mdiPencil}
                                    d-style={ButtonStyle.CLEAR}
                                    title={LANG.component_edit}
                                    title-place='right'
                                    large
                                   />
                            </div>
                        )}
                    </Await>
                </Suspense>

            </div>
            <div className={Styles.component_category_container}>
                <NavLink className={extendClassName(Styles.category, defaultPath ? 'active' : undefined)} to={`/admin/${componentID}/ac-year`} end>{LANG.ac_years}</NavLink>
                <div className={Styles.separator} role={"separator"} aria-hidden={true}></div>
                <NavLink className={Styles.category} to={`/admin/${componentID}/users`} end>{LANG.users}</NavLink>
                <div className={Styles.separator} role={"separator"} aria-hidden={true}></div>
                <NavLink className={Styles.category} to={`/admin/${componentID}/upload`} end>{LANG.admin_upload}</NavLink>
            </div>
            <main className={Styles.content}>
                <Outlet/>
            </main>
        </div>
    )
}