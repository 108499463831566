import { ALL_CSV_HEADER_TAGS, CsvHeaderTag } from "../../enum";

import Styles from '../../styles/uploader/SelectTag.module.scss'
import { AdminCsvTag } from "../../component/uploader/Tag";
import { useModal } from "../../../../core/modal/ModalBase";

export function SelectTag({ onSelect }: { onSelect: (t: CsvHeaderTag) => void }): JSX.Element {
    const modal = useModal();
    return (
        <div className={Styles.modal}>
            {ALL_CSV_HEADER_TAGS.map((tag) => {
                return (
                    <AdminCsvTag key={tag} tag={tag} onClick={() => {
                        onSelect(tag);
                        modal.closeModal();
                    }} clickable />
                )
            })}
        </div>
    )
}
