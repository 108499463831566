import { Outlet, } from "react-router-dom";
import { EventCalendar } from "../component";
import { Panel, ToolTipContainer } from "../../common";
import { useModal } from "../../../core/modal/ModalBase";
import { mdiCalendarMonthOutline, mdiPlus } from '@mdi/js';
import Icon from "@mdi/react";
import { NoContent } from "../../common";
import { IfAllow } from "../../common/component/IfAllow";
import { Permission } from "../../../util/pernission";
import { CreateEventModal } from "../modal/CreateEditEventModal";
import { LANG } from "../../../lang";
import { SelectGroup } from "../../group";


function EventsView(): JSX.Element {
    const modal = useModal();
    const openQuickCreateEvent = () => {
        modal.openModal(<SelectGroup callback={(event) => {
            modal.openModal(<CreateEventModal quickGroup={event} />)
        }} />)
    }

    return (
        <div className={'main-panel'}>
            <Outlet />
            <Panel title={LANG.events}
                icon={mdiCalendarMonthOutline}
                className="panel_shadow"
                headClassName={'pan_bold_border'}
                button={(
                    <IfAllow permission={Permission.CREATE_EVENT}>
                        <ToolTipContainer data-tooltip-content={LANG.event_create} data-tooltip-place='right'>
                            <button className={'pam__btn'} onClick={openQuickCreateEvent}>
                                <Icon
                                    path={mdiPlus} /></button>
                        </ToolTipContainer>
                    </IfAllow>
                )}>
                <EventCalendar />
            </Panel>
        </div>
    )
}

const NoEvent = (
    <NoContent iconPath={mdiCalendarMonthOutline}
        text={LANG.event_not_selected}
        btnText={LANG.event_create}
        permission={Permission.CREATE_EVENT}
        modal={<CreateEventModal />}
    />
)

export {
    EventsView,
    NoEvent
}
