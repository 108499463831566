export const APP_NAME = 'Émargement';
export const DEFAULT_EVENT_COLOR = '#7F7EBE';

export const COLOR_PALETTE = [
    '#FEFAE0',
    '#FFF6BD',
    '#FFE1AF',
    '#FFCE9A',
    '#FFD78D',
    '#FFC759',
    '#FFE1E1',
    '#FFB9B9',
    '#FF9F9F',
    '#FF7575',
    '#84033C',
    '#E9E6FF',
    '#D4B9F8',
    '#BE8ABF',
    '#7286D3',
    '#A1CAE2',
    '#5784B4',
    '#484C7F',
    '#212E53',
    '#110822',
    '#C9E4C5',
    '#BCE29E',
    '#70AF85',
    '#41D383'
]

export const DEFAULT_EVENT_COLOR_RGB = {
    r: 127,
    g: 126,
    b: 190,
}

export const TOOL_TIP_ID = 'backoffice-tooltip'

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const SCAN_TYPE = {
    PRESENT: 0,
    JUSTIFIED: 1,
    ABSENT: 2
}
