import React, { useEffect } from 'react';
import BackofficeRouter from "./router";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { APP_NAME, TOOL_TIP_ID } from "./util/const";

function App() {
    useEffect(() => {
        document.title = APP_NAME;
    }, []);
    return (
        <>
            <BackofficeRouter />
            <Tooltip className={'tooltip-container'} id={TOOL_TIP_ID} />
        </>
    )
}

export default App;
