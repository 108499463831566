import { mdiSchool } from "@mdi/js";
import { Panel } from "../../common";
import { Outlet } from "react-router-dom";
import { StudentList } from "../component/StudentList";
import { LANG } from "../../../lang";

export function StudentView() {

    return (
        <div className={'main-panel'}>
            <Outlet />
            <Panel title={LANG.students}
                icon={mdiSchool}
                className="panel_shadow"
                headClassName={'pan_bold_border'}
            >
                <div>
                    {/*<button onClick={() => {modal.openModal(<SignatureModal />)}}>Valider</button>*/}
                    <StudentList chackable={false} filter={''} />

                </div>
            </Panel>
        </div>
    )
}
