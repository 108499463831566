import { APIAnswer, APIRequest, APIRequests, BodeParam } from "../declarations";
import CacheManager from "./cacheManager";
import { RequestError } from "../util/RequestError";

export const REQUESTS: APIRequests = {
    DELETE_GROUP_STUDENT: {
        method: 'DELETE',
        url: '/groups/:id/students/:student_id',
    },
    GET_GROUP: {
        method: 'GET',
        url: '/groups/:id'
    },
    GET_GROUP_STUDENTS: {
        method: 'GET',
        url: '/groups/:id/students'
    },
    PATCH_GROUP: {
        method: 'PATCH',
        url: '/groups/:id',
        body: {
            name: 'string',
            color: 'color',
        }
    },
    POST_GROUP: {
        method: 'POST',
        url: '/ac-years/:id/groups',
        body: {
            name: 'string',
            color: 'color',
        }
    },
    PUT_GROUP_STUDENT: {
        method: 'PUT',
        url: '/groups/:id/students/:student_id',
    },
    GET_USER: {
        method: 'GET',
        url: '/users/:id'
    },
    GET_USERS_COMPONENTS: {
        method: 'GET',
        url: '/users/:id/components'
    },
    POST_COMPONENTS: {
        method: 'POST',
        url: '/components',
        body: {
            name: 'string',
        }
    },
    PATCH_AC_YEAR: {
        method: 'PATCH',
        url: '/ac-years/:id',
        body: {
            name: 'string',
            year_start: 'number',
            year_end: 'number',
        }
    },
    PATCH_COMPONENT: {
        method: 'PATCH',
        url: '/components/:id',
        body: {
            name: 'string',
        }
    },
    GET_COMPONENTS: {
        method: 'GET',
        url: '/components',
    },
    GET_COMPONENTS_AC_YEARS: {
        method: 'GET',
        url: '/components/:id/ac-years'
    },
    GET_COMPONENT: {
        method: 'GET',
        url: '/components/:id'
    },
    GET_AC_YEAR: {
        method: 'GET',
        url: '/ac-years/:id'
    },
    GET_AC_YEAR_GROUPS: {
        method: 'GET',
        url: '/ac-years/:id/groups'
    },
    GET_AC_YEAR_EVENTS: {
        method: 'GET',
        url: '/ac-years/:id/events',
        query: {
            start_time: true,
            end_time: true
        }
    },
    GET_EVENT: {
        method: 'GET',
        url: '/events/:id'
    },
    GET_EVENT_GROUPS: {
        method: 'GET',
        url: '/events/:id/groups'
    },
    GET_EVENT_STUDENTS: {
        method: 'GET',
        url: '/events/:id/students'
    },
    GET_EVENT_SCANS: {
        method: 'GET',
        url: '/events/:id/scans'
    },
    POST_EVENT_SCAN: {
        method: 'POST',
        url: '/events/:id/scans',
        body: {
            card_id: 'string',
            student_id: 'number',
            justified_absence: 'boolean',
            reason: 'string',
        }
    },
    POST_EVENT: {
        method: 'POST',
        url: '/ac-years/:id/events',
        body: {
            name: 'string',
            room: 'string',
            start_time: 'date',
            end_time: 'date',
            color: 'color',
        }
    },
    POST_EVENT_DUPLICATE: {
        method: "POST",
        url: '/events/:id/duplicate',
        body: {
            name: 'string',
            room: 'string',
            start_time: 'date',
            end_time: 'date',
            color: 'color',
        }
    },
    PATCH_EVENT: {
        method: 'PATCH',
        url: '/events/:id',
        body: {
            name: 'string',
            room: 'string',
            start_time: 'date',
            end_time: 'date',
            color: 'color',
        }
    },
    GET_STUDENTS: {
        method: 'GET',
        url: '/ac-years/:id/students',
        query: {
            limit: false,
            after: false,
            search: false,
        }
    },
    POST_GROUP_STUDENTS: {
        method: 'POST',
        url: '/groups/:id/students',
        body: {
            students: 'array<number>'
        }
    },
    PUT_EVENT_GROUP: {
        method: 'PUT',
        url: '/events/:id/groups/:group_id',
    },
    DELETE_EVENT_GROUP: {
        method: 'DELETE',
        url: '/events/:id/groups/:group_id',
    },
    GET_STUDENT_PRESENCES: {
        method: 'GET',
        url: '/ac-years/:id/students/:student_id/presences',
    },
    GET_STUDENT: {
        method: 'GET',
        url: '/ac-years/:id/students/:student_id',
    },
    GET_STUDENT_GROUPS: {
        method: 'GET',
        url: '/ac-years/:id/students/:student_id/groups',
    },
    LOGIN: {
        method: 'POST',
        url: '/p/login',
        type: 'AUTH',
        body: {
            email: 'string',
            password: 'string',
        }
    },
    CAS_GET_TOKEN: {
        method: 'POST',
        url: '/cas/get-token',
        type: 'AUTH',
        body: {
            token: 'string'
        }
    },
    CAS_REGISTER: {
        method: 'POST',
        url: '/cas/register',
        type: 'AUTH',
        body: {
            token: 'string',
            email: 'string',
        }
    },
    CAS_I_AM_LOG: {
        method: 'GET',
        url: '/cas/i-am-log',
        type: 'AUTH'
    },
    PUT_ARCHIVED_AC_YEAR: {
        method: 'PUT',
        url: '/ac-years/:id/archive',
    },
    POST_COMPONENT: {
        method: 'POST',
        url: '/components',
        body: {
            name: 'string',
        }
    },
    POST_AC_YEAR: {
        method: 'POST',
        url: '/components/:id/ac-years',
        body: {
            name: 'string',
            year_start: 'number',
            year_end: 'number',
        }
    },
    GET_USERS_PERMISSIONS: {
        method: 'GET',
        url: '/components/:id/users',
    },
    GET_USER_PERMISSIONS: {
        method: 'GET',
        url: '/components/:id/users/:user_id',
    },
    PUT_USER_PERMISSIONS: {
        method: 'PUT',
        url: '/components/:id/users/:user_id',
        body: {
            permission: "number",
        }
    },
    DELETE_USER_PERMISSIONS: {
        method: 'DELETE',
        url: '/components/:id/users/:user_id',
    },
    POST_USER_PERMISSIONS_BY_EMAIL: {
        method: 'POST',
        url: '/components/:id/users',
        body: {
            email: 'string',
        }
    },
    POST_STUDENTS: {
        method: 'POST',
        url: '/ac-years/:id/students',
        body: {
            students: 'array<any>'
        }
    },
    PATCH_STUDENT_CARD: {
        method: 'PATCH',
        url: "/students/:id/card",
        body: {
            card_id: 'string'
        }
    },
    PUT_EVENT_VALIDATE: {
        method: 'PUT',
        url: "/events/:id/validate"
    },
    DELETE_EVENT_VALIDATE: {
        method: 'DELETE',
        url: "/events/:id/validate"
    },
    PUT_EVENT_ARCHIVATE: {
        method: 'PUT',
        url: "/events/:id/archived"
    },
    DELETE_EVENT_SCAN: {
        method: "DELETE",
        url: "/events/:event_id/scans/:student_id"
    },
    GET_GROUP_STUDENTS_WITH_SCAN_COUNT: {
        method: "GET",
        url: "/groups/:id/students_with_scan"
    },
    GET_COMPONENT_ROOMS: {
        method: "GET",
        url: "/components/:id/rooms"
    },
    POST_COMPONENT_ROOM: {
        method: "POST",
        url: "/components/:id/rooms",
        body: {
            name: 'string'
        }
    },
    DELETE_COMPONENT_ROOM: {
        method: "DELETE",
        url: "/components/:id/rooms/:room_id",
    }
}

export function getControllerName(request: APIRequest<any>): string {
    return request.url.split('/')[1];
}

export function getAllControllers(): string[] {
    return Object.values(REQUESTS).map((request) => getControllerName(request)).filter((value, index, self) => self.indexOf(value) === index);
}

export function getControllerRequests(controllerName: string): [string, APIRequest<any>][] {
    return Object.entries(REQUESTS).filter(([_, request]) => getControllerName(request) === controllerName);
}

export function getRequestByName(name: keyof APIRequests): APIRequest<any> {
    return REQUESTS[name];
}

export function getAPIRequestUrlParams(request: APIRequest<any>): string[] {
    const urlParams = request.url.match(/:[a-zA-Z_-]+/g);
    return urlParams ? urlParams.map((param) => param.replace(':', '')) : [];
}
export async function fetchRequest<R>(request: APIRequest<R>, params?: { [key: string]: string | undefined }, body?: { [key: string]: BodeParam }): Promise<APIAnswer<R>> {
    let base = request.type === undefined || request.type === 'API' ? '/api/v1' : request.type === 'AUTH' ? '/auth/v1' : '';


    let url = base + request.url.replace(/:[a-zA-Z_-]+/g, (match) => {
        const param = match.replace(':', '');
        if (!params || !params[param]) {
            throw new Error(`Missing param ${param} for request ${request.url}`);
        }
        return params[param] || '';
    });

    if (request.query) {
        const query = Object.entries(request.query).filter(([key, require]) => {
            return require || (params && params[key]);
        }).map(([key, _]) => {
            if (!params || !params[key]) {
                throw new Error(`Missing qeury param ${key} for request ${request.url}`);
            }
            return `${key}=${params[key]}`;
        }).join('&');
        if (query) {
            url += '?' + query;
        }
    }

    const init: RequestInit = {
        method: request.method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'error'
    }

    if (request.type === undefined || request.type === 'API') {
        // @ts-ignore can't be null
        init.headers['Authorization'] = `Token ${CacheManager.getToken()}`;
    }

    if (request.body) {
        if (!body) {
            throw new Error(`Missing body for request ${request.url}`);
        }
        init.body = JSON.stringify(body);
    }



    const res = await fetch(url, init);
    if (res.ok) {
        if (res.status === 204) {
            // if no content return empty object
            // not very clean but it works
            return {
                status: 204,
                data: {}
            } as APIAnswer<R>;
        }
        return await res.json();
    } else {
        let json;
        try {
            json = await res.json();
        } catch (_) {
            throw new RequestError(res.statusText, res.status, null);
        }
        throw new RequestError(json.error || res.statusText, res.status, json);
    }

}
