import {Outlet} from "react-router-dom";
import {HeaderAdmin} from "../../common/component/Header";
import {IconButton, Panel} from "../../common";
import {mdiPlus, mdiSecurity} from "@mdi/js";
import React from "react";
import {ComponentsLis} from "../component/ComponentsLis";
import {ModalProvider, useModal} from "../../../core/modal/ModalBase";
import {IfSuperAdmin} from "../../common/component/IfSuperAdmin";
import {EditComponent} from "../modal/EditComponent";
import {LANG} from "../../../lang";
import {ButtonStyle} from "../../common/component/IconButton";

// export function AdminRoot() : JSX.Element {
//     return (
//         <>
//             <HeaderAdmin/>
//             <div>
//                 <h1>Admin</h1>
//                 <Outlet/>
//             </div>
//         </>
//     )
// }

export function AdminRoot(): JSX.Element {
    return (
        <ModalProvider>
            <HeaderAdmin/>
            <main className="main-container">
                <div className={'main-panel'}>
                    <Outlet/>
                    <AdminPanel/>
                </div>
            </main>
        </ModalProvider>
    )
}

export function AdminPanel(): JSX.Element {

    const modal = useModal();

    return (

        <Panel title={LANG.components}
               icon={mdiSecurity}
               className="panel_shadow"
               headClassName={'pan_bold_border'}
               button={(
                   <IfSuperAdmin>
                       <IconButton
                           path={mdiPlus}
                           onClick={() => modal.openModal(<EditComponent/>)}
                           d-style={ButtonStyle.ROUND_UPC}
                           title={LANG.component_create}
                           title-place='right'
                           large
                           />
                   </IfSuperAdmin>
               )}
        >
            <ComponentsLis/>
        </Panel>
    )
}