import Styles from "../styles/component/TableActions.module.scss"
import { ToolTipContainer } from "./Utils";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import { extendClassName } from "../../../util/utilsHelper";


export type TableAction<T> = {
    type: 'link' | 'button',
    icon: string,
    tooltip: string,
    className?: string
    onClick?: (target: T) => void,
    to?: ((target: T) => string) | string
}

export type TableActionsProps<T> = {
    target: T,
    actions: TableAction<T>[]
}

export function TableActions<T>({ target, actions }: TableActionsProps<T>): JSX.Element {
    return (
        <div className={Styles.table__actions}>
            {
                actions.map((a: TableAction<T>, i) => {
                    return (
                        <ToolTipContainer className={Styles.action__tooltip} data-tooltip-content={a.tooltip} key={i}>
                            {a.type === 'button' ? (
                                <button className={extendClassName(a.className, Styles.action)} onClick={() => a.onClick && a.onClick(target)}>
                                    <Icon path={a.icon} />
                                </button>
                            ) : (
                                <Link to={typeof a.to === 'function' ? a.to(target) : a.to || '#'} className={extendClassName(Styles.action, a.className)}>
                                    <Icon path={a.icon} />
                                </Link>
                            )
                            }
                        </ToolTipContainer>
                    )
                })
            }
        </div>
    )
}
