import {ModalBase, ModalField} from "../../../core/modal/ModalBase";
import {Group, PostGroup} from "../../../declarations";
import React, {useCallback, useMemo} from "react";
import ModalStyle from "../../../core/modal/Modal.module.scss";
import {mdiPencilOutline, mdiPound} from "@mdi/js";
import {DEFAULT_EVENT_COLOR} from "../../../util/const";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

const validationName = (value: string): string | null => value.length > 40 ? null : value;

export default function CreateEditGroupModal<T extends Group>({baseGroup, onConfirm}: { baseGroup?: T, onConfirm?: (group: T) => void }): JSX.Element {
    const [isValid, setIsValid] = React.useState<boolean>(baseGroup !== undefined);

    const state = useMemo<PostGroup>(() => {
        if (baseGroup !== undefined) {
            return {
                name: baseGroup!.name,
                color: baseGroup!.color,
            }
        }
        return {
            name: "",
            color: DEFAULT_EVENT_COLOR,
        }
    }, [baseGroup]);

    const checkValidity = useCallback((): boolean => {
        // check other value
        if (state.name.length > 0) {
            setIsValid(true);
            return true;
        }
        // not valid
        setIsValid(false);
        return false;

    }, [state]);

    const onInputChange = useCallback((value: string, id: string): void => {
        state[id as keyof PostGroup] = value;
        checkValidity();
    }, [state, checkValidity]);

    const onCreate = (id: string): boolean => {
        if (id !== "create") return true;
        if (!checkValidity()) return false;

        if (onConfirm) {
            if (baseGroup) {
                onConfirm({
                    ...baseGroup,
                    ...state
                });
            }
            else {
                onConfirm({
                    ...state
                } as T);
            }

            return true;
        }

        CacheManager.createUpdateGroup(state, baseGroup?.id).then().catch((err) => {
            // todo: handle error
            console.error(err);
        });


        return true;
    }

    return (
        <ModalBase title={baseGroup ? LANG.group_edit : LANG.group_create}
                   buttons={[
                       {id: "create", label: baseGroup ? LANG.global_edit : LANG.global_create, active: isValid}
                   ]}
                   onButtonClick={onCreate}
                   className='modal-create-group'
        >
            <div className={ModalStyle.body_fields}>
                <ModalField
                    id="name"
                    label={LANG.group_create_modal_field_name}
                    type="text"
                    defaultValue={state.name}
                    svg={mdiPound}
                    placeholder={LANG.group_create_modal_field_name}
                    validation={validationName}
                    onChange={onInputChange}
                />
                <ModalField
                    id='color'
                    type='color'
                    label={LANG.global_color}
                    defaultValue={state.color}
                    svg={mdiPencilOutline}
                    onChange={onInputChange}
                />
            </div>
        </ModalBase>
    )
}