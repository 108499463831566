import Styles from '../styles/component/Utils.module.scss'
import {classNameProp, panelProp, TooltipContainerProps} from "../../../declarations";
import {extendClassName} from "../../../util/utilsHelper";
import Icon from "@mdi/react";
import React, {ForwardedRef, forwardRef, memo, Suspense} from "react";
import {mdiMagnify} from "@mdi/js";
import {TOOL_TIP_ID} from "../../../util/const";
import {Await} from "react-router-dom";
import {AwaitSuspensePromiseProps} from "../declarations";
import {LANG} from "../../../lang";

export function GlobalSpinner(props: classNameProp): JSX.Element {
    return (
        <div className={props.className + " " + Styles.loader_container}>
            <span className={Styles.loader}></span>
        </div>
    )
}

type SearchBarProps = {
    onChange: (value: string) => void,
    placeholder?: string,
    ignoreCase?: boolean,
    defaultValue?: string
} & classNameProp

const SearchBar_ = forwardRef((props: SearchBarProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    return (
        <div className={extendClassName(Styles.search_bar, props.className)}>
            <input ref={ref} type="text" defaultValue={props.defaultValue}
                   placeholder={props.placeholder ?? LANG.global_search}
                   onChange={(e) => props.onChange((props.ignoreCase ? e.target.value.toLowerCase() : e.target.value))}/>
            <Icon path={mdiMagnify} className={Styles.search_bar__svg}/>
        </div>
    )
});

export const SearchBar = memo(SearchBar_)

export function Panel(props: panelProp): JSX.Element {
    return (
        <div
            className={`${Styles.panel_container} ${props.borderTop ? Styles.border_top : ''} ${props.className || ''}`}>
            <div className={Styles.panel__head + (props.headClassName ? " " + props.headClassName : "")}>
                {props.icon && <Icon path={props.icon} className={Styles.panel__svg}/>}
                {props.title && <h2 className={Styles.panel__title}>{props.title}</h2>}
                {props.button && props.button}
            </div>
            <div
                className={extendClassName(Styles.panel__body, props.bodyClassName, props.bodyScrollable ? Styles.body_scrollable : undefined)}>
                {props.children}
            </div>
        </div>
    )
}

export function ToolTipContainer(props: TooltipContainerProps & classNameProp & { inline?: boolean }): JSX.Element {

    const propsWithoutChildren = {...props}
    delete propsWithoutChildren.children;
    delete propsWithoutChildren.inline;

    return (
        <div
            data-tooltip-id={TOOL_TIP_ID}
            role='tooltip'
            aria-label={'tooltip: ' + props['data-tooltip-content'] || ''}
            style={{display: props.inline ? 'inline-block' : 'block'}}
            {...propsWithoutChildren}
        >
            {props.children}
        </div>
    )
}

export function VCheckBox(props: { value: boolean } & classNameProp) {
    return (
        <div role='checkbox' aria-label='is select checkbox'
             aria-checked={props.value} className={extendClassName(Styles.checkbox, props.className)}>
            {/*<div className={Styles.checkbox__container}>*/}
            <div aria-hidden={true}
                 className={extendClassName(Styles.checkbox__check, props.value ? Styles.checked : undefined)}/>
            {/*</div>*/}
        </div>
    )
}

export function AwaitSuspensePromise<T>({promise, children, fallback}: AwaitSuspensePromiseProps<T>): JSX.Element {
    return (
        <Suspense fallback={fallback || <GlobalSpinner/>}>
            <Await resolve={promise}>
                {children}
            </Await>
        </Suspense>
    )
}