import React, { memo, Suspense, useMemo } from "react";
import { useStudentsFromEvent } from "../hook/useStudentsFromEvent";
import Styles from "../styles/EventComponents.module.scss";
import { mdiAccountAlert, mdiAccountCheck, mdiAccountLock, mdiAccountMinus, mdiAccountSchool, mdiCheck, mdiDownload, mdiLockOpen, mdiReload } from "@mdi/js";
import {
    ChangeEvent,
    GlobalSpinner,
    IconButton,
    SearchBar,
    Table,
    ToolTipContainer,
    useEventListener
} from "../../common";
import { Await, NavLink } from "react-router-dom";
import { EventGroupChangeEventData, StudentWithScanTime, UEvent } from "../../../declarations";
import { TableAction, TableActions } from "../../common/component/TableActions";
import { useModal } from "../../../core/modal/ModalBase";
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal";
import CacheManager from "../../../service/cacheManager";
import { JustifyAbsence } from "../modal/JustifyAbsence";
import { Permission } from "../../../util/pernission";
import { LANG } from "../../../lang";
import { ButtonStyle } from "../../common/component/IconButton";
import { IfAllow } from "../../common/component/IfAllow";
import { SCAN_TYPE } from "../../../util/const";
import Icon from "@mdi/react";

function getScanSatusByType(type?: number): string {
    if (type === undefined) return LANG.student_neutal
    switch (type) {
        case SCAN_TYPE.PRESENT:
            return LANG.student_present;
        case SCAN_TYPE.JUSTIFIED:
            return LANG.student_justify_absent;
        case SCAN_TYPE.ABSENT:
        default:
            return LANG.student_absent;
    }
}

function renderStudentTime(student: StudentWithScanTime): JSX.Element {
    return <span>{getScanSatusByType(student.scan?.type)}</span>
    // return <span> {new Date(student.scan.time * 1000).toLocaleDateString("fr-FR", {
    //     day: "numeric",
    //     month: "numeric",
    //     year: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    // })} </span>
}

function studentsScanToCSV(students: StudentWithScanTime[]): string {
    let csv = "id,firstname,lastname,status,time\n";
    for (const student of students) {
        let status = getScanSatusByType(student.scan?.type)
        let time = student.scan?.time ? (new Date(student.scan.time * 1000).toLocaleDateString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
        })) : "---";
        csv += `${student.id},${student.firstname},${student.lastname},${status},${time}\n`;
    }
    return csv;
}

const EventStudents = memo(({ event }: { event: UEvent }): JSX.Element => {
    const [studentsFilter, setStudentsFilter] = React.useState<string>("");
    const { students, refresh, allow_scans } = useStudentsFromEvent(event.id);
    const allowCreateScan = CacheManager.havePermission(Permission.CREATE_SCANS);
    const allowViewStudents = CacheManager.havePermission(Permission.VIEW_STUDENTS);
    const allowRemoveScan = CacheManager.havePermission(Permission.DELETE_SCANS);
    const isValidate = ((event.flags || 0) & 0b1) > 0;
    const isArchived = ((event.flags || 0) & 0b10) > 0;
    const modal = useModal()

    const downloadCSV = React.useCallback(async () => {
        const csv = studentsScanToCSV(await students);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "students.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [students]);

    useEventListener("eventGroupChange", (e: ChangeEvent<EventGroupChangeEventData>) => {
        if (e.data.eventID === event.id) refresh()
    })

    const renderStudentActions = React.useCallback((student: StudentWithScanTime) => {

        const isValidateOrArchive = isValidate || isArchived;
        const actions: TableAction<StudentWithScanTime>[] = []

        // if (allowViewStudents) {
        //     actions.push({
        //         type: "link",
        //         icon: mdiEye,
        //         tooltip: LANG.event_students_actions_view,
        //         to: `/students/${student.id}`
        //     });
        // }
        if (!isValidateOrArchive) {
            if (!(student.scan && student.scan.time)) {
                if (allowCreateScan) {
                    actions.push(
                        {
                            type: "button",
                            icon: mdiAccountCheck,
                            tooltip: LANG.event_students_actions_set_present,
                            className: Styles.icon_present,
                            onClick: () => CacheManager.addEventScan(event.id, student.id, SCAN_TYPE.PRESENT),
                        },
                        {
                            type: "button",
                            icon: mdiAccountMinus,
                            tooltip: LANG.event_students_actions_set_absent,
                            className: Styles.icon_absent,
                            onClick: () => CacheManager.addEventScan(event.id, student.id, SCAN_TYPE.ABSENT),
                        },
                        {
                            type: "button",
                            icon: mdiAccountAlert,
                            tooltip: LANG.event_students_actions_justify,
                            className: Styles.icon_justified,
                            onClick: () => {
                                modal.openModal(
                                    <JustifyAbsence student={student} eventID={event.id} />
                                )
                            }
                        }
                    )
                }
            } else if (allowRemoveScan) {
                actions.push({
                    type: "button",
                    icon: mdiAccountLock,
                    tooltip: LANG.event_students_actions_delete_presense,
                    onClick: () => {
                        modal.openModal(
                            <ConfirmationModal title={LANG.event_students_actions_delete_presense_title}
                                message={LANG.event_students_actions_delete_presense_body(student.firstname, student.lastname)}
                                onConfirm={() => CacheManager.removeEventScan(event.id, student.id)} />
                        )
                    }
                })
            }
        }

        return (
            <TableActions target={student} actions={actions} />
        )
    }, [isValidate, isArchived, allowRemoveScan, allowCreateScan, modal, event.id])

    const validateBtn = useMemo(() => {
        if (!isValidate && !isArchived) {
            return <IconButton
                path={mdiCheck}
                title={LANG.event_validate_button}
                onClick={() => {
                    modal.openModal(
                        <ConfirmationModal title={LANG.event_validate_modal_title} message={LANG.event_validate_modal_body}
                            onConfirm={() => { CacheManager.putEventValidate(event) }} />
                    )
                }}
                d-style={ButtonStyle.ROUND_UPC}
                title-inline
            />
        } else if (!isArchived) {
            return <IfAllow permission={Permission.MODERATOR} >
                <IconButton
                    path={mdiLockOpen}
                    title={LANG.event_unvalidate_button}
                    onClick={() => {
                        CacheManager.deleteEventValidete(event)
                    }}
                    d-style={ButtonStyle.ROUND_UPC}
                    title-inline
                />
            </IfAllow>
        }
        return undefined;
    }, [event, isArchived, isValidate, modal])

    return (
        <div className={Styles.students__container}>
            <div className={Styles.students__head}>
                <div className={Styles.students__head_title}>
                    <Icon path={mdiAccountSchool} size={1} />
                    <span>{LANG.students}</span>
                </div>

                <SearchBar className={Styles.students__head_search} onChange={setStudentsFilter} />
                <div className={Styles.students__head_left}>
                    <div className={Styles.students_counts}>
                        <Suspense>
                            <Await resolve={students}>
                                {(sts: StudentWithScanTime[]) => {
                                    const total = sts.length;
                                    // @ts-ignore why I cant add number and boolean ;( x + false = x , x + true = x + 1
                                    const abs = sts.reduce((p, v) => p + (v.scan !== undefined && v.scan.type !== SCAN_TYPE.PRESENT ? 1 : 0), 0);
                                    const prs = sts.reduce((p, v) => p + (v.scan?.type === SCAN_TYPE.PRESENT ? 1 : 0), 0);
                                    return (
                                        <>
                                            <ToolTipContainer data-tooltip-content={LANG.student_present} inline>
                                                <span className={Styles.sc_present}>{prs}</span>
                                            </ToolTipContainer>
                                            <ToolTipContainer data-tooltip-content={LANG.student_absent} inline>
                                                <span className={Styles.sc_abs}>{abs}</span>
                                            </ToolTipContainer>
                                            <ToolTipContainer data-tooltip-content={LANG.student_total} inline>
                                                <span className={Styles.sc_total}>{total}</span>
                                            </ToolTipContainer>
                                        </>
                                    )
                                }}
                            </Await>
                        </Suspense>
                    </div>
                    <IconButton
                        path={mdiReload}
                        title={LANG.global_refresh}
                        onClick={refresh}
                        d-style={ButtonStyle.ROUND_GRAY}
                        title-inline
                    />
                    <IconButton
                        path={mdiDownload}
                        title={LANG.event_students_tooltip_csv_download}
                        onClick={downloadCSV}
                        d-style={ButtonStyle.ROUND_GRAY}
                        title-inline
                    />
                    {validateBtn}
                </div>
            </div>
            <Suspense fallback={<GlobalSpinner />}>
                <Await resolve={students}>
                    {students => (
                        <Table<StudentWithScanTime> className={Styles.students__table}
                            filter={studentsFilter}
                            header={[
                                {
                                    key: "id",
                                    display: LANG.global_id,
                                    render: (st) => { return <NavLink to={'/students/' + st.id} > {st.id} </NavLink> }
                                },
                                { key: "firstname", display: LANG.event_students_colum_firstname },
                                { key: "lastname", display: LANG.event_students_colum_lastname },
                                {
                                    key: "scan",
                                    display: LANG.event_students_colum_scan,
                                    camBeSorted: false,
                                    canBeFiltered: false,
                                    render: renderStudentTime,
                                    disabled: !allow_scans
                                },
                                {
                                    key: "actions",
                                    display: LANG.global_actions,
                                    camBeSorted: false,
                                    canBeFiltered: false,
                                    render: renderStudentActions,
                                    disabled: !(allowCreateScan || allowViewStudents)
                                },
                            ]}
                            showHeader={true}
                            rowClassNameCallback={(e) => e.scan?.type !== undefined ?
                                e.scan.type === SCAN_TYPE.PRESENT ?
                                    Styles.row_scan_present : Styles.row_scan_absent : ""}
                            data={students}
                        />
                    )}
                </Await>
            </Suspense>
        </div>
    )
})

export { EventStudents };
