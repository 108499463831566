import {useAcYears} from "../hook/useAcYears";
import {Await, useParams} from "react-router-dom";
import {ACYear} from "../../../declarations";
import {AcYearEditLine} from "../component/AcYearEditLine";
import {mdiPlus, mdiReload} from "@mdi/js";
import {useModal} from "../../../core/modal/ModalBase";
import {EditAcYear} from "../modal/EditAcYear";
import {GlobalSpinner, IconButton} from "../../common";
import {Suspense} from "react";
import Styles from "../styles/Admin.module.scss";
import {ButtonStyle} from "../../common/component/IconButton";
import {LANG} from "../../../lang";

export function ViewEditACYear() {

    const modal = useModal();
    const {componentID} = useParams() as { componentID: string }

    const [acYears, refresh] = useAcYears(parseInt(componentID))

    return (
        <>
            <div className={Styles.component_users_btn}>
                <IconButton
                    onClick={refresh}
                    path={mdiReload}
                    d-style={ButtonStyle.ROUND_UPC}
                    title={LANG.global_refresh}
                    large
                    title-inline
                />
                <IconButton
                    onClick={() => modal.openModal(<EditAcYear componentID={parseInt(componentID)}/>)}
                    path={mdiPlus}
                    d-style={ButtonStyle.ROUND_UPC}
                    title={LANG.global_add}
                    large
                    title-inline
                />
            </div>
            <Suspense fallback={<GlobalSpinner/>}>
                <Await resolve={acYears}>{(acYears: ACYear[]) => {
                    return (
                        <div className={Styles.component_users_data}>
                            {acYears.map(ac => <AcYearEditLine key={ac.id} acYear={ac}/>)}
                        </div>
                    )
                }}
                </Await>
            </Suspense>
        </>
    )
}