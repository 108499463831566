// WIP - This is a singleton class that will be used to cache data from the backend
// It will be used to avoid making multiple requests to the backend for the same data
import { fetchRequest, REQUESTS } from "./apiHelper";
import {
    ACYear,
    APIAnswer,
    APIRequest,
    BodeParam,
    Group,
    PostGroup,
    Room,
    Scan,
    Student,
    StudentScanTypeCount,
    UComponent,
    UEvent,
    User,
    UserPermissions
} from "../declarations";
import { callChangeEvent } from "../feature/common/event/ChangeEvent";
import { Presence } from "../feature/student/student";
import { RequestError } from "../util/RequestError";
import { redirect } from "react-router-dom";
import { SCAN_TYPE } from "../util/const";

function reverseCardID(cardID: string): string {
    if (cardID == null) return '';
    let res = '';
    for (let i = 0; i < cardID.length; i += 2) {
        res = cardID[i] + cardID[i + 1] + res;
    }
    return res;
}
class CacheManager {

    // private static _instance?: CacheManager;

    private AC_YEAR?: ACYear;
    private SELF?: User;

    private TOKEN?: string;

    // public constructor() {
    // }

    /** @deprecated */
    public static getInstance(): CacheManager {
        // if (!CacheManager._instance) {
        //     CacheManager._instance = new CacheManager();
        // }
        //
        // return CacheManager._instance;
        return CacheManagerInstance;
    }

    /** @deprecated */
    getInstance() {
        return this;
    }


    public hasToken(): boolean {
        return this.TOKEN !== undefined;
    }

    public getToken(): string {
        if (this.TOKEN === undefined) {
            throw new Error("Token not set");
        }
        return this.TOKEN;
    }

    private async getProfile() {
        this.SELF = await this._easyGet(REQUESTS.GET_USER, { id: '@me' });
    }

    public async login(email: string, password: string): Promise<void> {
        const res = await fetchRequest(REQUESTS.LOGIN, undefined, { email, password });
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        this.TOKEN = res.data;

        // save token in local storage
        localStorage.setItem("token", this.TOKEN);

        await this.getProfile()
    }

    public async loginFromHalfToken(token: string) {
        const res = await fetchRequest(REQUESTS.CAS_GET_TOKEN, undefined, { token });
        if (res.error !== undefined || res.data === undefined) {
            // throw new Error(res.error || "Unknown error");
            return false;
        }
        this.TOKEN = res.data;
        // save token in local storage
        localStorage.setItem("token", this.TOKEN);

        await this.getProfile()
        return true;
    }

    private casLogout() {
        this._easyGet(REQUESTS.CAS_I_AM_LOG).then(
            (isLog: boolean) => {
                if (isLog) window.open('/auth/v1/cas/logout', '_blank')
            }
        ).catch()
    }

    public logout(): void {
        this.TOKEN = undefined;
        if (this.AC_YEAR !== undefined) {
            this._safeDestroy();
        }
        if (this.SELF !== undefined) {
            this.SELF = undefined;
            localStorage.removeItem("token");
        }
        this.casLogout()
    }

    public getAcYear(): ACYear {
        if (this.AC_YEAR === undefined) {
            throw new Error("Academic year not set");
        }
        return this.AC_YEAR;
    }

    // todo: detect cache
    public async fetchRequest<R>(request: APIRequest<R>, params?: { [key: string]: string | undefined }, body?: {
        [key: string]: BodeParam
    }): Promise<APIAnswer<R>> {
        return fetchRequest(request, params, body).catch((e) => {
            if (e instanceof RequestError) {
                console.log(e.statusCode, e.message, (e as RequestError).json)
                if (e.statusCode === 403) {
                    callChangeEvent("error", {
                        status: e.statusCode,
                        message: "Vous n'êtes pas autorisé à effectuer cette action"
                    }, 'create')
                }
                throw e;
                // return {
                //     data: undefined,
                //     error: (e as RequestError).json?.error || e.message,
                //     status: e.statusCode
                // }
            } else {
                throw e;
            }
        })
    }

    public getSelf(): User {
        if (this.SELF === undefined) {
            throw new Error("Not logged in can't get profile");
        }
        return this.SELF;
    }

    // same as getSelf but doesn't throw error if not logged in
    // returns undefined instead
    public getSelfUnsafe(): User | undefined {
        return this.SELF;
    }

    public unsafeGetAcYear(): ACYear | undefined {
        return this.AC_YEAR;
    }

    public loaderCheckPermission(permission: number, redirectTo?: string) {
        return () => {
            console.debug("loaderCheckPermission", permission, this.havePermission(permission), redirectTo || '/unauthorized')
            if (!this.havePermission(permission)) throw redirect(redirectTo || '/unauthorized');
            return null;
        }
    }

    public havePermission(permission: number): boolean {
        return ((this.unsafeGetAcYear()?.component?.permission || 0) & permission) > 0
    }

    public setAcYear(acYear: ACYear): void {
        if (this.AC_YEAR !== undefined) {
            this._safeDestroy();
        }
        this.AC_YEAR = acYear;
    }



    public tryInitFromLocalStorage(): boolean {
        let token = localStorage.getItem("token")
        if (token !== null) {
            this.TOKEN = token;
            this.getProfile().catch((e) => {
                console.error(e);
                this.logout();
            });
            return true;
        }
        return false;
    }

    private _safeDestroy(): void {
        this.AC_YEAR = undefined;
    }

    public async _easyGet<R>(request: APIRequest<R>, params?: { [key: string]: string | undefined }, body?: {
        [key: string]: BodeParam
    }): Promise<R> {
        const res = await this.fetchRequest(request, params, body);
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        return res.data;
    }

    public async getGroupStudents(groupID: string): Promise<Student[]> {
        return this._easyGet(REQUESTS.GET_GROUP_STUDENTS, { id: groupID });
    }

    public async getGroupStudentsWithScan(groupID: string): Promise<StudentScanTypeCount[]> {
        return this._easyGet(REQUESTS.GET_GROUP_STUDENTS_WITH_SCAN_COUNT, { id: groupID });
    }

    public async justifyAbsence(eventID: number, studentID: number, justification: string): Promise<void> {
        let res = await this.fetchRequest(REQUESTS.POST_EVENT_SCAN, { id: eventID.toString() }, {
            student_id: studentID,
            reason: justification,
            type: SCAN_TYPE.JUSTIFIED,
        });
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        await callChangeEvent('studentPresenceChange', {
            event_id: eventID,
            student_id: studentID,
            reason: justification,
            type: SCAN_TYPE.JUSTIFIED,
            time: new Date().getTime() / 1000
        }, "change");
    }

    public async addEventScan(eventID: number, studentID: number, type: number = 0): Promise<void> {
        let res = await this.fetchRequest(REQUESTS.POST_EVENT_SCAN, { id: eventID.toString() }, {
            student_id: studentID,
            type: type,
        });
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        await callChangeEvent('studentPresenceChange', {
            event_id: eventID,
            student_id: studentID,
            type: type,
            time: new Date().getTime() / 1000
        }, "change");
    }

    public async removeEventScan(eventID: number, studentID: number): Promise<void> {
        let res = await this.fetchRequest(REQUESTS.DELETE_EVENT_SCAN, { event_id: eventID.toString(), student_id: studentID.toString() });
        if (res.error !== undefined) {
            throw new Error(res.error || "Unknown error");
        }
        await callChangeEvent('studentPresenceChange', {
            event_id: eventID,
            student_id: studentID,
            justified_absence: false,
            time: undefined,
        }, "change");
    }

    public async addEventScanFromCard(eventID: number, cardID: string): Promise<Student> {
        console.debug(reverseCardID(cardID))
        const res = await this._easyGet(REQUESTS.POST_EVENT_SCAN, { id: eventID.toString() }, { card_id: reverseCardID(cardID) })
        await callChangeEvent('studentPresenceChange', {
            event_id: eventID,
            student_id: res.id,
            justified_absence: false,
            time: new Date().getTime() / 1000
        }, "change");
        return res;
    }

    public async getEventScans(eventID: number): Promise<Scan[]> {
        return this._easyGet(REQUESTS.GET_EVENT_SCANS, { id: eventID.toString() });
    }

    public async getEventStudents(eventID: number): Promise<Student[]> {
        return this._easyGet(REQUESTS.GET_EVENT_STUDENTS, { id: eventID.toString() });
    }

    public async getEventGroups(groupID: string): Promise<Group[]> {
        return this._easyGet(REQUESTS.GET_EVENT_GROUPS, { id: groupID });
    }

    public async getEvent(id: string): Promise<UEvent> {
        return this._easyGet(REQUESTS.GET_EVENT, { id });
    }

    public async getGroups(componentID?: number): Promise<Group[]> {
        return this._easyGet(REQUESTS.GET_AC_YEAR_GROUPS, { id: (componentID || this.getAcYear().id).toString() })
    }

    public async getPresences(studentID: number): Promise<Presence[]> {
        return this._easyGet(REQUESTS.GET_STUDENT_PRESENCES, {
            id: this.getAcYear().id.toString(),
            student_id: studentID.toString()
        })
    }

    public async getStudent(studentID: number): Promise<Student> {
        return this._easyGet(REQUESTS.GET_STUDENT, {
            id: this.getAcYear().id.toString(),
            student_id: studentID.toString()
        })
    }

    public async getStudentGroups(studentID: number): Promise<Group[]> {
        return this._easyGet(REQUESTS.GET_STUDENT_GROUPS, {
            id: this.getAcYear().id.toString(),
            student_id: studentID.toString()
        })
    }

    public async getGroup(id: string): Promise<Group> {
        return this._easyGet(REQUESTS.GET_GROUP, { id });
    }

    public async createUpdateGroup(group: PostGroup, updateID?: number): Promise<Group> {
        let res: APIAnswer<Group> = updateID ? await this.fetchRequest(REQUESTS.PATCH_GROUP, { id: updateID.toString() }, {
            name: group.name,
            color: group.color,
        }) : await this.fetchRequest(REQUESTS.POST_GROUP, { id: this.getAcYear().id.toString() }, {
            name: group.name,
            color: group.color,
        });

        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        const newGroup: Group = res.data;

        await callChangeEvent('groupChange', newGroup, updateID ? "change" : "create");
        return newGroup;
    }

    public async updateEvent(event: UEvent): Promise<UEvent> {
        const res = await this.fetchRequest(REQUESTS.PATCH_EVENT, { id: event.id.toString() }, {
            name: event.name,
            room: event.room,
            start_time: event.start_time,
            end_time: event.end_time,
            color: event.color,
        });
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }

        await callChangeEvent('eventChange', res.data, "change");
        return res.data;
    }

    public async duplicateEvent(baseEventID: number, event: UEvent): Promise<UEvent> {
        event.id = await this._easyGet(REQUESTS.POST_EVENT_DUPLICATE, {
            id: baseEventID.toString()
        }, {
            name: event.name,
            room: event.room,
            start_time: event.start_time,
            end_time: event.end_time,
            color: event.color,
        })
        await callChangeEvent('eventChange', event, "create")
        return event
    }

    public async createEvent(event: UEvent): Promise<UEvent> {
        const res = await this.fetchRequest(REQUESTS.POST_EVENT, { id: this.getAcYear().id.toString() }, {
            name: event.name,
            room: event.room,
            start_time: event.start_time,
            end_time: event.end_time,
            color: event.color,
        });
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }
        event.id = res.data;

        await callChangeEvent('eventChange', event, "create");
        return event;
    }

    public async GetStudents(limit?: number, after?: number, search?: string): Promise<Student[]> {
        return this._easyGet(REQUESTS.GET_STUDENTS, {
            id: this.getAcYear().id.toString(),
            limit: limit?.toString(),
            after: after?.toString(),
            search: search
        })
    }

    public async RemoveGroupFromEvent(eventID: number, groupID: number): Promise<void> {
        const res = await this.fetchRequest(REQUESTS.DELETE_EVENT_GROUP, {
            id: eventID.toString(),
            group_id: groupID.toString()
        })
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }

        await callChangeEvent('eventGroupChange', {
            eventID: eventID,
            group: {
                id: groupID,
                ac_year_id: this.getAcYear().id,
                component_id: this.getAcYear().component_id
            }
        }, "delete")
    }

    public async AddGroupToEvent(eventID: number, groupID: number): Promise<Group> {
        const res = await this.fetchRequest(REQUESTS.PUT_EVENT_GROUP, {
            id: eventID.toString(),
            group_id: groupID.toString()
        })
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }

        await callChangeEvent('eventGroupChange', {
            eventID: eventID,
            group: res.data
        }, "create")

        return res.data;
    }

    public async RemoveStudentFromGroup(groupID: number, studentID: number): Promise<number> {
        const res = await this.fetchRequest(REQUESTS.DELETE_GROUP_STUDENT, {
            id: groupID.toString(),
            student_id: studentID.toString()
        })
        if (res.error !== undefined || res.data === undefined) {
            throw new Error(res.error || "Unknown error");
        }

        await callChangeEvent('groupStudentChange', {
            groupID: groupID,
            studentIDs: [studentID]
        }, "delete")

        return studentID;
    }

    public async addStudentsToGroup(groupID: number, students: number[]): Promise<number[]> {
        if (students.length === 0) {
            return [];
        }
        // if less than 5 students, we can just add them all at once
        if (students.length < 5) {
            let res = await Promise.all(
                students.map(value => this.fetchRequest(REQUESTS.PUT_GROUP_STUDENT, {
                    id: groupID.toString(),
                    student_id: value.toString()
                }))
            )
            // let errors = res.filter(value => value.error !== undefined);
            // if (errors.length > 0) {
            //     throw new Error(errors[0].error||"Unknown error");
            // }
            const studentIDs = res.filter(value => value.error === undefined && value.data !== undefined).map(value => value.data ?? 0);
            await callChangeEvent('groupStudentChange', {
                groupID: groupID,
                studentIDs: studentIDs
            }, "create")
            return studentIDs
        } else {
            // if more than 5 students, we need to do it in batches
            let res = await this.fetchRequest(REQUESTS.POST_GROUP_STUDENTS, { id: groupID.toString() }, { students: students })
            if (res.error !== undefined || res.data === undefined) {
                throw new Error(res.error || "Unknown error");
            }
            await callChangeEvent('groupStudentChange', {
                groupID: groupID,
                studentIDs: res.data
            }, "create")
            return res.data;
        }
    }

    public async getComponents(): Promise<UComponent[]> {

        const res = await fetchRequest(REQUESTS.GET_USERS_COMPONENTS, { id: '@me' });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }
        return res.data;
    }


    public async getComponent(id: number): Promise<UComponent> {
        const res = await fetchRequest(REQUESTS.GET_COMPONENT, { id: id.toString() });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }
        return res.data;
    }

    public async updateAcYear(acYear: ACYear): Promise<ACYear> {
        const res = await fetchRequest(REQUESTS.PATCH_AC_YEAR, { id: acYear.id.toString() }, {
            name: acYear.name,
            year_start: acYear.year_start,
            year_end: acYear.year_end,
        });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }

        await callChangeEvent('acYearChange', res.data, "change")

        return res.data;
    }

    public async createAcYear(componentID: number, name: string, yearStart: number, yearEnd: number): Promise<ACYear> {
        const ac = await this._easyGet(REQUESTS.POST_AC_YEAR, { id: componentID.toString() }, {
            name: name,
            year_start: yearStart,
            year_end: yearEnd
        })

        await callChangeEvent('acYearChange', ac, "create")
        return ac;
    }

    public async createComponent(name: string): Promise<UComponent> {
        const id = await this._easyGet(REQUESTS.POST_COMPONENT, {}, {
            name: name
        })
        const component: UComponent = {
            id: id,
            name: name
        }
        await callChangeEvent('componentChange', component, "create")
        return component;
    }

    public async updateComponent(component: UComponent): Promise<UComponent> {
        const res = await fetchRequest(REQUESTS.PATCH_COMPONENT, { id: component.id.toString() }, {
            name: component.name
        });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }

        await callChangeEvent('componentChange', res.data, "change")

        if (this.AC_YEAR?.component_id === component.id) {
            this.AC_YEAR.component = component;
        }

        return res.data;
    }

    public async getAcYears(componentID: number) {
        const res = await fetchRequest(REQUESTS.GET_COMPONENTS_AC_YEARS, { id: componentID.toString() });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }
        return res.data;
    }

    public async archiveAcYear(acYearID: number) {
        const acYear = await this._easyGet(REQUESTS.PUT_ARCHIVED_AC_YEAR, { id: acYearID.toString() });
        await callChangeEvent('acYearChange', acYear, "change")
    }

    public async getUsersPermissions(componentID: number): Promise<UserPermissions[]> {
        return await this._easyGet(REQUESTS.GET_USERS_PERMISSIONS, { id: componentID.toString() });
    }

    public async removeUserAccess(componentID: number, userID: number): Promise<void> {
        await this._easyGet(REQUESTS.DELETE_USER_PERMISSIONS, { id: componentID.toString(), user_id: userID.toString() });
        await callChangeEvent('userPermissionsChange', { component_id: componentID, id: userID, permission: 0 }, "delete")
    }

    public async updateUserAccess(user: UserPermissions): Promise<void> {
        const res = await this._easyGet(REQUESTS.PUT_USER_PERMISSIONS, {
            id: user.component_id.toString(),
            user_id: user.id.toString()
        }, { permission: user.permission });
        res.username = user.username;
        await callChangeEvent('userPermissionsChange', res, "change")
    }

    public async addUserAccessFromEmail(componentID: number, email: string): Promise<UserPermissions> {
        const res = await this._easyGet(REQUESTS.POST_USER_PERMISSIONS_BY_EMAIL, { id: componentID.toString() }, { email: email });
        await callChangeEvent('userPermissionsChange', res, "create")
        return res;
    }

    public async registerCAS(token: string, email: string): Promise<void> {
        await this._easyGet(REQUESTS.CAS_REGISTER, {}, {
            token, email
        })
    }

    public async updateStudentCardID(studentId: number, cardId: string, isLE?: boolean) {
        return await this._easyGet(REQUESTS.PATCH_STUDENT_CARD, { id: studentId.toString() }, { card_id: isLE ? reverseCardID(cardId) : cardId });
    }

    public async putEventValidate(event: number | UEvent) {
        const id = typeof event === "number" ? event : event.id;
        const res = await fetchRequest(REQUESTS.PUT_EVENT_VALIDATE, { id: id.toString() })
        if (res.status >= 200 && res.status <= 299 && typeof event !== "number") {
            const newvent = { ...event };
            newvent.flags = (newvent.flags || 0) | 0b1;
            await callChangeEvent('eventChange', newvent, 'change');
        }
    }

    public async deleteEventValidete(event: number | UEvent) {
        const id = typeof event === "number" ? event : event.id;
        const res = await fetchRequest(REQUESTS.DELETE_EVENT_VALIDATE, { id: id.toString() })
        if (res.status >= 200 && res.status <= 299 && typeof event !== "number") {
            const newvent = { ...event };
            newvent.flags = (newvent.flags || 0) & (~0b1);
            await callChangeEvent('eventChange', newvent, 'change');
        }
    }

    public async archiveEvent(event: UEvent): Promise<void> {
        await this._easyGet(REQUESTS.PUT_EVENT_ARCHIVATE, { id: event.id.toString() });
        const newevent = { ...event };
        newevent.flags = (newevent.flags || 0) | 0b10;
        await callChangeEvent('eventChange', newevent, 'change');
    }

    public async getRooms(componentID: number): Promise<Room[]> {
        return this._easyGet(REQUESTS.GET_COMPONENT_ROOMS, { id: componentID.toString() })
    }

    public async postRooms(componentID: number, name: string): Promise<Room> {
        return this._easyGet(REQUESTS.POST_COMPONENT_ROOM, { id: componentID.toString() }, {
            name: name
        })
    }

    public async deleteRoom(componentID: number, roomID: number): Promise<void> {
        await this._easyGet(REQUESTS.DELETE_COMPONENT_ROOM, { id: componentID.toString(), room_id: roomID.toString() })
    }


}

const CacheManagerInstance = new CacheManager();
export default CacheManagerInstance;
