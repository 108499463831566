import CacheManager from "../../../service/cacheManager";
import { useParams } from "react-router-dom";
import { Group, UEvent } from "../../../declarations";
import { useModal } from "../../../core/modal/ModalBase";
import { GlobalSpinner, Panel, ToolTipContainer } from "../../common";
import { isSameDayNumber } from "../../../util/dateHelper";
import Styles from "../styles/EventsView.module.scss";
import { Suspense, useCallback } from "react";
import { DEFAULT_EVENT_COLOR } from "../../../util/const";
import Icon from "@mdi/react";
import {
    mdiAccountMultipleOutline,
    mdiPlus
} from "@mdi/js";
import { extendClassName } from "../../../util/utilsHelper";
import { EventStudents } from "../component";
import { SelectGroup, SmartGroupLists } from "../../group";
import { GroupCore } from "../../group/component";
import { IfAllow } from "../../common/component/IfAllow";
import { Permission } from "../../../util/pernission";
import { LANG } from "../../../lang";
import { useUEvent } from "../hook/useUEvent";
import { useRediractIfError } from "../../common/hook/useRedirectIfError";
import { EventDetailsActions } from "../component/EventDetailsActions";

function EventDetails(): JSX.Element {
    const { id } = useParams();
    const { event, isError } = useUEvent(parseInt(id || "NaN"))
    useRediractIfError(isError, "/events")
    const modal = useModal();
    const params: any = useParams()


    let getStringDate = (event: UEvent) => {
        let start = new Date(event.start_time * 1000).toLocaleDateString("fr-FR", {
            weekday: "long",
            day: "numeric",
            month: "long",
            hour: "2-digit",
            minute: "2-digit",
        });
        let end = undefined;
        if (isSameDayNumber(event.start_time, event.end_time)) {
            end = new Date(event.end_time * 1000).toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit"
            });
        } else {
            end = new Date(event.end_time * 1000).toLocaleDateString("fr-FR", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric"
            });
        }

        return start + " - " + end;
    }
    const addGroup = useCallback((group: Group) => {
        CacheManager.AddGroupToEvent(parseInt(params.id), group.id)
    }, [params])

    const deleteGroup = useCallback((group: Group) => {
        CacheManager.RemoveGroupFromEvent(parseInt(params.id), group.id)
    }, [params])

    if (event === undefined) {
        return (
            <div className={Styles.event_view}>
                <GlobalSpinner />
            </div>
        )
    }

    const isArchivedOrValidate = ((event.flags || 0) & 0b11) > 0;

    return (
        <div className={Styles.event_view}>
            {/*head*/}
            <div className={Styles.view_pt + " panel_content_head"}
                style={{ borderColor: event.color || DEFAULT_EVENT_COLOR }}>
                <div className='head__title'>
                    <h2>{event.name}</h2>
                    <span>{getStringDate(event)}</span>
                </div>
                <EventDetailsActions event={event} />
            </div>

            {/*STUDENTS*/}
            <div className={extendClassName(Styles.students_container, Styles.view_center)}>
                <EventStudents event={event} />
            </div>

            {/*GROUPS */}
            <div className={Styles.view_left}></div>
            <Panel title={LANG.groups}
                icon={mdiAccountMultipleOutline}
                className={Styles.view_tl}
                borderTop={true}
                button={
                    <IfAllow permission={Permission.CREATE_EVENT} overwrite={isArchivedOrValidate ? false : undefined}>
                        <ToolTipContainer data-tooltip-content={LANG.event_add_group} data-tooltip-place='right'>
                            <button className={'pam__btn'}
                                onClick={(e) => modal.openModal(<SelectGroup callback={addGroup} />, {
                                    position: 'relative',
                                    relativeTo: e.currentTarget,
                                    relativeDirection: 'bottom-left'
                                })}><Icon
                                    path={mdiPlus} /></button>
                        </ToolTipContainer>
                    </IfAllow>
                }
            >
                <SmartGroupLists eventID={parseInt(params.id)} onDelete={!isArchivedOrValidate && CacheManager.havePermission(Permission.CREATE_EVENT) ? deleteGroup : undefined} GroupComponent={GroupCore}
                    confirmationMessage={LANG.event_remove_group_confirmation} />
            </Panel>

            {/*USERS */}
            <Panel title={LANG.users} icon={mdiAccountMultipleOutline} className={Styles.view_bl} borderTop={true}>
                <Suspense fallback={<GlobalSpinner />}>

                </Suspense>
            </Panel>
        </div>
    )
}

export {
    EventDetails,
}
