import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../core/modal/ModalBase";

export function useRediractIfError(
    isError: boolean | string | null | undefined,
    path?: string,
    showModal?: boolean
) {
    const navigate = useNavigate()
    const modal = useModal()

    useEffect(() => {
        // not null or undfined or true or not null string
        if (isError || isError === "") {
            navigate(path || "/");
            if (showModal) {
                modal.openErrorModal(isError)
            }
        }
    }, [isError, modal, navigate, path, showModal])
}
