import { createBrowserRouter, Outlet, redirect, RouterProvider } from "react-router-dom";
import { Header, PageNotFound, useEventListener } from "./feature/common";
import React, { useCallback } from "react";
import { EventDetails, EventsView, NoEvent } from "./feature/planning";
import CacheManager from "./service/cacheManager";
import { ModalBase, ModalProvider, useModal } from "./core/modal/ModalBase";
import { GroupDetails, GroupsView, NoGroup } from "./feature/group";
import { NoStudent, StudentDetails } from "./feature/student";
import { StudentView } from "./feature/student/page/StudentView";
import { ChangeEventMap } from "./declarations";
import { AdminRoot } from "./feature/admin/page/AdminRoot";
import { ComponentsEdit } from "./feature/admin/page/ComponentsEdit";
import { ViewEditACYear } from "./feature/admin/page/ViewEditAcYear";
import { EditUsers } from "./feature/admin/page/EditUsers";
import { UploaderCSV } from "./feature/admin/page/UploaderCSV";
import { RegisterCas } from "./feature/auth/page/RegisterCas";
import { Test } from "./feature/testwip/Test";
import { EventScan } from "./feature/planning/page/EventScan";
import { Unauthorized } from "./feature/common/component/Unauthorized";
import { Permission } from "./util/pernission";
import { LANG } from "./lang";
import { HomePage } from "./feature/common/page/HomePage";
import { LoginLayout, LoginSelectType } from "./feature/auth/page/Login";
import { LoginPassword } from "./feature/auth/page/LoginPassword";
import { LoginSelectAcYear } from "./feature/auth/page/LoginSelectAcYear";

function ErrorController({ children }: { children: React.ReactNode }) {
    const modal = useModal();

    const callback = useCallback((e: ChangeEventMap["error"]) => {
        if (e.data.status === 403) {
            modal.openModal(<ModalBase title={LANG.global_error}> {e.data.error} </ModalBase>);
        }
    }, [modal]);

    useEventListener('error', callback);
    return (
        <>{children}</>
    )
}

function Root() {
    return (
        <ModalProvider>
            <ErrorController>
                <Header />
                <main className="main-container">
                    <Outlet />
                </main>
            </ErrorController>
        </ModalProvider>
    )
}

export default function BackofficeRouter(): JSX.Element {

    const router = createBrowserRouter([
        {
            path: "test",
            element: <Test />
        },
        {
            path: "",
            loader: async (args) => {
                const url = new URL(args.request.url)
                if (url.searchParams.has('log_token')) {
                    if (url.searchParams.has('need_register') && url.searchParams.get('need_register') === "true") {
                        throw redirect("/register-cas?log_token="
                            + encodeURIComponent(url.searchParams.get('log_token') || '')
                            + "&id="
                            + encodeURIComponent(url.searchParams.get('id') || '')
                        )
                    } else {
                        try {
                            await CacheManager.loginFromHalfToken(url.searchParams.get('log_token') || '')
                        } catch (e) { } finally {
                            throw redirect("/f-auth")
                        }
                    }
                }

                if (CacheManager.unsafeGetAcYear() === undefined) {
                    CacheManager.tryInitFromLocalStorage()
                    throw redirect("/f-auth")
                }
                return null;
            },
            element: (
                <Root />
            ),
            errorElement: (
                <ModalProvider>
                    <Header />
                    <main className="main-container">
                        <PageNotFound />
                    </main>
                </ModalProvider>
            ),
            children: [
                {
                    path: "",
                    element: (
                        <HomePage />
                    )
                },
                {
                    path: "unauthorized",
                    element: Unauthorized
                },
                {
                    path: "events",
                    element: (
                        <EventsView />
                    ),
                    children: [
                        {
                            path: "",
                            element: NoEvent
                        },
                        {
                            path: "unauthorized",
                            element: Unauthorized
                        },
                        {
                            path: ":id",
                            loader: CacheManager.loaderCheckPermission(Permission.VIEW_EVENTS),
                            element: <EventDetails />
                        },
                        {
                            path: ":id/scan",
                            element: <EventScan />
                        }
                    ]
                },
                {
                    path: "groups",
                    loader: CacheManager.loaderCheckPermission(Permission.VIEW_GROUP),
                    element: (
                        <GroupsView />
                    ),
                    children: [
                        {
                            path: "",
                            element: NoGroup
                        },
                        {
                            path: ":id",
                            loader: CacheManager.loaderCheckPermission(Permission.VIEW_GROUP_STUDENTS, "/groups/unauthorized"),
                            element: (
                                <GroupDetails />
                            )
                        },
                        {
                            path: "unauthorized",
                            element: Unauthorized
                        },
                    ]
                },
                {
                    path: "students",
                    loader: CacheManager.loaderCheckPermission(Permission.VIEW_STUDENTS),
                    element: (
                        <StudentView />
                    ),
                    children: [
                        {
                            path: "",
                            element: NoStudent

                        },
                        {
                            path: ":id",
                            element: (
                                <StudentDetails />
                            )
                        }
                    ]
                },
                // {
                //     path: "/debug",
                //     element: (
                //         <ApiDebug/>
                //     )
                // }
            ]
        },
        {
            path: "f-auth",
            element: <LoginLayout />,
            children: [
                {
                    path: "",
                    element: <LoginSelectType />
                },
                {
                    path: "local",
                    element: <LoginPassword />
                },
                {
                    path: "select-acyear",
                    element: <LoginSelectAcYear />
                }
            ]
        },
        {
            path: "register-cas",
            element: (
                <RegisterCas />
            )
        },
        {
            path: "admin",
            loader: () => {
                if (CacheManager.getSelfUnsafe() === undefined) {
                    CacheManager.tryInitFromLocalStorage()
                    throw redirect("/f-auth")
                }
                return null;
            },
            element: (
                <AdminRoot />
            ),
            children: [
                {
                    path: "/admin",
                    element: (
                        <div>TODO</div>
                    )
                },
                {
                    path: "/admin/:componentID",
                    // loader: async ({params}) => {
                    //     let id = (params as { componentID: string}).componentID;
                    //     console.log(id)
                    //     if (id === undefined || id === null || isNaN(parseInt(id))) {
                    //         throw redirect("/ac-year")
                    //     }
                    //
                    //     const res = await CacheManager.getInstance().getComponent(parseInt(id))
                    //     console.log(res)
                    //     if (res === undefined || ((res.permission||0) & Permission.ADMIN) === 0) {
                    //         throw redirect("/ac-year")
                    //     }
                    //
                    //     return null;
                    // },
                    element: (
                        <ComponentsEdit />
                    ),
                    children: [
                        {
                            path: "/admin/:componentID",
                            element: (
                                <ViewEditACYear />
                            )

                        },
                        {
                            path: "/admin/:componentID/ac-year",
                            element: (
                                <ViewEditACYear />
                            )
                        },
                        {
                            path: "/admin/:componentID/users",
                            element: (
                                <EditUsers />
                            )
                        },
                        {
                            path: "/admin/:componentID/upload",
                            element: (
                                <UploaderCSV />
                            )
                        }
                    ]
                }
            ]
        }
    ])

    return (
        <RouterProvider router={router} />
    )
};
