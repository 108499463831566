import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACYear, UComponent } from "../../../declarations";
import { LANG } from "../../../lang";
import { fetchRequest, REQUESTS } from "../../../service/apiHelper";
import CacheManagerInstance from "../../../service/cacheManager";
import { Permission } from "../../../util/pernission";
import default_user from "../../../res/default_user.png";
import Styles from "../style/Login.module.scss"

export function LoginSelectAcYear() {
    const [acYears, setAcYears] = useState<ACYear[]>([]);
    const [acYear, setAcYear] = useState<ACYear | undefined>(undefined);
    // const [error, setError] = useState<string | undefined>(undefined);
    const [components, setComponents] = useState<UComponent[]>([]);
    const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false);

    const navigate = useNavigate();

    const fetchAcYears = useCallback(async (componentID: string) => {
        const res = await fetchRequest(REQUESTS.GET_COMPONENTS_AC_YEARS, { id: componentID });
        if (res.error !== undefined || res.data === undefined) {
            throw res.error;
        }
        const component = components?.find(v => v.id === parseInt(componentID));
        if (component === undefined || ((component.permission || 0) & Permission.ADMIN) === 0) {
            setAcYears(res.data);
        } else {
            setAcYears(
                [
                    ...res.data,
                    {
                        id: -1,
                        name: "Admin",
                        component: component,
                        component_id: component.id,
                        archived: false,
                        year_start: 0,
                        year_end: 0,
                        permission: component.permission
                    }
                ]
            )
        }
    }, [components])


    const _setAcYear = (selectedItem: ACYear | undefined) => {
        if (components === undefined) return;

        if (selectedItem === undefined) {
            setAcYear(undefined);
            return;
        }
        const year = { ...selectedItem, component: components.find(v => v.id === selectedItem.component_id) };
        setAcYear(year);
    }

    useEffect(() => {
        if (!CacheManagerInstance.hasToken()) {
            navigate("/f-auth");
        }
    }, [navigate])

    useEffect(() => {
        if (!CacheManagerInstance.hasToken() || isComponentLoad) return;
        setIsComponentLoad(true);
        // getComponents
        CacheManagerInstance.getComponents().then((components) => {
            setComponents(components);
        }).catch((e) => {
            // setError(e.message)
        });
    }, [fetchAcYears, isComponentLoad])

    useEffect(() => {
        if (components.length > 0) {
            fetchAcYears(components[0].id.toString())
        }
    }, [components, fetchAcYears])

    const logout = () => {
        CacheManagerInstance.logout();
        setComponents([]);
        navigate("/f-auth");

    }

    return (
        <div className={Styles.ac_year}>
            <div className={Styles.ac_year__user}>
                <img src={default_user} alt="user profile" className={Styles.ac_year_profile} />
                <span>{CacheManagerInstance.getSelfUnsafe()?.username || ""}</span>
                <button className={Styles.notme} onClick={logout}>{LANG.auth_not_me}</button>
            </div>
            {/*<div className={Styles.ac_year__select}>*/}
            {(components.length === 0) &&
                <div className={Styles.no_access_error}>
                    {LANG.auth_no_access.map((value, index) => <p key={index}>{value}</p>)}
                </div>
            }
            {
                components.length > 0 &&
                <>
                    <select
                        onChange={async (e) => {
                            setAcYear(undefined)
                            await fetchAcYears(e.target.value)
                        }}
                    >
                        {components.map((component) => {
                            return <option value={component.id} key={component.id}>{component.name}</option>
                        })}
                    </select>

                    <select
                        onChange={async (e) => {
                            _setAcYear(acYears.find((acYear) => acYear.id === parseInt(e.target.value)))
                        }}
                        defaultValue={undefined}
                    >
                        <option value={undefined} key={undefined}>{LANG.auth_select_label_option}</option>
                        {acYears.map((acYear) => {
                            return <option value={acYear.id}
                                key={acYear.id}>{acYear.archived ? `${acYear.name} - (${LANG.ac_year_archived})` : acYear.name}</option>
                        })}
                    </select>

                    <button onClick={() => {
                        if (acYear === undefined) {
                            return;
                        }
                        CacheManagerInstance.setAcYear(acYear);
                        if (acYear.id === -1) navigate("/admin/" + acYear.component_id); else navigate("/");
                    }}
                        disabled={acYear === undefined}
                    >{LANG.auth_continue}
                    </button>
                </>
            }
        </div>
    )
}
