import {ChangeEventMap, CustomEventActionType, CustomEventMap} from "../../../declarations";

class ChangeEvent<T> extends Event {
    readonly data: T;
    readonly action: CustomEventActionType

    constructor(data: T, action: CustomEventActionType, name: string) {
        super(name, {
            bubbles: false,
            cancelable: false,
            composed: false,
        });
        this.data = data;
        this.action = action;
    }
}

function changeEventFactory
<
    K extends keyof CustomEventMap,
    T = ChangeEventMap[K] extends ChangeEvent<infer T> ? T : never
>
(name: K): (data: T, action: CustomEventActionType) => Promise<void> {
    return async (data: T, action: CustomEventActionType) => {
        window.dispatchEvent(new ChangeEvent<T>(data, action, name));
    }
}

async function callChangeEvent
<
    K extends keyof CustomEventMap,
    T = ChangeEventMap[K] extends ChangeEvent<infer T> ? T : never
>
(name: K, data: T, action: CustomEventActionType) {
    window.dispatchEvent(new ChangeEvent<T>(data, action, name));
}

export {
    ChangeEvent,
    changeEventFactory,
    callChangeEvent
}