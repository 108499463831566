import { Await, useParams } from "react-router-dom";
import { GlobalSpinner, IconButton, PageNotFound } from "../../common";
import { useStudentPresences } from "../hook/useStudentPresences";
import { Suspense, useCallback, useState } from "react";
import { Presence } from "../student";
import Styles from '../style/page/StudentDetails.module.scss'
import { StudentPresences } from "../component/StudentPresences";
import { extendClassName } from "../../../util/utilsHelper";
import { GroupLists, useGroups } from "../../group";
import { Group } from "../../../declarations";
import CacheManager from "../../../service/cacheManager";
import { useModal } from "../../../core/modal/ModalBase";
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal";
import { Permission } from "../../../util/pernission";
import { IfAllow } from "../../common/component/IfAllow";
import { mdiCreditCardSettings, mdiCreditCardSettingsOutline } from "@mdi/js";
import { ButtonStyle } from "../../common/component/IconButton";
import { EditCardID } from "../modal/EditCardID";
import { LANG } from "../../../lang";
import { SCAN_TYPE } from "../../../util/const";

export enum Filter {
    ALL,
    PRESENT,
    ABSENT,
    JUSTIFIED_ABSENCE,
    NEUTRE,
    GROUP
}

export function StudentDetails(): JSX.Element {
    const [filter, setFilter] = useState<Filter>(Filter.ALL)
    const { id } = useParams() as { id: string };
    // const modal = useModal()
    const { presences, student } = useStudentPresences(parseInt(id), true)
    const [groups] = useGroups(undefined, true, parseInt(id))
    const modal = useModal();

    // const allowViewGroups

    const getPresence = (p: Presence[], filter: Filter) => {
        switch (filter) {
            case Filter.ALL:
                return p
            case Filter.PRESENT:
                return p.filter((presence) => presence.type === SCAN_TYPE.PRESENT)
            case Filter.JUSTIFIED_ABSENCE:
                return p.filter((presence) => presence.type === SCAN_TYPE.JUSTIFIED)
            case Filter.ABSENT:
                return p.filter((presence) => presence.type === SCAN_TYPE.ABSENT)
            case Filter.NEUTRE:
                return p.filter((presence) => presence.time === null)
            default:
                throw new Error('Filter must be ALL PRESENT ABSENT for presence')
        }
    }
    const getNavItemClassName = (f: Filter) => {
        return extendClassName(Styles.nav_item, f === filter ? Styles.selected : "")
    }

    const getNavItem = (f: Filter, text: string, p: Presence[], g: Group[]) => {
        if (f === Filter.GROUP) {
            return <button className={getNavItemClassName(f)} onClick={() => setFilter(f)}>{text} ({g.length})</button>
        }
        return <button className={getNavItemClassName(f)}
            onClick={() => setFilter(f)}>{text} ({getPresence(p, f).length})</button>
    }

    const removeGroup = useCallback((group: Group) => {
        modal.openModal(
            <ConfirmationModal title={LANG.group_remove_student}
                message={LANG.group_remove_student_confirm}
                onConfirm={() => {
                    CacheManager.RemoveStudentFromGroup(group.id, parseInt(id)).catch(modal.openErrorModal)
                }} />
        )
    }, [id, modal])

    const editCardOd = useCallback(() => {
        modal.openModal(
            <EditCardID studentId={parseInt(id)} />
        )
    }, [id, modal])

    if (id === undefined) {
        return (
            <PageNotFound />
        )
    }

    return (
        <div className={Styles.view}>
            {/*<button onClick={() => modal.openModal(<SignatureModal />)}>Refresh</button>*/}

            {/*HEAD */}
            <div className={Styles.top}>
                <Suspense fallback={<GlobalSpinner />}>
                    <Await resolve={student}>
                        {
                            (student) => student ? (
                                <div className={Styles.title_container}>
                                    <h1 className={Styles.title}>{student.firstname} {student.lastname}</h1>
                                    <IfAllow permission={Permission.EDIT_STUDENTS}>
                                        <IconButton
                                            path={mdiCreditCardSettingsOutline}
                                            pathHover={mdiCreditCardSettings}
                                            d-style={ButtonStyle.CLEAR}
                                            title={LANG.student_card_modal_title}
                                            onClick={editCardOd}
                                            large
                                            title-inline
                                        />
                                    </IfAllow>
                                </div>
                            ) : <GlobalSpinner />
                        }
                    </Await>
                </Suspense>
            </div>

            {/*left*/}
            <div className={Styles.left}>
                <Suspense fallback={<GlobalSpinner />}>
                    <Await resolve={Promise.all([presences, groups])}>
                        {
                            ([presences, groups]: [Presence[], Group[]]) => (

                                <div className={Styles.view_data}>
                                    <nav className={Styles.nav}>
                                        {getNavItem(Filter.ALL, LANG.global_all, presences, [])}
                                        {getNavItem(Filter.PRESENT, LANG.student_present, presences, [])}
                                        {getNavItem(Filter.JUSTIFIED_ABSENCE, LANG.student_justify_absent, presences, [])}
                                        {getNavItem(Filter.ABSENT, LANG.student_absent, presences, [])}
                                        <div className={Styles.nav__separator} role='separator' aria-hidden></div>
                                        {getNavItem(Filter.GROUP, LANG.groups, [], groups)}
                                    </nav>
                                    {Filter.GROUP !== filter &&
                                        <StudentPresences className={Styles.body}
                                            presences={getPresence(presences, filter)} />
                                    }
                                    {
                                        Filter.GROUP === filter &&
                                        <GroupLists groups={groups}
                                            onDelete={
                                                CacheManager.havePermission(Permission.MANAGE_GROUP) ?
                                                    removeGroup : undefined
                                            } />
                                    }
                                </div>
                            )
                        }
                    </Await>
                </Suspense>
            </div>
        </div>
    )
}
