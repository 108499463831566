import {Outlet} from "react-router-dom";
import {IconButton, NoContent, Panel, SearchBar} from "../../common";
import {mdiAccountGroupOutline, mdiPlus} from "@mdi/js";
import React from "react";
import {useModal} from "../../../core/modal/ModalBase";
import CreateEditGroupModal from "../modal/CreateEditGroupModal";
import {SmartGroupLists} from "../component";
import {Permission} from "../../../util/pernission";
import {LANG} from "../../../lang";
import {ButtonStyle} from "../../common/component/IconButton";


function GroupsView(): JSX.Element {

    const modal = useModal();

    const [groupFilter, setGroupFilter] = React.useState<string>('')

    return (
        <div className={'main-panel'} aria-label='Groups page'>
            <Outlet/>
            <Panel title={LANG.groups}
                   icon={mdiAccountGroupOutline}
                   className="panel_shadow"
                   headClassName={'pan_bold_border'}
                   button={(
                       <IconButton
                           path={mdiPlus}
                           onClick={() => modal.openModal(<CreateEditGroupModal />)}
                           title={LANG.group_create}
                           title-place={'right'}
                           d-style={ButtonStyle.ROUND_UPC}
                           permission={Permission.CREATE_GROUP}
                           large
                           />
                   )}>
                <div>
                    <SearchBar placeholder={LANG.group_search} onChange={setGroupFilter} defaultValue={groupFilter}  />
                    <SmartGroupLists filter={groupFilter} />
                </div>

            </Panel>
        </div>
    )

}

const NoGroup = (
    <NoContent iconPath={mdiAccountGroupOutline}
               text={LANG.group_not_selected}
               btnText={LANG.group_create}
               permission={Permission.CREATE_GROUP}
               modal={<CreateEditGroupModal />}
    />
)

export {
    GroupsView,
    NoGroup
};