import {Group, groupDeleteHandler} from "../../../declarations";
import {DEFAULT_EVENT_COLOR} from "../../../util/const";
import Icon from "@mdi/react";
import {mdiAccount, mdiClose} from "@mdi/js";

import Styles from "../styles/Group.module.scss"
import {NavLink} from "react-router-dom";
import {ToolTipContainer} from "../../common";
import CacheManager from "../../../service/cacheManager";
import {Permission} from "../../../util/pernission";
import {LANG} from "../../../lang";


function GroupCore({group, onDelete}: { group: Group, onDelete?: groupDeleteHandler }): JSX.Element {
    return (
        <div className={Styles.group__link}>
            <div className={Styles.group} role='group' aria-label='group'>
                <div className={Styles.group__color} aria-label='group color'
                     style={{backgroundColor: group.color || DEFAULT_EVENT_COLOR}}>
                    {onDelete && <button className={Styles.group__delete} aria-label='delete group'
                            onClick={() => onDelete(group)}>
                        <Icon path={mdiClose} color={'white'}/>
                    </button> }
                </div>
                <span className={Styles.group__name} aria-label='group name'>{group.name}</span>
                <ToolTipContainer className={Styles.group__students_count} data-tooltip-content={LANG.group_students_count} aria-label='group students count'>
                    <span className={Styles.students_count__number}>{group.students_count || 0}</span>
                    <Icon className={Styles.students_count__ico} path={mdiAccount}/>
                </ToolTipContainer>
            </div>
        </div>
    )
}

function NavGroup({group, onDelete}: { group: Group, onDelete?: groupDeleteHandler }): JSX.Element {
    if (CacheManager.havePermission(Permission.VIEW_GROUP)){
        return (
            <NavLink to={'/groups/' + group.id} end>
                <GroupCore group={group} onDelete={onDelete}/>
            </NavLink>
        )
    } else {
        return <GroupCore group={group} onDelete={onDelete}/>;
    }

}

export {NavGroup, GroupCore}