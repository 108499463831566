import { ACYear } from "../../../declarations";
import Icon from "@mdi/react";
import { mdiArchive, mdiArchiveAlert, mdiArchiveAlertOutline, mdiPencil, mdiPencilOutline, mdiPound } from "@mdi/js";
import Styles from '../styles/AcYearEditLine.module.scss'
import { ToolTipContainer } from "../../common";
import { useModal } from "../../../core/modal/ModalBase";
import { EditAcYear } from "../modal/EditAcYear";
import { ArchiveAcYear } from "../modal/ArchiveAcYear";
import { LANG } from "../../../lang";
import { Tag } from "../../common/component/Tag";


export function AcYearEditLine({ acYear }: { acYear: ACYear }) {

    const modal = useModal();

    return (
        <div className={Styles.ac_year_el}>
            <span className={Styles.id}><Icon path={mdiPound} className={Styles.pound} />{acYear.id}</span>

            <ToolTipContainer data-tooltip-content={`${acYear.year_start} - ${acYear.year_end}`}>
                <span>{acYear.name}</span>
            </ToolTipContainer>
            {acYear.archived &&
                <Tag name={LANG.ac_year_archived} color="#c1752c" mdiIcon={mdiArchive} />
            }

            <div role='group' aria-label='actions' className={Styles.actions}>
                {!acYear.archived &&
                    <ToolTipContainer data-tooltip-content={LANG.global_archive}>
                        <button className={Styles.action} onClick={() => modal.openModal(<ArchiveAcYear acYear={acYear} />)}>
                            <Icon path={mdiArchiveAlertOutline}></Icon>
                            <Icon path={mdiArchiveAlert}></Icon>
                        </button>
                    </ToolTipContainer>
                }
                <ToolTipContainer data-tooltip-content={LANG.global_edit}>
                    <button className={Styles.action} onClick={() => modal.openModal(<EditAcYear baseAcYear={acYear} />)}>
                        <Icon path={mdiPencilOutline}></Icon>
                        <Icon path={mdiPencil}></Icon>
                    </button>
                </ToolTipContainer>
            </div>

        </div>
    )
}
