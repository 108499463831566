import Icon from "@mdi/react";
import { CSSProperties, MouseEventHandler } from "react";
import { extendClassName } from "../../../util/utilsHelper";
import Styles from '../styles/Tag.module.scss'

export interface TagProps {
    name: string,
    color: string,
    mdiIcon?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
    className?: string
}

export function Tag(props: TagProps) {
    const outside = (children: any) => {

        if (props.onClick) {
            return (
                <button className={extendClassName(Styles.tag, Styles.clickable, props.className)} onClick={props.onClick} style={
                    { '--color': props.color } as CSSProperties
                }>
                    {children}
                </button>
            )
        } else {
            return <div className={extendClassName(Styles.tag, props.className)} style={{ '--color': props.color } as CSSProperties}>
                {children}
            </div>
        }
    }
    if (props.mdiIcon) {
        return outside((
            <>
                <Icon path={props.mdiIcon} />
                <span className={Styles.name}>{props.name}</span>
            </>
        ))
    }
    return outside((
        <span className={Styles.name}>{props.name}</span>
    ))
}
