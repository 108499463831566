import {useCallback, useEffect, useState} from "react";
import {UserPermissions} from "../../../declarations";
import {ChangeEvent, useEventListener} from "../../common";
import CacheManager from "../../../service/cacheManager";

export function useUsersPermissions(componentID: number ): [
    Promise<UserPermissions[]>,
    () => void
] {
    const [users, setUsers] = useState<Promise<UserPermissions[]>>(Promise.resolve([]))


    const refresh = useCallback(() => {
            setUsers(CacheManager.getUsersPermissions(componentID));
    }, [componentID])

    const callBackEvent = useCallback((event: ChangeEvent<UserPermissions>) => {
        if (event.data.component_id !== componentID) return;

        users.then((users) => {
            const index = users.findIndex((user) => user.id === event.data.id)
            if (index !== -1 && event.data.permission === 0) {
                console.log('delete')
                users.splice(index, 1)
                setUsers(Promise.resolve(users))
                return
            } else {
                users[index === -1 ? users.length : index] = event.data
                setUsers(Promise.resolve(users))
            }
        });

    }, [componentID, users])

    useEventListener('userPermissionsChange', callBackEvent);

    useEffect(refresh, [refresh])

    return [
        users,
        refresh
    ]

}