import { Student, StudentScanTypeCount } from "../../../declarations";
import { IconButton, SearchBar, Table } from "../../common";
import React, { MouseEvent, useCallback, useEffect, useMemo } from "react";
import Styles from "../../planning/styles/EventComponents.module.scss";
import Icon from "@mdi/react";
import { mdiAccountSchool, mdiDeleteForever, mdiEye, mdiPlus } from "@mdi/js";
import { useModal } from "../../../core/modal/ModalBase";
import { SelectStudents } from "../../student"
import CacheManager from "../../../service/cacheManager";
import { TableAction, TableActions } from "../../common/component/TableActions";
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal";
import { ButtonStyle } from "../../common/component/IconButton";
import { Permission } from "../../../util/pernission";
import { LANG } from "../../../lang";
import { SCAN_TYPE } from "../../../util/const";
import { CompactSelect } from "../../common/component/InputUtils";


function GroupStudents({ students, groupID }: { students: StudentScanTypeCount[], groupID: number }): JSX.Element {
    const [studentsFilter, setStudentsFilter] = React.useState<string>("");
    const [sstudents, setSStudents] = React.useState<StudentScanTypeCount[]>([]);
    const [warnCeil, setWarnCeil] = React.useState<number>(2)
    const modal = useModal();


    useEffect(() => {
        setSStudents(students);
    }, [students])

    const addStudents = useCallback((students: Student[]) => {
        CacheManager.addStudentsToGroup(groupID, students.map(s => s.id)).then(r => {
            const toAdd = students.filter(s => r.includes(s.id)).map<StudentScanTypeCount>(st => {
                return {
                    ...st,
                    scan_count: {}
                }
            });
            setSStudents([...sstudents, ...toAdd].filter((s, i, a) => a.indexOf(s) === i));
        })
    }, [groupID, sstudents])

    const deleteStudent = useCallback((student: Student) => {
        CacheManager.RemoveStudentFromGroup(groupID, student.id).then(r => {
            setSStudents(sstudents.filter(s => s.id !== student.id));
        })
    }, [groupID, sstudents])


    const confirmDeleteStudent = useCallback((student: Student) => {
        modal.openModal(
            <ConfirmationModal
                title={LANG.student_delete}
                message={LANG.student_delete_confirm(student.firstname, student.lastname, groupID)}
                onConfirm={() => deleteStudent(student)}
            />)
    }, [deleteStudent, groupID, modal])

    const actions: TableAction<Student>[] = useMemo(() => {
        let act: TableAction<Student>[] = []
        if (CacheManager.havePermission(Permission.MANAGE_GROUP)) {
            act.push({
                type: "button",
                icon: mdiDeleteForever,
                tooltip: LANG.student_delete,
                onClick: confirmDeleteStudent
            })
        }
        if (CacheManager.havePermission(Permission.VIEW_STUDENTS)) {
            act.push(
                {
                    type: "link",
                    icon: mdiEye,
                    tooltip: LANG.student_view,
                    to: (ls) => `/students/${ls.id}`
                }
            )
        }
        return act
    }, [confirmDeleteStudent])

    const drawActions = useCallback((s: Student) => <TableActions target={s} actions={actions} />, [actions])
    const drawScanCount = useCallback((s: StudentScanTypeCount) => {
        return <span className={Styles.scan_counts}>
            <span className={Styles.icon_present}>{s.scan_count[SCAN_TYPE.PRESENT] || 0}</span>
            <span className={Styles.icon_absent}>{s.scan_count[SCAN_TYPE.ABSENT] || 0}</span>
            <span className={Styles.icon_justified}>{s.scan_count[SCAN_TYPE.JUSTIFIED] || 0}</span>
        </span>
    }, [])

    return (
        <div className={Styles.students__container}>
            <div className={Styles.students__head}>
                <div className={Styles.students__head_title}>
                    <Icon path={mdiAccountSchool} size={1} />
                    <span>{LANG.students}</span>
                </div>

                <SearchBar className={Styles.students__head_search} onChange={setStudentsFilter} />
                <div>
                    <label htmlFor="warn-ceil"> Abscence surligniage </label>
                    <CompactSelect
                        options={Array.from({ length: 20 }, (_, i) => (i + 1).toString()).reduce((a, v) => ({ ...a, [v]: v }), {})}
                        onChange={v => setWarnCeil(parseInt(v))}
                        name="warn-ceil"
                    />
                </div>
                <div>
                    <IconButton path={mdiPlus}
                        title={LANG.group_add_students}
                        d-style={ButtonStyle.ROUND_GRAY}
                        onClick={
                            (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
                                modal.openModal(<SelectStudents callBack={addStudents} />, {
                                    position: 'relative',
                                    relativeTo: e.currentTarget,
                                    relativeDirection: 'bottom-left',
                                })
                        }
                        permission={Permission.MANAGE_GROUP}
                    />
                </div>
            </div>

            <Table<StudentScanTypeCount> className={Styles.students__table}
                showHeader={true}
                data={sstudents}
                rowClassNameCallback={(s) => s.scan_count[SCAN_TYPE.ABSENT] >= warnCeil ? Styles.students__table__red_warn : ""}
                header={[
                    { key: "id", display: LANG.global_id },
                    { key: "firstname", display: LANG.event_students_colum_firstname },
                    { key: "lastname", display: LANG.event_students_colum_lastname },
                    { key: "scan_count", display: LANG.group_students_scan_count, render: drawScanCount },
                    { key: "actions", display: LANG.global_actions, render: drawActions, disabled: actions.length === 0 }
                ]}
                filter={studentsFilter}
            />
        </div>
    )
}

export {
    GroupStudents
}
