import {UserPermissions} from "../../../declarations";
import {ModalBase, useModal} from "../../../core/modal/ModalBase";
import {Permission, PermissionName} from "../../../util/pernission";
import {useState} from "react";
import CacheManager from "../../../service/cacheManager";
import {LANG} from "../../../lang";

export function EditUserPermissions({user}: { user: UserPermissions }): JSX.Element {
    const modal = useModal();
    const [permission, setPermission] = useState<number>(user.permission);

    const isAdmin = (permission & Permission.ADMIN) !== 0;

    return (
        <ModalBase
            title={LANG.users_permission_modal_title(user.username)}
            buttons={[
                {
                    label: LANG.global_confirm,
                    id: "confirm",
                    active: true,
                },
            ]}
            onButtonClick={(btn: string) => {
                if (btn === "confirm") {
                    CacheManager.updateUserAccess({
                        ...user,
                        permission: permission,
                    }).catch((e) => {
                        modal.openErrorModal("Erreur", e.message);
                    });
                }
                return true;
            }}

        >
            <div>
                {
                    Object.entries(Permission).map(([key, value]) => {
                        return (
                            <div key={key}>
                                <input id={'perm-' + key} type="checkbox" checked={isAdmin || (permission & value) !== 0}
                                       onChange={(e) => {
                                           if (e.target.checked) {
                                               setPermission(permission | value);
                                           } else {
                                               setPermission(permission & ~value);
                                           }
                                       }}
                                       disabled={(isAdmin && value !== Permission.ADMIN) || value === Permission.ACCESS}/>
                                <label htmlFor={'perm-' + key}>{PermissionName[value]}</label>
                            </div>
                        )
                    })
                }
            </div>
        </ModalBase>
    )
}