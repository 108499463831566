import {UploaderCSVSelectFileProps} from "../../declaration";
import Styles from '../../styles/uploader/UploaderCSVSelectFile.module.scss'
import Icon from "@mdi/react";
import {mdiUpload} from "@mdi/js";
import {LANG} from "../../../../lang";

export function UploaderCSVSelectFile(props: UploaderCSVSelectFileProps): JSX.Element {
    return (
        <div className={Styles.container}>
            <div className={Styles.box}>
                <div className={Styles.select_acyear}>
                    <select name="scyear" id="acyear" onChange={event => {
                        const v = event.target.value;
                        if (v === 'none') {
                            props.onAcYearSelected(undefined)
                        } else
                            props.onAcYearSelected(props.acYears.find(ac => ac.id === parseInt(v)))
                    }}>
                        <option value="none">--</option>
                        {props.acYears.map(ac => <option key={ac.id} value={ac.id}>{ac.name}</option>)}
                    </select>
                    <label htmlFor="scyear">{LANG.admin_csv_select_ac_year}</label>
                </div>
                <div className={Styles.file}>
                    <input id='filecsv' name='filecsv' type="file" accept="text/csv" onChange={e => {
                        if (e.target.files !== null && e.target.files.length > 0) {
                            props.onFileSelected(e.target.files[0])
                        }
                    }}/>
                    <label htmlFor="filecsv">
                        <figure><Icon path={mdiUpload}/></figure>
                        {props.file !== undefined ? <span>{props.file.name}</span> : <span>{LANG.admin_csv_select_file}</span>}
                    </label>
                </div>
            </div>
        </div>
    )
}
