import {COLOR_PALETTE} from "./const";
import {memo} from "react";

export const genericMemo: <T>(component: T) => T = memo
export function extendClassName(...args: (string | undefined)[]): string|undefined {
    return args.filter((arg) => arg !== undefined).join(" ")||undefined;
}

export function formatNumber(number: number, minimumIntegerDigits: number): string {
    return number.toLocaleString("fr-FR", {minimumIntegerDigits: minimumIntegerDigits, useGrouping:false});
}

export function convertRemToPixels(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function posInRect(x: number, y: number, rect: DOMRect): boolean {
    return rect.left <= x && x <= rect.right && rect.top <= y && y <= rect.bottom;
}

export function filterInPlace<T>(array: T[], fn: (value: T, index: number, array: T[]) => boolean) {
    let from = 0, to = 0;
    while (from < array.length) {
        if (fn(array[from], from, array)) {
            array[to] = array[from];
            to++;
        }
        from++;
    }
    array.length = to;
}

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}


export function getRandomColorFromPalette(): string {
    return COLOR_PALETTE[getRandomInt(0, COLOR_PALETTE.length)]
}