import Styles from '../styles/EventScan.module.scss'
import { EventStudents } from "../component";
import React, { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalSpinner, useEventListener } from "../../common";
import CacheManager from "../../../service/cacheManager";
import { APIAnswer, Student } from "../../../declarations";
import { RequestError } from "../../../util/RequestError";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose, mdiNfc } from "@mdi/js";
import { LANG } from "../../../lang";
import { useUEvent } from '../hook/useUEvent';
import { useRediractIfError } from '../../common/hook/useRedirectIfError';


export function KeyListener(props: {
    onEnter: (value: string) => void,
    isStop: boolean
}) {
    // use ref to prevent rerender at change
    const rValue = useRef<string>('')

    const onKeyPress = useCallback((event: KeyboardEvent) => {
        if (props.isStop) {
            // reset value when stop
            if (rValue.current !== '') rValue.current = '';
            return;
        }
        if (/^[a-fA-F0-9]$/.test(event.key)) {
            rValue.current = rValue.current + event.key
        } else if (event.key === "Enter") {
            props.onEnter(rValue.current)
            rValue.current = ''
        }
    }, [props, rValue])

    useEventListener('keypress', onKeyPress)

    // no render component
    return null;
}

export function EventScan(): JSX.Element {

    const params: any = useParams()
    const { event, isError } = useUEvent(params.id || "NaN")
    useRediractIfError(isError, "/events")
    const [answer, setAnswer] = useState<APIAnswer<Student> | undefined>()
    const [waiting, setWaiting] = useState<boolean>(false)

    const onIDInput = (id: string) => {
        setWaiting(true)
        CacheManager.addEventScanFromCard(parseInt(params.id), id).then(rep => setAnswer({ status: 200, data: rep })).catch(e => {
            if (e instanceof RequestError) {
                setAnswer({ status: e.statusCode, error: e.message })
            } else {
                setAnswer({ status: 500, error: e.message })
            }
        }).finally(() => setWaiting(false))
    }


    let result: {
        path: string,
        color: string
        text: string
    }
    if (answer === undefined) {
        result = {
            path: mdiNfc,
            color: '#CD5D7D',
            text: LANG.event_scan_result_waiting
        }
    } else if (answer?.status === 200) {
        result = {
            path: mdiCheck,
            color: 'green',
            text: LANG.event_scan_result_success(answer.data?.firstname, answer.data?.lastname),
        }
    } else if (answer?.status === 404) {
        result = {
            path: mdiClose,
            color: 'red',
            text: LANG.event_scan_result_unknown_card
        }
    } else if (answer?.status === 400) {
        result = {
            path: mdiClose,
            color: 'red',
            text: LANG.event_scan_result_error(answer.error)
        }
    } else {
        result = {
            path: mdiClose,
            color: 'red',
            text: LANG.event_scan_result_error_unknown
        }
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.result_container}>
                <KeyListener onEnter={onIDInput} isStop={false} />
                <div className={Styles.result}>
                    {waiting ?
                        <GlobalSpinner /> :
                        <>
                            <Icon path={result.path} color={result.color} />
                            <span>{result.text}</span>
                        </>
                    }

                </div>
            </div>
            <div className={Styles.students_container}>
                {event ? <EventStudents event={event} /> : <GlobalSpinner />}
            </div>
        </div>
    )
}
