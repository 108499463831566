import { useGroups } from "../hook";
import { Suspense } from "react";
import { GlobalSpinner } from "../../common";
import { Await } from "react-router-dom";
import { NavGroup } from "./Group";
import { classNameProp, Group, groupDeleteHandler } from "../../../declarations";

import Styles from "../styles/Group.module.scss"
import { extendClassName } from "../../../util/utilsHelper";
import { useModal } from "../../../core/modal/ModalBase";
import { ConfirmationModal } from "../../../core/modal/ConfirmationModal";
import { LANG } from "../../../lang";
import { Fzf } from "fzf";

function GroupLists({ groups, className, GroupComponent, onDelete, confirmationMessage, filter }: {
    groups: Group[],
    GroupComponent?: (props: { group: Group }) => JSX.Element,
    onDelete?: groupDeleteHandler,
    confirmationMessage?: string,
    filter?: string
} & classNameProp): JSX.Element {
    const modal = useModal();
    const GC: (props: { group: Group, onDelete: groupDeleteHandler }) => JSX.Element = GroupComponent ?? NavGroup

    const handleDelete: groupDeleteHandler | undefined = onDelete ? (group: Group) => {

        if (confirmationMessage) {
            modal.openModal(
                <ConfirmationModal title={LANG.group_delete} message={confirmationMessage} onConfirm={() => onDelete(group)} />
            )
        } else {
            onDelete(group)
        }

    } : undefined

    // const filterToLowerCase = filter?.toLowerCase()
    let sortedFilteredGroup: Group[] = []

    if (filter) {
        const fzf = new Fzf(groups, {
            selector: g => g.name
        })
        sortedFilteredGroup = fzf.find(filter).map((v) => v.item)
    } else {
        sortedFilteredGroup = groups.sort((g1, g2) => g1.name.localeCompare(g2.name))
    }

    return (
        <div className={extendClassName(Styles.groups_list, className)} aria-label='groups'>
            {sortedFilteredGroup.map((value, index) => {
                return (
                    <GC group={value} onDelete={handleDelete} key={index} />
                );
            })}
        </div>
    )
}

function SmartGroupLists({ eventID, className, GroupComponent, onDelete, confirmationMessage, filter }: {
    eventID?: number,
    GroupComponent?: (props: { group: Group }) => JSX.Element,
    onDelete?: groupDeleteHandler,
    confirmationMessage?: string,
    filter?: string
} & classNameProp): JSX.Element {

    const [groups] = useGroups(eventID, true)

    return (
        // <div>
        <Suspense fallback={<GlobalSpinner />}>
            <Await resolve={groups}>
                {(resolveGroups: Group[]) => (
                    <GroupLists className={className} groups={resolveGroups} GroupComponent={GroupComponent}
                        onDelete={onDelete} confirmationMessage={confirmationMessage} filter={filter} />
                )}
            </Await>
        </Suspense>
        // </div>
    )
}

export { GroupLists, SmartGroupLists };
