import {classNameProp} from "../../../declarations";
import {
    CSSProperties,
    Dispatch,
    memo,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import InfiniteLoader from "react-window-infinite-loader";
import {FixedSizeList} from "react-window";
import {convertRemToPixels, extendClassName, filterInPlace} from "../../../util/utilsHelper";
import Styles from "../style/SelectStudents.module.scss";
import {VCheckBox} from "../../common";
import CacheManager from "../../../service/cacheManager";
import {SelectableStudent} from "../student";
import {NavLink} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiPound} from "@mdi/js";
import {LANG} from "../../../lang";


function StudentSelectable({student, onCheck}: { student: SelectableStudent, onCheck: (id: number) => void }): JSX.Element {

    return (
        <div className={Styles.student} onClick={() => onCheck(student.id)}>
            <div className={Styles.students__info}>
                <div className={Styles.student__id}>
                    <Icon path={mdiPound} className={Styles.student__id_ico} />
                    <span>{student.id}</span>
                </div>
                <span>{student.firstname}</span>
                <span>{student.lastname}</span>
            </div>
            <span className={Styles.student__checkbox}>
                <VCheckBox value={student.selected ?? false}/>
            </span>
        </div>
    )
}

const  StudentNav = memo(({student}: { student: SelectableStudent }): JSX.Element => {
    return (
        <NavLink to={'/students/' + student.id} end className={Styles.student}>
            <div className={Styles.students__info}>
                <div className={Styles.student__id}>
                    <Icon path={mdiPound} className={Styles.student__id_ico} />
                    <span>{student.id}</span>
                </div>
                <span>{student.firstname}</span>
                <span>{student.lastname}</span>
            </div>
        </NavLink>
    )
});

function StudentsWrapper(
    {
        // Are there more items to load?
        // (This information comes from the most recent API request.)
        hasNextPage,

        // Are we currently loading a page of items?
        // (This may be an in-flight flag in your Redux store for example.)
        // isNextPageLoading,

        // Array of items loaded so far.
        items,

        // Callback function responsible for loading the next page of items.
        loadNextPage,
        //
        className,
        // callback for selected student if undefined disable select and nav to /students/:id
        onCheck,

    }:
        {
            hasNextPage: boolean,
            // isNextPageLoading: boolean,
            items: SelectableStudent[],
            loadNextPage: (startIndex: number, stopIndex: number) => Promise<void>,
            onCheck?: (id: number) => void,
        } & classNameProp,
) {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded: (index: number) => boolean = (index) => !hasNextPage || index < items.length;

    // Render an item or a loading indicator.
    const Item = ({index, style}: { index: number, style: CSSProperties }) => {
        let content;
        if (!isItemLoaded(index)) {
            content = LANG.global_loading;
        } else if (onCheck) {
            content = <StudentSelectable onCheck={onCheck} student={items[index]}/>;
        } else (
            content = <StudentNav student={items[index]} />
        )

        return <div style={style}>{content}</div>;
    };

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
        >
            {({onItemsRendered, ref}) => (
                <FixedSizeList
                    className={extendClassName(className)}
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={window.innerHeight}

                    itemSize={convertRemToPixels(2)} width='100%'>
                    {Item}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    );
}

// export interface StudentListRef {
//     removeStudent: (id: number) => void
// }

function StudentListComponent(
    {
        search,
        onSelectedChange,
        filter,
        setFilter,
        chackable
    } :
        {
            search?: RefObject<HTMLInputElement>,
            filter: string,
            setFilter?: Dispatch<SetStateAction<string>>,
            onSelectedChange?: (students: SelectableStudent[]) => void,
            chackable: boolean
        }
    // ref: Ref<StudentListRef> | undefined
) {
    const [hasNextPage, setHasNextPage] = useState<boolean>(true)

    // const [isNextPageLoading, setIsNextPageLoading] = useMemo<boolean>(false)
    const [items, setItems] = useState<SelectableStudent[]>([])
    const cacheItems = useMemo<SelectableStudent[]>(() => [], [])
    const filterLoad = useMemo<{[key: string]: boolean}>(() => ({}), [])

    const isFilterEndLoad = useMemo(() => {
        return (f: string) => {
            if (filterLoad['']) return true;
            for (let i = 0 ; i < f.length; i++) {
                if (filterLoad[f.substring(0, i)]) {
                    return true
                }
            }
            return false;
        }
    }, [filterLoad])

    const setItemsFromCache = useCallback((f:  string) => {
        if (f.length === 0) {
            setItems([...cacheItems])
            return;
        } else {
            setItems(cacheItems.filter((st => st.id.toString().startsWith(f) || st.firstname.toLowerCase().startsWith(f) || st.lastname.toLowerCase().startsWith(f))))
        }
    }, [cacheItems])

    const getLastNotFilteredStudent = useCallback((filter: string): number => {
        for (let i = cacheItems.length - 1; i >= 0; i--) {
            if (cacheItems[i].filtered === undefined ||  filter.startsWith(cacheItems[i].filtered??"")) {
                return cacheItems[i].id;
            }
        }
        return 0;
    }, [cacheItems])

    useEffect(() => {
        setItemsFromCache(filter)
        setHasNextPage(!isFilterEndLoad(filter))
    }, [filter, setItemsFromCache, isFilterEndLoad])

    const loadNextPage = useCallback(async (startIndex: number, stopIndex: number) => {
        if (isFilterEndLoad(filter)) {
            setHasNextPage(false)
            return;
        }

        const data = await CacheManager.GetStudents(100, getLastNotFilteredStudent(filter), filter || undefined)

        if (data.length < 100) {
            filterLoad[filter??''] = true
            setHasNextPage(false)
        }
        if (filter.length === 0) {
            cacheItems.push(...data)
        } else {
            cacheItems.push(...data.map((st) => ({...st, filtered: filter})))
        }
        filterInPlace(cacheItems, (st, index, self) => self.findIndex((s) => s.id === st.id) === index)
        cacheItems.sort((a, b) => a.id - b.id)
        setItemsFromCache(filter)

    }, [filter, isFilterEndLoad, cacheItems, filterLoad, getLastNotFilteredStudent, setItemsFromCache])

    const onCheck = useCallback((id: number) => {
        if (!onSelectedChange) return;
        const index = cacheItems.findIndex((student) => student.id === id)
        if (index !== -1) {
            cacheItems[index].selected = !cacheItems[index].selected
            onSelectedChange(cacheItems.filter(s => s.selected))
            if (filter.length === 0) {
                setItemsFromCache(filter)
            } else {
                if (setFilter) setFilter('')
                if (search && search.current)
                    search.current.value = ''
            }

        }
    }, [cacheItems, onSelectedChange, filter, setItemsFromCache, setFilter, search])

    return (
        <StudentsWrapper
            onCheck={chackable ? onCheck : undefined}
            className={Styles.box__window}
            hasNextPage={hasNextPage}
            // isNextPageLoading={isNextPageLoading}
            items={items}
            loadNextPage={loadNextPage}
        />
    )
}

export const StudentList = StudentListComponent